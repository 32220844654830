import { useApolloClient, useMutation } from '@apollo/client';

import {
  Action,
  AddAreaAction,
  AddDistanceAction,
  AddGroupAction,
  AddPointAction,
  DeleteAnnotationAction,
  DeleteAnnotationMultiAction,
  DeleteGroupAction,
  MoveAnnotationsToGroupAction,
  MovePointAction,
  AddAdditionalPointAction,
  DeletePointAction,
  UnGroupAction,
  UpdateNameAction,
  UpdatePositionAction,
  AddCadLayerAction,
  DeleteCadObjectAction,
  DeleteCadObjectGroupAction,
  DeleteCadLayerAction,
  ConvertCadObjectAction,
  AddOrthophotoLayerAction,
  DeleteOrthophotoLayerAction,
  AddWmsLayerAction,
  DeleteWmsLayerAction,
  AddBoxAction,
  EditBoxAction,
  AddPointClusterAction,
  EditPointClusterAction,
  UpdateWmsLayerAction,
  UpdatePositionWmsLayerAction,
  HideCadObjectAction,
  UpdatePositionOrthophotoLayerAction,
  AddPlaneAction,
  EditPlaneAction,
  ConvertCadObjectActionValue,
} from '@pointorama/pointcloud-commander';
import { keyBy } from 'lodash';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CommandManagerContext } from '../../contexts/CommandManagerContext';
import { PROJECT_ADD_ACTION, PROJECT_FRAGMENT } from '../../graphql/project';
import { toggleSelectedAnnotation } from '../../redux/rendererReducer';
import {
  AddAreaAction as TypeAddAreaAction,
  AddDistanceAction as TypeAddDistanceAction,
  AddGroupAction as TypeAddGroupAction,
  AddPointAction as TypeAddPointAction,
  DeleteAnnotationAction as TypeDeleteAnnotationAction,
  DeleteAnnotationMultiAction as TypeDeleteAnnotationMultiAction,
  DeleteGroupAction as TypeDeleteGroupAction,
  MoveAnnotationsToGroupAction as TypeMoveAnnotationsToGroupAction,
  MovePointAction as TypeMovePointAction,
  AddAdditionalPointAction as TypeAddAdditionalPointAction,
  DeletePointAction as TypeDeletePointAction,
  ProjectAddActionMutation,
  ProjectAddActionMutationVariables,
  ProjectByIdQuery,
  ProjectFragment,
  UnGroupAction as TypeUnGroupAction,
  UpdateNameAction as TypeUpdateNameAction,
  UpdatePositionAction as TypeUpdatePositionAction,
  UpdatePositionWmsLayerAction as TypeUpdatePositionWmsLayerAction,
  UpdatePositionOrthophotoLayerAction as TypeUpdatePositionOrthophotoLayerAction,
  AddCadLayerAction as TypeAddCadLayerAction,
  HideCadObjectAction as TypeHideCadObjectAction,
  DeleteCadObjectAction as TypeDeleteCadObjectAction,
  DeleteCadObjectGroupAction as TypeDeleteCadObjectGroupAction,
  DeleteCadLayerAction as TypeDeleteCadLayerAction,
  DeleteWmsLayerAction as TypeDeleteWmsLayerAction,
  DeleteOrthophotoLayerAction as TypeDeleteOrthophotoLayerAction,
  ConvertCadObjectAction as TypeConvertCadObjectAction,
  AddWmsLayerAction as TypeAddWmsLayerAction,
  AddOrthophotoLayerAction as TypeAddOrthophotoLayerAction,
  UpdateWmsLayerAction as TypeUpdateWmsLayerAction,
  WmsType,
  AddBoxAction as TypeAddBoxAction,
  EditBoxAction as TypeEditBoxAction,
  AddPointClusterAction as TypeAddPointClusterAction,
  EditPointClusterAction as TypeEditPointClusterAction,
  AddPlaneAction as TypeAddPlaneAction,
  EditPlaneAction as TypeEditPlaneAction,
} from '../../types/graphqlTypes';
import { isNotNullOrUndefined } from '../../utils/isNullOrEmpty';

const ACTION_KEY_BY_ACTION = {
  POINT: 'addPointAction',
  DISTANCE: 'addDistanceAction',
  AREA: 'addAreaAction',
  BOX: 'addBoxAction',
  POINTCLUSTER: 'addPointClusterAction',
  PLANE: 'addPlaneAction',
  MOVE_POINT: 'movePointAction',
  ADD_ADDITIONAL_POINT: 'addAdditionalPointAction',
  DELETE_POINT: 'deletePointAction',
  ADD_GROUP: 'addGroupAction',
  DELETE_GROUP: 'deleteGroupAction',
  DELETE_ANNOTATION: 'deleteAnnotationAction',
  DELETE_ANNOTATION_MULTI: 'DeleteAnnotationMultiAction',
  MOVE_ANNOTATIONS_TO_GROUP: 'moveAnnotationsToGroupAction',
  UN_GROUP: 'unGroupAction',
  UPDATE_NAME: 'updateNameAction',
  UPDATE_POSITION: 'updatePositionAction',
  UPDATE_POSITION_WMS_LAYER: 'updatePositionWmsLayerAction',
  UPDATE_POSITION_ORTHOPHOTO_LAYER: 'updatePositionOrthophotoLayerAction',
  ADD_CAD_LAYER: 'addCadLayerAction',
  HIDE_CAD_OBJECT: 'hideCadObjectAction',
  DELETE_CAD_OBJECT: 'deleteCadObjectAction',
  DELETE_CAD_OBJECT_GROUP: 'deleteCadObjectGroupAction',
  DELETE_CAD_LAYER: 'deleteCadLayerAction',
  CONVERT_CAD_OBJECT: 'convertCadObjectAction',
  ADD_WMS_LAYER: 'addWmsLayerAction',
  UPDATE_WMS_LAYER: 'updateWmsLayerAction',
  DELETE_WMS_LAYER: 'deleteWmsLayerAction',
  ADD_ORTHOPHOTO_LAYER: 'addOrthophotoLayerAction',
  DELETE_ORTHOPHOTO_LAYER: 'deleteOrthophotoLayerAction',
  EDIT_BOX: 'editBoxAction',
  EDIT_POINTCLUSTER: 'editPointClusterAction',
  EDIT_PLANE: 'editPlaneAction',
};

const ANNOTATION_TYPENAME_BY_TYPE = {
  POINT: 'PointAnnotation',
  DISTANCE: 'DistanceAnnotation',
  AREA: 'AreaAnnotation',
  BOX: 'BoxAnnotation',
  POINTCLUSTER: 'PointCluster',
  PLANE: 'Plane',
};

const isGroupAction = (
  action: Action | undefined,
): action is AddGroupAction | DeleteGroupAction | MoveAnnotationsToGroupAction | UnGroupAction => {
  if (action instanceof AddGroupAction) return true;
  if (action instanceof DeleteGroupAction) return true;
  if (action instanceof MoveAnnotationsToGroupAction) return true;
  if (action instanceof UnGroupAction) return true;
  return false;
};
const isMultiAction = (action: Action | undefined): action is DeleteAnnotationMultiAction => {
  if (action instanceof DeleteAnnotationMultiAction) return true;
  return false;
};
const isUpdatePositionAction = (action: Action | undefined): action is UpdatePositionAction => {
  if (action instanceof UpdatePositionAction) return true;
  return false;
};
const isUpdatePositionWmsLayerAction = (action: Action | undefined): action is UpdatePositionWmsLayerAction => {
  if (action instanceof UpdatePositionWmsLayerAction) return true;
  return false;
};
const isUpdatePositionOrthophotoLayerAction = (
  action: Action | undefined,
): action is UpdatePositionOrthophotoLayerAction => {
  if (action instanceof UpdatePositionOrthophotoLayerAction) return true;
  return false;
};

export const getConvertCadObjectAction = (
  action: Omit<TypeConvertCadObjectAction, 'identifier'>,
): ConvertCadObjectActionValue => {
  return {
    ...action,
    cadObjects: action.cadObjects.map((cadObject) => {
      if (cadObject.type === 'POINT') {
        return {
          ...cadObject,
          type: cadObject.type,
          annotationIdentifier: cadObject.annotationIdentifier,
          position: cadObject.position || { x: 0, y: 0, z: 0 },
          color: cadObject.color || { r: 24, g: 24, b: 24, a: 1 },
        };
      }
      if (cadObject.type === 'LINE') {
        return {
          ...cadObject,
          type: cadObject.type,
          annotationIdentifier: cadObject.annotationIdentifier,
          points: cadObject.points || [],
          color: cadObject.color || { r: 24, g: 24, b: 24, a: 1 },
        };
      }
      if (cadObject.type === 'POLYGON') {
        return {
          ...cadObject,
          type: cadObject.type,
          annotationIdentifier: cadObject.annotationIdentifier,
          points: cadObject.points || [],
          color: cadObject.color || { r: 24, g: 24, b: 24, a: 1 },
        };
      }
      throw new Error(`Invalid cad object type: ${cadObject.type}`);
    }),
  };
};

const isCadAction = (
  action: Action | undefined,
): action is
  | AddCadLayerAction
  | ConvertCadObjectAction
  | DeleteCadLayerAction
  | DeleteCadObjectGroupAction
  | DeleteCadObjectAction
  | HideCadObjectAction => {
  if (action instanceof AddCadLayerAction) return true;
  if (action instanceof ConvertCadObjectAction) return true;
  if (action instanceof DeleteCadLayerAction) return true;
  if (action instanceof DeleteCadObjectGroupAction) return true;
  if (action instanceof DeleteCadObjectAction) return true;
  if (action instanceof HideCadObjectAction) return true;
  return false;
};

const isWmsLayerAction = (action: Action | undefined): action is AddWmsLayerAction | DeleteWmsLayerAction => {
  if (action instanceof AddWmsLayerAction) return true;
  if (action instanceof DeleteWmsLayerAction) return true;
  return false;
};

const isOrthophotoLayerAction = (
  action: Action | undefined,
): action is AddOrthophotoLayerAction | DeleteOrthophotoLayerAction => {
  if (action instanceof AddOrthophotoLayerAction) return true;
  if (action instanceof DeleteOrthophotoLayerAction) return true;
  return false;
};

export const useExecuteAction = () => {
  const { projectId = '' } = useParams();
  const { commander } = useContext(CommandManagerContext);
  const [addAction] = useMutation<ProjectAddActionMutation, ProjectAddActionMutationVariables>(PROJECT_ADD_ACTION);
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  const executeAction = useCallback(
    (
      args:
        | { action: Omit<TypeAddPointAction, 'identifier'>; type: 'POINT' }
        | { action: Omit<TypeAddDistanceAction, 'identifier'>; type: 'DISTANCE' }
        | { action: Omit<TypeAddAreaAction, 'identifier'>; type: 'AREA' }
        | { action: Omit<TypeAddBoxAction, 'identifier'>; type: 'BOX' }
        | { action: Omit<TypeAddPointClusterAction, 'identifier'>; type: 'POINTCLUSTER' }
        | { action: Omit<TypeAddPlaneAction, 'identifier'>; type: 'PLANE' }
        | { action: Omit<TypeMovePointAction, 'identifier'>; type: 'MOVE_POINT' }
        | { action: Omit<TypeAddAdditionalPointAction, 'identifier'>; type: 'ADD_ADDITIONAL_POINT' }
        | { action: Omit<TypeDeletePointAction, 'identifier'>; type: 'DELETE_POINT' }
        | { action: Omit<TypeAddGroupAction, 'identifier'>; type: 'ADD_GROUP' }
        | { action: Omit<TypeDeleteGroupAction, 'identifier'>; type: 'DELETE_GROUP' }
        | { action: Omit<TypeDeleteAnnotationAction, 'identifier'>; type: 'DELETE_ANNOTATION' }
        | { action: Omit<TypeMoveAnnotationsToGroupAction, 'identifier'>; type: 'MOVE_ANNOTATIONS_TO_GROUP' }
        | { action: Omit<TypeUnGroupAction, 'identifier'>; type: 'UN_GROUP' }
        | { action: Omit<TypeUpdateNameAction, 'identifier'>; type: 'UPDATE_NAME' }
        | { action: Omit<TypeUpdatePositionAction, 'identifier'>; type: 'UPDATE_POSITION' }
        | { action: Omit<TypeUpdatePositionWmsLayerAction, 'identifier'>; type: 'UPDATE_POSITION_WMS_LAYER' }
        | {
            action: Omit<TypeUpdatePositionOrthophotoLayerAction, 'identifier'>;
            type: 'UPDATE_POSITION_ORTHOPHOTO_LAYER';
          }
        | { action: Omit<TypeDeleteAnnotationMultiAction, 'identifier'>; type: 'DELETE_ANNOTATION_MULTI' }
        | { action: Omit<TypeAddCadLayerAction, 'identifier'>; type: 'ADD_CAD_LAYER' }
        | { action: Omit<TypeConvertCadObjectAction, 'identifier'>; type: 'CONVERT_CAD_OBJECT' }
        | { action: Omit<TypeHideCadObjectAction, 'identifier'>; type: 'HIDE_CAD_OBJECT' }
        | { action: Omit<TypeDeleteCadObjectAction, 'identifier'>; type: 'DELETE_CAD_OBJECT' }
        | { action: Omit<TypeDeleteCadObjectGroupAction, 'identifier'>; type: 'DELETE_CAD_OBJECT_GROUP' }
        | { action: Omit<TypeDeleteCadLayerAction, 'identifier'>; type: 'DELETE_CAD_LAYER' }
        | { action: Omit<TypeAddWmsLayerAction, 'identifier'>; type: 'ADD_WMS_LAYER' }
        | { action: Omit<TypeUpdateWmsLayerAction, 'identifier'>; type: 'UPDATE_WMS_LAYER' }
        | { action: Omit<TypeDeleteWmsLayerAction, 'identifier'>; type: 'DELETE_WMS_LAYER' }
        | { action: Omit<TypeAddOrthophotoLayerAction, 'identifier'>; type: 'ADD_ORTHOPHOTO_LAYER' }
        | { action: Omit<TypeDeleteOrthophotoLayerAction, 'identifier'>; type: 'DELETE_ORTHOPHOTO_LAYER' }
        | { action: Omit<TypeEditBoxAction, 'identifier'>; type: 'EDIT_BOX' }
        | { action: Omit<TypeEditPointClusterAction, 'identifier'>; type: 'EDIT_POINTCLUSTER' }
        | { action: Omit<TypeEditPlaneAction, 'identifier'>; type: 'EDIT_PLANE' },
      executeOptions: { redo?: boolean } = {},
    ) => {
      const { type, action } = args;
      let commanderAction: Action | undefined = undefined;
      if (type === 'POINT') commanderAction = new AddPointAction(action);
      else if (type === 'DISTANCE') commanderAction = new AddDistanceAction(action);
      else if (type === 'AREA') commanderAction = new AddAreaAction(action);
      else if (type === 'BOX') commanderAction = new AddBoxAction(action);
      else if (type === 'POINTCLUSTER') commanderAction = new AddPointClusterAction(action);
      else if (type === 'PLANE') commanderAction = new AddPlaneAction(action);
      else if (type === 'MOVE_POINT') commanderAction = new MovePointAction(action);
      else if (type === 'ADD_ADDITIONAL_POINT') commanderAction = new AddAdditionalPointAction(action);
      else if (type === 'DELETE_POINT') commanderAction = new DeletePointAction(action);
      else if (type === 'ADD_GROUP') commanderAction = new AddGroupAction(action);
      else if (type === 'DELETE_GROUP') commanderAction = new DeleteGroupAction(action);
      else if (type === 'DELETE_ANNOTATION') commanderAction = new DeleteAnnotationAction(action);
      else if (type === 'DELETE_ANNOTATION_MULTI') commanderAction = new DeleteAnnotationMultiAction(action);
      else if (type === 'MOVE_ANNOTATIONS_TO_GROUP') commanderAction = new MoveAnnotationsToGroupAction(action);
      else if (type === 'UN_GROUP') commanderAction = new UnGroupAction(action);
      else if (type === 'UPDATE_NAME') commanderAction = new UpdateNameAction(action);
      else if (type === 'UPDATE_POSITION') commanderAction = new UpdatePositionAction(action);
      else if (type === 'UPDATE_POSITION_WMS_LAYER') commanderAction = new UpdatePositionWmsLayerAction(action);
      else if (type === 'UPDATE_POSITION_ORTHOPHOTO_LAYER')
        commanderAction = new UpdatePositionOrthophotoLayerAction(action);
      else if (type === 'ADD_CAD_LAYER') commanderAction = new AddCadLayerAction(action);
      else if (type === 'HIDE_CAD_OBJECT') commanderAction = new HideCadObjectAction(action);
      else if (type === 'DELETE_CAD_OBJECT') commanderAction = new DeleteCadObjectAction(action);
      else if (type === 'DELETE_CAD_OBJECT_GROUP') commanderAction = new DeleteCadObjectGroupAction(action);
      else if (type === 'DELETE_CAD_LAYER') commanderAction = new DeleteCadLayerAction(action);
      else if (type === 'CONVERT_CAD_OBJECT')
        commanderAction = new ConvertCadObjectAction(getConvertCadObjectAction(action));
      else if (type === 'ADD_WMS_LAYER') commanderAction = new AddWmsLayerAction(action);
      else if (type === 'UPDATE_WMS_LAYER') commanderAction = new UpdateWmsLayerAction(action);
      else if (type === 'DELETE_WMS_LAYER') commanderAction = new DeleteWmsLayerAction(action);
      else if (type === 'ADD_ORTHOPHOTO_LAYER') commanderAction = new AddOrthophotoLayerAction(action);
      else if (type === 'DELETE_ORTHOPHOTO_LAYER') commanderAction = new DeleteOrthophotoLayerAction(action);
      else if (type === 'EDIT_BOX') commanderAction = new EditBoxAction(action);
      else if (type === 'EDIT_POINTCLUSTER') commanderAction = new EditPointClusterAction(action);
      else if (type === 'EDIT_PLANE') commanderAction = new EditPlaneAction(action);
      if (!commanderAction) return;
      commander.setState({ ...commander.getState() });
      commander.execute(commanderAction, executeOptions);
      const project = apolloClient.readFragment<ProjectFragment>({
        fragment: PROJECT_FRAGMENT,
        id: `Project:${projectId}`,
        fragmentName: 'Project',
      });
      if (!project) return;
      const annotationsByIdentifier = keyBy(project.state.annotations, 'identifier');
      const wmsLayersByIdentifier = keyBy(project.state.wmsLayers, 'identifier');
      const cadLayersByIdentifier = keyBy(project.state.cadLayers, 'identifier');
      const orthophotoLayersByIdentifier = keyBy(project.state.orthophotoLayers, 'identifier');
      const newState = { ...commander.getState() };
      const pointCloudsStateById = keyBy(newState.pointClouds, 'identifier');
      addAction({
        variables: {
          projectId,
          action: {
            [ACTION_KEY_BY_ACTION[type]]: { ...commanderAction.value, identifier: commanderAction.identifier },
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          projectAddAction: {
            id: projectId,
            __typename: 'Project',
            state: {
              ...newState,
              annotations: newState.annotations.map((annotation) => {
                const cacheAnnotation = annotationsByIdentifier[annotation.identifier];
                const fullAnnotation = {
                  ...annotation,
                  __typename: ANNOTATION_TYPENAME_BY_TYPE[annotation.type] || '',
                } as ProjectByIdQuery['projectById']['state']['annotations'][0];
                if (
                  (!isGroupAction(commanderAction) &&
                    !isMultiAction(commanderAction) &&
                    !isUpdatePositionAction(commanderAction) &&
                    !isCadAction(commanderAction) &&
                    !isWmsLayerAction(commanderAction) &&
                    !isOrthophotoLayerAction(commanderAction) &&
                    !isUpdatePositionWmsLayerAction(commanderAction) &&
                    !isUpdatePositionOrthophotoLayerAction(commanderAction) &&
                    annotation.identifier === commanderAction?.value.annotationIdentifier) ||
                  (isMultiAction(commanderAction) &&
                    !isCadAction(commanderAction) &&
                    commanderAction.value.identifiers.includes(annotation.identifier))
                ) {
                  if (fullAnnotation.__typename === 'PointAnnotation') {
                    return {
                      ...fullAnnotation,
                      position: { ...fullAnnotation.position, __typename: 'Position' },
                      visible: true,
                      hiddenLabels: cacheAnnotation ? cacheAnnotation.hiddenLabels : [],
                      annotationColor: cacheAnnotation?.annotationColor || { r: 24, g: 24, b: 24, a: 1 },
                      groupIdentifier: fullAnnotation.groupIdentifier || null,
                      color: fullAnnotation.color ? { ...fullAnnotation.color, __typename: 'Color' } : null,
                      intensity: fullAnnotation.intensity || null,
                      classification: fullAnnotation.classification || null,
                    };
                  } else if (fullAnnotation.__typename === 'BoxAnnotation') {
                    return {
                      ...fullAnnotation,
                      position: { ...fullAnnotation.position, __typename: 'Position' },
                      scale: { ...fullAnnotation.scale, __typename: 'Position' },
                      rotation: { ...fullAnnotation.rotation, __typename: 'Position' },
                      visible: true,
                      hiddenLabels: cacheAnnotation ? cacheAnnotation.hiddenLabels : [],
                      annotationColor: cacheAnnotation?.annotationColor || { r: 47, g: 89, b: 84, a: 1 },
                      groupIdentifier: fullAnnotation.groupIdentifier || null,
                      annotationFilter: fullAnnotation.annotationFilter || null,
                    };
                  } else if (fullAnnotation.__typename === 'PointCluster') {
                    return {
                      ...fullAnnotation,
                      visible: true,
                      hiddenLabels: cacheAnnotation ? cacheAnnotation.hiddenLabels : [],
                      annotationColor: cacheAnnotation?.annotationColor || { r: 47, g: 89, b: 84, a: 1 },
                      annotationClass: fullAnnotation.annotationClass || 0,
                      groupIdentifier: fullAnnotation.groupIdentifier || null,
                      annotationFilter: fullAnnotation.annotationFilter || null,
                    };
                  } else if (fullAnnotation.__typename === 'Plane') {
                    return {
                      ...fullAnnotation,
                      position: { ...fullAnnotation.position, __typename: 'Position' },
                      normal: { ...fullAnnotation.normal, __typename: 'Position' },
                      visible: true,
                      hiddenLabels: cacheAnnotation ? cacheAnnotation.hiddenLabels : [],
                      annotationColor: cacheAnnotation?.annotationColor || { r: 24, g: 24, b: 24, a: 1 },
                      groupIdentifier: fullAnnotation.groupIdentifier || null,
                      annotationFilter: fullAnnotation.annotationFilter || null,
                    };
                  }
                  return {
                    ...fullAnnotation,
                    visible: true,
                    hiddenLabels: cacheAnnotation ? cacheAnnotation.hiddenLabels : [],
                    annotationColor: cacheAnnotation?.annotationColor || { r: 24, g: 24, b: 24, a: 1 },
                    groupIdentifier: fullAnnotation.groupIdentifier || null,
                  };
                }
                if (
                  fullAnnotation.__typename === 'DistanceAnnotation' ||
                  fullAnnotation.__typename === 'AreaAnnotation'
                ) {
                  return {
                    ...fullAnnotation,
                    visible: cacheAnnotation?.visible || false,
                    hiddenLabels: cacheAnnotation?.hiddenLabels || [],
                    annotationColor: cacheAnnotation?.annotationColor || {
                      __typename: 'Color',
                      r: 24,
                      g: 24,
                      b: 24,
                      a: 1,
                    },
                    groupIdentifier: fullAnnotation.groupIdentifier || null,
                    points: fullAnnotation.points.map((point) => {
                      return { position: point.position, pointCloudId: point.pointCloudId || null };
                    }),
                  };
                } else if (fullAnnotation.__typename === 'PointAnnotation') {
                  return {
                    ...fullAnnotation,
                    position: { ...fullAnnotation.position, __typename: 'Position' },
                    visible: cacheAnnotation?.visible || false,
                    hiddenLabels: cacheAnnotation?.hiddenLabels || [],
                    annotationColor: cacheAnnotation?.annotationColor || { r: 24, g: 24, b: 24, a: 1 },
                    groupIdentifier: fullAnnotation.groupIdentifier || null,
                    classification: fullAnnotation.classification || null,
                    intensity: fullAnnotation.intensity || null,
                    color: fullAnnotation.color ? { ...fullAnnotation.color, __typename: 'Color' } : null,
                    pointCloudId: fullAnnotation.pointCloudId || null,
                  };
                } else if (fullAnnotation.__typename === 'BoxAnnotation') {
                  return {
                    ...fullAnnotation,
                    visible: cacheAnnotation?.visible || false,
                    hiddenLabels: cacheAnnotation?.hiddenLabels || [],
                    annotationColor: cacheAnnotation?.annotationColor || { r: 24, g: 24, b: 24, a: 1 },
                    groupIdentifier: fullAnnotation.groupIdentifier || null,
                    position: { ...fullAnnotation.position, __typename: 'Position' },
                    scale: { ...fullAnnotation.scale, __typename: 'Position' },
                    rotation: { ...fullAnnotation.rotation, __typename: 'Position' },
                  };
                }
                return fullAnnotation;
              }),
              groups: newState.groups.map((annotation) => {
                if (
                  (isGroupAction(commanderAction) &&
                    annotation.identifier === commanderAction?.value.groupIdentifier) ||
                  (commanderAction instanceof DeleteAnnotationMultiAction &&
                    commanderAction.value.identifiers.includes(annotation.identifier))
                )
                  return { ...annotation, __typename: 'AnnotationGroup' as const };
                return { ...annotation, __typename: 'AnnotationGroup' as const };
              }),
              cadLayers: newState.cadLayers.map((layer) => {
                const existingLayer = cadLayersByIdentifier[layer.identifier];
                const newVisibleState = (layer.visibleState || [])
                  .map((state) => {
                    if (state.type === 'HIDDEN')
                      return {
                        ...state,
                        isLayer: state.isLayer || false,
                        layerIdentifier: state.layerIdentifier || null,
                        isGroup: state.isGroup || false,
                        subIndex: state.subIndex === undefined ? null : state.subIndex,
                        __typename: 'CadLayerVisibleStateHidden' as const,
                        userId: `${state.userId}`,
                      };
                    if (state.type === 'DELETE')
                      return {
                        ...state,
                        subIndex: state.subIndex === undefined ? null : state.subIndex,
                        __typename: 'CadLayerVisibleStateDelete' as const,
                      };
                    return null;
                  })
                  .filter(isNotNullOrUndefined);
                return {
                  ...(existingLayer || {}),
                  ...layer,
                  fileUrl: existingLayer?.fileUrl || '',
                  visibleState: newVisibleState,
                };
              }),
              wmsLayers: (newState.wmsLayers || []).map((layer) => {
                const cacheWmsLayer = wmsLayersByIdentifier[layer.identifier];
                return {
                  ...layer,
                  type: layer.type === 'WMS' ? WmsType.Wms : WmsType.Wmts,
                  visible: cacheWmsLayer ? cacheWmsLayer.visible : true,
                };
              }),
              orthophotoLayers: (newState.orthophotoLayers || []).map((layer) => {
                const cacheOrthophotoLayer = orthophotoLayersByIdentifier[layer.identifier];
                return {
                  ...layer,
                  visible: cacheOrthophotoLayer ? cacheOrthophotoLayer.visible : true,
                  url: cacheOrthophotoLayer ? cacheOrthophotoLayer.url : '',
                };
              }),
            },
            pointClouds: project.pointClouds.map((pointCloud) => ({
              ...pointCloud,
              displayName: pointCloudsStateById[pointCloud.id]?.name,
            })),
            calculations: project.calculations,
          },
        },
      });
      if (
        type === 'POINT' ||
        type === 'DISTANCE' ||
        type === 'AREA' ||
        type === 'BOX' ||
        type === 'POINTCLUSTER' ||
        type === 'PLANE'
      )
        dispatch(
          toggleSelectedAnnotation({
            identifier: action.annotationIdentifier,
            clearOtherSelections: true,
            type: 'annotation',
          }),
        );

      return null;
    },
    [addAction, apolloClient, commander, dispatch, projectId],
  );

  return [executeAction];
};
