import { useCallback, useContext, useEffect, useRef } from 'react';
import { RendererContext } from '../../contexts/RendererContext';
import { useRerender } from '../useRerender';
import { Project } from '../../types/graphqlTypes';
import { useExecuteAction } from './useExecuteAction';
import { ViewerContext } from '../../contexts/ViewerContext';

export const useScreenTools = ({ project }: { project?: Pick<Project, 'mapVisible'> }) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [executeAction] = useExecuteAction();
  const { enable2DMode } = useContext(ViewerContext);

  useEffect(() => {
    viewer?.addEventListener('map_overlay_changed', rerender);
    return () => {
      viewer?.removeEventListener('map_overlay_changed', rerender);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!viewer]);

  const onZoomOutStart = useCallback(() => {
    if (!viewer?.orbitControls) return;
    viewer.orbitControls.radiusDelta += 1 * (viewer.scene.view.radius + viewer.orbitControls.radiusDelta) * 0.1;
    intervalRef.current && clearInterval(intervalRef.current);
    timeoutRef.current && clearInterval(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        viewer.orbitControls.radiusDelta += 1 * (viewer.scene.view.radius + viewer.orbitControls.radiusDelta) * 0.1;
      }, 24);
    }, 500);
  }, [viewer?.orbitControls, viewer?.scene.view.radius]);
  const onZoomInStart = useCallback(() => {
    if (!viewer?.orbitControls) return;
    viewer.orbitControls.radiusDelta += -1 * (viewer.scene.view.radius + viewer.orbitControls.radiusDelta) * 0.1;
    intervalRef.current && clearInterval(intervalRef.current);
    timeoutRef.current && clearInterval(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        viewer.orbitControls.radiusDelta += -1 * (viewer.scene.view.radius + viewer.orbitControls.radiusDelta) * 0.1;
      }, 24);
    }, 500);
  }, [viewer?.orbitControls, viewer?.scene.view.radius]);
  const onZoomEnd = useCallback(() => {
    timeoutRef.current && clearInterval(timeoutRef.current);
    intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  const onFullScreen = useCallback(() => {
    if (!document.fullscreenEnabled) return;
    if (!viewer) return;
    viewer.measuringTool.setCurrentTool(undefined);
    viewer.volumeTool.deactivate();
    viewer.selectionTool.activate();
    document.getElementById('potree_render_area')?.requestFullscreen();
  }, [viewer]);

  const fitToScreen = useCallback(() => {
    viewer?.fitToScreen();
  }, [viewer]);

  const flip2DView = useCallback(async () => {
    const line = viewer?.viewerMode.associatedLine;
    if (!line) return;
    const points = line.points.map((p) => p.position);
    if (points.length !== 2) return;
    const action1 = {
      annotationIdentifier: line.identifier,
      position: { ...points[1] },
      pointIndex: 0,
      pointCloudId: '',
    };
    const action2 = {
      annotationIdentifier: line.identifier,
      position: { ...points[0] },
      pointIndex: 1,
      pointCloudId: '',
    };
    await executeAction({ action: action1, type: 'MOVE_POINT' });
    await executeAction({ action: action2, type: 'MOVE_POINT' });
    enable2DMode(line.identifier);
  }, [viewer, executeAction, enable2DMode]);

  return [
    { fitToScreen, onFullScreen, flip2DView },
    { onZoomOutStart, onZoomInStart, onZoomEnd },
  ] as const;
};
