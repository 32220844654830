import { memo } from 'react';
import { Input } from '../../../components/inputs/Input';
import { useConvertedMeasurementInputProps } from '../../../hooks/useConvertedMeasurementInputProps';
import { PropertiesSection, Property } from './PropertiesSection';
import { T } from '../../../translation/src';
import { usePlaneProperties } from '../../../hooks/potree/usePlaneProperties';

const PositionEditProperties_: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [{ position }, { onEditX, onEditY, onEditZ }] = usePlaneProperties();
  const convertMeasurementInputProps = useConvertedMeasurementInputProps();

  return (
    <PropertiesSection title={<T _str="Position" swc />}>
      <Property title={<T _str="X" swc />}>
        <Input
          key={identifier.concat('x')}
          id="inputX"
          onChange={onEditX}
          {...convertMeasurementInputProps({ defaultValue: position.x })}
        />
      </Property>

      <Property title={<T _str="Y" swc />}>
        <Input
          key={identifier.concat('y')}
          id="inputY"
          onChange={onEditY}
          {...convertMeasurementInputProps({ defaultValue: position.y })}
        />
      </Property>

      <Property title={<T _str="Z" swc />}>
        <Input
          key={identifier.concat('z')}
          id="inputZ"
          onChange={onEditZ}
          {...convertMeasurementInputProps({ defaultValue: position.z })}
        />
      </Property>
    </PropertiesSection>
  );
};
export const PositionEditProperties = memo(PositionEditProperties_);
