import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckBox, CheckBoxProps } from '../../../components/inputs/CheckBox';
import { Radio, RadioGroup } from '../../../components/inputs/Radio';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { AppliedFilters, useAppliedFilters } from '../../../hooks/potree/usePointCloudProperties';
import { T } from '../../../translation/src';
import { PointCloud } from '../../../types/graphqlTypes';
import { Filters } from '../../..//components/Filters';

const CheckBoxOption: React.FC<{ title: React.ReactNode; titleClassName?: string } & Omit<CheckBoxProps, 'title'>> = ({
  title,
  titleClassName,
  ...props
}) => {
  return (
    <div className="flex items-center space-x-2">
      <CheckBox {...props} />
      <div className={titleClassName}>{title}</div>
    </div>
  );
};

export interface CalculationModalProps {
  onClose: () => void;
  onSubmit: (pointClouds: { filters: AppliedFilters | undefined; id: string }[]) => Promise<void>;
  open?: boolean;
  title?: React.ReactNode;
  pointClouds: Pick<PointCloud, 'displayName' | 'id' | 'availableClasses'>[];
  multiplePointCloudsSupported?: boolean;
}
const CalculationModal_: React.FC2<CalculationModalProps> = ({
  onClose,
  open,
  pointClouds,
  title,
  onSubmit: onSubmitProps,
  multiplePointCloudsSupported,
}) => {
  const appliedFilters = useAppliedFilters({ project: { pointClouds } });
  const [selectedPointClouds, setSelectedPointClouds] = useState<string[]>(pointClouds.map((p) => p.id));
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: boolean }>(
    Object.entries(appliedFilters).reduce((acc, [key, value]) => ({ ...acc, [key]: value.hasFilters }), {}),
  );

  const resetValues = useCallback(() => {
    setSelectedPointClouds(pointClouds.map((p) => p.id));
    setSelectedFilters(
      Object.entries(appliedFilters).reduce((acc, [key, value]) => ({ ...acc, [key]: value.hasFilters }), {}),
    );
  }, [appliedFilters, pointClouds]);

  useEffect(() => {
    resetValues();
  }, [resetValues]);

  const onSuccess = useCallback(() => {
    onClose();
    // Because of transition
    setTimeout(() => resetValues, 250);
  }, [onClose, resetValues]);

  const onSubmit = useCallback(async () => {
    await onSubmitProps(
      selectedPointClouds.map((pc) => ({
        id: pc,
        filters: selectedFilters[pc] ? appliedFilters[pc].filters : undefined,
      })),
    );
    onSuccess();
  }, [appliedFilters, onSuccess, onSubmitProps, selectedFilters, selectedPointClouds]);

  return (
    <SubmitModal
      title={title}
      actionButtonTitle={<T _str="start calculation" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={false}
      onSubmit={onSubmit}
    >
      <div className="mb-2 text-sm text-gray-500">
        <T _str="On which layers do you want to execute the calculation?" />
      </div>
      <div className="border border-gray-300 rounded">
        {multiplePointCloudsSupported && (
          <div className="p-3 border-b border-gray-300">
            <CheckBoxOption
              title={<T _str="All layers" />}
              checked={selectedPointClouds.length === pointClouds.length}
              onChange={() =>
                setSelectedPointClouds((pcs) => {
                  if (pcs.length === pointClouds.length) return [];
                  if (pcs.length === 0) return pointClouds.map((p) => p.id);
                  return pointClouds.map((p) => p.id).filter((id) => !pcs.includes(id));
                })
              }
            />
          </div>
        )}
        <div className="p-3">
          {multiplePointCloudsSupported ? (
            pointClouds.map((pointCloud) => {
              const filters = appliedFilters[pointCloud.id]?.filtersArray.filter(
                (filter) => filter.value !== undefined,
              );
              return (
                <div key={pointCloud.id} className="flex flex-col">
                  <CheckBoxOption
                    title={pointCloud.displayName}
                    titleClassName={'truncate'}
                    checked={selectedPointClouds.includes(pointCloud.id)}
                    onChange={() =>
                      setSelectedPointClouds((pcs) =>
                        pcs.includes(pointCloud.id)
                          ? pcs.filter((id) => id !== pointCloud.id)
                          : pcs.concat(pointCloud.id),
                      )
                    }
                  />
                  <Filters
                    filters={filters}
                    pointCloudId={pointCloud.id}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                  />
                </div>
              );
            })
          ) : (
            <RadioGroup
              name={`pointclouds`}
              value={selectedPointClouds[0]}
              onChange={({ target: { value } }) => setSelectedPointClouds([value])}
            >
              {pointClouds.map((pointCloud) => {
                const filters = appliedFilters[pointCloud.id]?.filtersArray.filter(
                  (filter) => filter.value !== undefined,
                );
                return (
                  <Radio
                    value={pointCloud.id}
                    labelClassName="truncate"
                    extraContent={
                      <Filters
                        filters={filters}
                        pointCloudId={pointCloud.id}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                      />
                    }
                    key={pointCloud.id}
                  >
                    {pointCloud.displayName}
                  </Radio>
                );
              })}
            </RadioGroup>
          )}
        </div>
      </div>
    </SubmitModal>
  );
};

export const CalculationModal = memo(CalculationModal_);
