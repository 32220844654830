import { memo } from 'react';
import { Input } from '../../../components/inputs/Input';
import { PropertiesSection, Property } from './PropertiesSection';
import { T } from '../../../translation/src';
import { usePlaneProperties } from '../../../hooks/potree/usePlaneProperties';

const PlaneAngleEditProperties_: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [{ inclination, bearing }, { onEditInclination, onEditBearing }] = usePlaneProperties();
  return (
    <PropertiesSection title={<T _str="Orientation" swc />}>
      <Property title={<T _str="Inclination" swc />}>
        <Input
          key={identifier.concat('Inclination')}
          id="inputVERT"
          onChange={onEditInclination}
          step="0.01"
          min={0}
          max={90}
          type="number"
          defaultValue={inclination.toFixed(2)}
        />
      </Property>

      <Property title={<T _str="Bearing" swc />}>
        <Input
          key={identifier.concat('Bearing')}
          id="inputHOR"
          onChange={onEditBearing}
          step="0.01"
          min={0}
          max={360}
          type="number"
          defaultValue={bearing.toFixed(2)}
        />
      </Property>
    </PropertiesSection>
  );
};
export const PlaneAnglesEditProperties = memo(PlaneAngleEditProperties_);
