import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { RendererContext } from './RendererContext';
import { RendererToolType, useRendererTools } from '../hooks/potree/useRenderer';
import { ClipMethods, ProjectByIdQuery, useProjectUpdateClippingMethodMutation } from '../types/graphqlTypes';
import { useParams } from 'react-router-dom';

export type ViewerContextType = {
  in2DMode: boolean;
  planeId: string;
  toggle2DMode: (planeId: string) => void;
  enable2DMode: (planeId: string) => void;
  disable2DMode: () => void;
};
export const ViewerContext = createContext<ViewerContextType>({
  in2DMode: false,
  planeId: '',
  toggle2DMode: () => {
    return;
  },
  enable2DMode: () => {
    return;
  },
  disable2DMode: () => {
    return;
  },
});

export const ViewerContextProvider = ({
  project,
  children,
}: {
  project?: ProjectByIdQuery['projectById'];
  children: ReactNode;
}) => {
  const [in2DMode, set2DMode] = useState<boolean>(false);
  const [planeId, setPlaneId] = useState<string>('');
  const [initialised, setInitialised] = useState<boolean>(false);
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const [_, { changeTool }] = useRendererTools();
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const { projectId = '' } = useParams();

  useEffect(() => {
    if (initialised || !project) return;

    if (project.mapVisible) {
      set2DMode(true);
      setPlaneId('map');
    }
    setInitialised(true);
  }, [project, initialised]);

  const stopIsolateOldPlane = useCallback(() => {
    if (!project) return;
    if (planeId === 'map' || planeId === '') return;
    updateClippingMethod({
      variables: { projectId, annotationIdentifiers: [planeId], clipMethod: ClipMethods.None },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUpdateClippingMethod: {
          id: projectId,
          state: {
            annotations: project.state.annotations.map((someAnnotation) => {
              if (someAnnotation.identifier === planeId && someAnnotation.__typename === 'Plane') {
                return {
                  ...someAnnotation,
                  annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.None },
                };
              }
              return someAnnotation;
            }),
          },
        },
      },
    });
  }, [project, updateClippingMethod, planeId, projectId]);

  const isolateNewPlane = useCallback(
    (newPlaneId: string) => {
      if (!project) return;
      if (newPlaneId === '' || newPlaneId === 'map') return;
      updateClippingMethod({
        variables: { projectId, annotationIdentifiers: [newPlaneId], clipMethod: ClipMethods.Isolate },
        optimisticResponse: {
          __typename: 'Mutation',
          projectUpdateClippingMethod: {
            id: projectId,
            state: {
              annotations: project.state.annotations.map((someAnnotation) => {
                if (someAnnotation.identifier === newPlaneId && someAnnotation.__typename === 'Plane') {
                  return {
                    ...someAnnotation,
                    annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.Isolate },
                  };
                }
                return someAnnotation;
              }),
            },
          },
        },
      });
    },
    [project, updateClippingMethod, projectId],
  );

  const toggle2DMode = useCallback(
    (newPlaneId: string) => {
      if (!viewer) return;

      if (in2DMode) {
        stopIsolateOldPlane();
        if (planeId === newPlaneId) {
          set2DMode(false);
          setPlaneId('');
          viewer.viewerMode.disable2DMode();
          changeTool({ type: RendererToolType.SELECT });
        } else {
          setPlaneId(newPlaneId);
          viewer.viewerMode.enable2DMode(newPlaneId);
          isolateNewPlane(newPlaneId);
        }
      } else {
        set2DMode(true);
        setPlaneId(newPlaneId);
        viewer.viewerMode.enable2DMode(newPlaneId);
        isolateNewPlane(newPlaneId);
        changeTool({ type: RendererToolType.SELECT });
      }
    },
    [changeTool, in2DMode, planeId, viewer, stopIsolateOldPlane, isolateNewPlane],
  );

  const enable2DMode = useCallback(
    (newPlaneId: string) => {
      if (!in2DMode) {
        changeTool({ type: RendererToolType.SELECT });
        set2DMode(true);
      }
      if (planeId !== newPlaneId) {
        stopIsolateOldPlane();
        setPlaneId(newPlaneId);
        isolateNewPlane(newPlaneId);
      }
      viewer?.viewerMode.enable2DMode(newPlaneId);
    },
    [changeTool, in2DMode, planeId, viewer, stopIsolateOldPlane, isolateNewPlane],
  );

  const disable2DMode = useCallback(() => {
    if (in2DMode) {
      stopIsolateOldPlane();
      changeTool({ type: RendererToolType.SELECT });
      set2DMode(false);
      setPlaneId('');
      viewer?.viewerMode.disable2DMode();
    }
  }, [changeTool, in2DMode, viewer, stopIsolateOldPlane]);

  return (
    <ViewerContext.Provider value={{ in2DMode, planeId, toggle2DMode, enable2DMode, disable2DMode }}>
      {children}
    </ViewerContext.Provider>
  );
};
