import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { memo, MouseEventHandler } from 'react';

interface FavoriteIconProps {
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const FavoriteIcon_: React.FC2<FavoriteIconProps> = ({ checked, className, ...props }) => {
  return (
    <div {...props} className={classNames(className, 'absolute w-6 stroke-current cursor-pointer')}>
      {checked ? <SolidStarIcon className="text-white" /> : <StarIcon className="text-white" />}
    </div>
  );
};

export const FavoriteIcon = memo(FavoriteIcon_) as typeof FavoriteIcon_;
