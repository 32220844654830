import { memo, useContext } from 'react';
import { T } from '../../../translation/src';
import { Annotation, Color } from '../../../types/graphqlTypes';
import { Property } from './PropertiesSection';
import { RendererReadOnlyContext } from '../../../contexts/RendererReadOnlyContext';
import { EditAnnotationColors } from './EditAnnotationColor';
import { useParams } from 'react-router-dom';

const StaticColorProperty_: React.FC<{ color?: Color | null }> = ({ color }) => {
  return (
    <Property title={<T _str="color" swc />}>
      {color ? (
        <div
          className="w-5 h-5 rounded"
          style={{
            backgroundColor: `rgba(${color.r},${color.g},${color.b},${color.a || 1})`,
          }}
        />
      ) : (
        '-'
      )}
    </Property>
  );
};

export const StaticColorProperty = memo(StaticColorProperty_);

const EditableColorProperty_: React.FC<{ annotations: Annotation[] }> = ({ annotations }) => {
  const { projectId = '' } = useParams();
  const isReadOnly = useContext(RendererReadOnlyContext);
  if (isReadOnly) {
    return null;
  }
  return (
    <Property title={<T _str="object color" swc />}>
      <EditAnnotationColors annotations={annotations} projectId={projectId} />
    </Property>
  );
};

export const EditableColorProperty = memo(EditableColorProperty_);
