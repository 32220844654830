import { useContext, useMemo } from 'react';
import { AnnotationContext, AnnotationContextGroup } from '../../contexts/AnnotationContext';
import { useCustomSelector } from '../../redux/store';
import { Annotation } from '../../types/graphqlTypes';
import { useAnnotations } from './useRenderer';

// Filter out groups and return flat list of annotations

export const useFlatAnnotationList = () => {
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);

  const annotationContext = useContext(AnnotationContext);

  const [{ annotationsByIdentifier, annotations }] = useAnnotations();

  const allSelectedAnnotations = useMemo(() => {
    return selectedAnnotations.reduce((result: Annotation[], selectionItem) => {
      if (selectionItem.type === 'annotation') {
        const annotation = annotationsByIdentifier[selectionItem.identifier];
        if (annotation) {
          return [...result, annotation];
        }
      } else if (selectionItem.type === 'group') {
        const group = annotations.find(
          (annotation) => annotation.identifier === selectionItem.identifier,
        ) as AnnotationContextGroup;
        if (group) {
          return [...result, ...group.annotations];
        }
      }
      return result;
    }, []);
  }, [annotations, annotationsByIdentifier, selectedAnnotations]);

  const allAnnotations = useMemo(() => {
    // filter out groups
    return annotationContext.annotations.reduce((result: Annotation[], annotation) => {
      if ('annotations' in annotation) {
        return [...result, ...annotation.annotations];
      }
      return [...result, annotation];
    }, []);
  }, [annotationContext]);

  return { allSelectedAnnotations, allAnnotations };
};
