import classNames from 'classnames';
import { memo, MouseEventHandler, useCallback } from 'react';
import { HeroIcon } from '../../types';

interface IconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  disabled?: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | HeroIcon;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Icon_: React.FC<IconProps> = ({
  disabled,
  icon: Icon,
  onClick: onClickProps,
  className: propsClassName,
  ...props
}) => {
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => !disabled && onClickProps?.(event),
    [disabled, onClickProps],
  );

  return (
    <div
      className={classNames(disabled ? 'cursor-not-allowed' : 'cursor-pointer', propsClassName)}
      onClick={onClick}
      {...props}
    >
      <Icon className="stroke-current" />
    </div>
  );
};

export const Icon = memo(Icon_);
