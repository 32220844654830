import classNames from 'classnames';
import moment from 'moment';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { Radio } from '../../../components/inputs/Radio';
import { UserContext } from '../../../contexts/UserContext';
import { T, useT } from '../../../translation/src';
import {
  useBillingCheckoutUrlMutation,
  useBillingProductsQuery,
  useOrganisationsQuery,
} from '../../../types/graphqlTypes';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { POINTORAMA_CONTACT_URL } from '../../../utils/constants';

interface UpgradeModal {
  onClose: () => void;
  open?: boolean;
  monthlyPrice: { id: string; price: number; currency?: string };
  yearlyPrice: { id: string; price: number; currency?: string };
}
const UpgradeModal_: React.FC2<UpgradeModal> = ({ onClose, open, monthlyPrice, yearlyPrice }) => {
  const [isYearly, setIsYearly] = useState(true);
  const translations = useTranslations();
  const [checkoutUrl, { loading }] = useBillingCheckoutUrlMutation();
  const { organisationId = '' } = useParams();

  const onUpgrade = useCallback(async () => {
    const priceId = isYearly ? yearlyPrice?.id : monthlyPrice?.id;
    if (!priceId) return;
    const url = await checkoutUrl({ variables: { organisationId, priceId } });
    window.location.replace(url.data?.billingCheckoutUrl || '');
  }, [checkoutUrl, isYearly, monthlyPrice?.id, organisationId, yearlyPrice?.id]);

  return (
    <SubmitModal
      title={translations.choosePlan}
      actionButtonTitle={translations.checkout}
      onClose={onClose}
      open={open}
      onSubmit={onUpgrade}
      isSubmitting={loading}
    >
      <div className="flex flex-col w-full overflow-hidden border border-gray-300 divide-y rounded-md">
        {[yearlyPrice, monthlyPrice].map((price, index) => (
          <div
            className={classNames(
              'flex items-center w-full p-3 cursor-pointer hover:bg-green-200 dark:hover:bg-black',
              ((isYearly && index === 0) || (!isYearly && index !== 0)) && 'bg-green-200 dark:bg-black',
            )}
            onClick={() => setIsYearly(index === 0)}
          >
            <div className="mr-2">
              <Radio
                checked={(index === 0 && isYearly) || (index === 1 && !isYearly)}
                onClick={() => setIsYearly(index === 0)}
              />
            </div>
            <div className="flex w-40 font-bold">
              {index === 0 ? <T _str="yearly plan" swc /> : <T _str="monthly plan" swc />}
            </div>
            <div className="flex">
              {price.price} {price.currency}/{index === 0 ? translations.year : translations.month}
            </div>
          </div>
        ))}
      </div>
    </SubmitModal>
  );
};
const UpgradeModal = memo(UpgradeModal_);

const formatDate = (date: Date) => moment(date).format('DD/MM/YYYY');

interface OptionRowProps {
  title: string;
  monthlyPrice?: { id: string; price: number; currency?: string };
  yearlyPrice?: { id: string; price: number; currency?: string };
  description: string;
  isCustom?: boolean;
  isFreeTrial?: boolean;
  freeTrialEndDate?: string;
  isCurrent?: boolean;
  endDate?: Date;
}
const OptionRow: React.FC2<OptionRowProps> = ({
  description,
  title,
  monthlyPrice,
  yearlyPrice,
  isCustom,
  isCurrent,
  isFreeTrial,
  endDate,
}) => {
  const translations = useTranslations();
  const { smDevice, xsDevice } = useDeviceSize();

  const priceContent = useMemo(() => {
    if (isCustom) return translations.custom;
    if (isFreeTrial) return `${translations.free} ${formatDate(endDate || new Date())}`;
    if (!monthlyPrice || !yearlyPrice) return null;
    if (endDate) return `${translations.nextPaymentOn} ${formatDate(endDate)}`;
    return (
      <>
        <div className="mr-2">
          {monthlyPrice.price} {monthlyPrice.currency}/{translations.month}
        </div>
        <div className="text-gray-500">
          {' '}
          ({yearlyPrice.price} {yearlyPrice.currency}/{translations.year})
        </div>
      </>
    );
  }, [isCustom, translations, isFreeTrial, endDate, monthlyPrice, yearlyPrice]);

  const onClickContactSales = useCallback(() => {
    return window.open(POINTORAMA_CONTACT_URL, '_blank');
  }, []);

  const currentPlan = (
    <div className="bg-black text-white py-2.5 px-3.5 rounded-full text-sm">
      {xsDevice ? <T _str="current" swc /> : <T _str="current plan" swc />}
    </div>
  );

  return (
    <div
      className={classNames(
        'flex items-center w-full p-3 dark:bg-[#242424]',
        isCurrent && 'bg-neon-green-300 dark:bg-neon-green-400 dark:text-black',
      )}
    >
      <div className="flex w-48 font-bold">{title}</div>
      {!smDevice && <div className="flex w-72">{priceContent}</div>}
      <div className="flex flex-grow text-gray-500">{description}</div>
      {isFreeTrial && currentPlan}
      {!isFreeTrial && (
        <div className="flex justify-end w-36">
          {!isCurrent ? (
            <Button variant="primary" size="xs" onClick={onClickContactSales}>
              {xsDevice ? <T _str="contact" swc /> : <T _str="contact sales" swc />}
            </Button>
          ) : (
            currentPlan
          )}
        </div>
      )}
    </div>
  );
};

const useTranslations = () => {
  const plan = useT('plan', { swc: true });
  const contact = useT('contact', { swc: true });
  const contactSales = useT('contact sales', { swc: true });
  const custom = useT('custom', { swc: true });
  const month = useT('month', { swc: true });
  const checkout = useT('checkout', { swc: true });
  const year = useT('year', { swc: true });
  const freeTrial = useT('free trial', { swc: true });
  const free = useT('free til', { swc: true });
  const nextPaymentOn = useT('next payment on', { swc: true });
  const freeTrialDescription = useT('500 million points limitation', { swc: true });
  const choosePlan = useT('choose plan', { swc: true });
  const upgrade = useT('upgrade', { swc: true });
  return {
    plan,
    contact,
    contactSales,
    custom,
    month,
    year,
    freeTrial,
    free,
    freeTrialDescription,
    choosePlan,
    upgrade,
    nextPaymentOn,
    checkout,
  };
};

const BillingOptions_: React.FC2 = ({ className }) => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { data: billingProductsData } = useBillingProductsQuery({ variables: { organisationId } });
  const user = useContext(UserContext);
  const { data: organisations } = useOrganisationsQuery(user ? { variables: { includeIds: [organisationId] } } : {});
  const billingProducts = billingProductsData?.billingProducts;

  const organisation = organisations?.organisations?.find((org) => org.id === organisationId);
  const { smDevice, xsDevice } = useDeviceSize();

  return (
    <div
      className={classNames(
        'flex flex-col w-full overflow-hidden border border-gray-300 divide-y rounded-md',
        className,
      )}
    >
      <OptionRow
        isCurrent
        description={smDevice ? '' : translations.freeTrialDescription}
        title={translations.freeTrial}
        isFreeTrial
        endDate={organisation?.subscription.endDate}
      />
      {billingProducts?.map((product) => {
        const priceMonth = product.prices.find((price) => price.period === 'month');
        const priceYear = product.prices.find((price) => price.period === 'year');
        if (!priceYear || !priceMonth) return null;
        return (
          <OptionRow
            key={product.name}
            description={smDevice ? '' : product.description}
            monthlyPrice={{ id: priceMonth?.id, price: priceMonth?.value, currency: priceMonth?.currency }}
            yearlyPrice={{ id: priceYear?.id, price: priceYear?.value, currency: priceYear?.currency }}
            title={xsDevice ? product.name.replace('Pointorama', '').trim() : product.name}
            isCurrent={!!product.isActive}
            endDate={product.endDate}
          />
        );
      })}
      <OptionRow isCustom description="" title={translations.custom} />
    </div>
  );
};

export const BillingOptions = memo(BillingOptions_);
