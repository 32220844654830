import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { Button } from '../Button';

interface WarningLabelProps {
  type: 'warning' | 'warning-red';
  label: React.ReactNode;
  icon?: React.FC<{ className?: string }>;
}
const WarningLabel_: React.FC2<WarningLabelProps> = ({ className: propsClassName, type, label, icon: IconProp }) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  let className = 'text-white bg-warning-yellow-700';
  if (type === 'warning-red') className = 'text-white bg-error-red-700';
  if (type === 'warning') className = 'text-white bg-warning-yellow-700';

  const icon = IconProp ? <IconProp className="w-8 h-8" /> : <ExclamationTriangleIcon className="w-8 h-8" />;
  return visible ? (
    <div className={classNames('py-2 px-5 w-full', className, propsClassName)}>
      <div className="flex items-center justify-between space-x-2">
        {icon}
        <div className="text-center">{label}</div>
        <div>
          <Button variant="tertiary" size="xs" onClick={onDismiss}>
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
export const WarningLabel = memo(WarningLabel_);
