import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import { Input, InputProps } from '../../inputs/Input';
import { Label } from '../Label';

interface FormInputProps extends InputProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}

const FormInput_: React.FC2<FormInputProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  ...props
}) => {
  return (
    <label className={classNames('block relative text-black dark:text-white', propsClassName)}>
      <Label>{label}</Label>
      <Input id={name} name={name} className={classNames(inputClassName, error && 'input-error', 'mt-1')} {...props} />
      <div className="flex items-center h-4 mt-1 text-xs text-error-red-500">{!hideErrorLabel && error}</div>
    </label>
  );
};

export const FormInput = memo(FormInput_) as typeof FormInput_;

export interface FormikInputProps extends FormInputProps {
  name: string;
  onChange?: FormInputProps['onChange'];
}

const FormikInput_: React.FC2<FormikInputProps> = ({ name, onChange: customOnChange, ...props }) => {
  const [{ onChange: fieldOnChange, ...field }, meta] = useField(name);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      fieldOnChange(e);
      customOnChange?.(e);
    },
    [fieldOnChange, customOnChange],
  );

  return (
    <FormInput error={meta.touched && meta.error ? meta.error : undefined} {...props} {...field} onChange={onChange} />
  );
};

export const FormikInput = memo(FormikInput_) as typeof FormikInput_;
