import { useProjectRequestDownloadTokenLazyQuery } from '../types/graphqlTypes';

export const useDownload = () => {
  const [getDownloadToken] = useProjectRequestDownloadTokenLazyQuery();

  const downloadOriginalFile = async (projectId: string, pointcloudId: string) => {
    if (!projectId) return;
    const { data: tokenData } = await getDownloadToken({ variables: { projectId } });
    await fetch(`${import.meta.env.VITE_NX_SERVER}/project/${projectId}/pointcloud/${pointcloudId}/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: tokenData?.projectRequestDownloadToken }),
    })
      .then((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'download.zip';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }
        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => console.log('failed: ', e));
  };

  return downloadOriginalFile;
};
