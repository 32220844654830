import { Switch } from '@headlessui/react';
import { memo } from 'react';

interface SwitchToggleProps {
  onChange: (value: boolean) => void;
  switchedOn: boolean;
}

const SwitchToggle_: React.FC2<SwitchToggleProps> = ({ onChange, switchedOn }) => {
  return (
    <Switch
      checked={switchedOn}
      onChange={() => onChange(!switchedOn)}
      className={`${switchedOn ? 'bg-neon-green-700 dark:bg-neon-green-300' : 'bg-gray-300 dark:bg-[#404040]'} relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span
        className={`${switchedOn ? 'translate-x-6 dark:bg-ash-green-800' : 'translate-x-1 dark:bg-gray-400'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};

export const SwitchToggle = memo(SwitchToggle_);
