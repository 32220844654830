import { useAnnotations } from '../../../hooks/potree/useRenderer';
import {
  GeneralStatus,
  PointCloud,
  Project,
  useProjectDeleteExportMutation,
  useProjectExportPointcloudMutation,
} from '../../../types/graphqlTypes';
import { memo, useMemo } from 'react';
import { CalculationList } from './CalculationList';
import { ExportModal, ExportModalProps } from '../../project/ExportModal';
import { DATA_DEPTH } from '../../../hooks/potree/usePointCloudProperties';
import { ExportList } from './ExportList';
import { Button } from '../../../components/Button';
import useOpen from '../../../hooks/useOpen';
import { T, useT } from '../../../translation/src';
import { useFormatDate } from '../../../hooks/useFormatDate';

interface PointCloudActionsProps {
  pointCloud: Pick<PointCloud, 'displayName' | 'id' | 'availableClasses'>;
  project: Pick<Project, 'id' | 'exports'>;
}

const useExportTranslations = () => {
  const exportTitle = useT('export', { swc: true });
  return { exportTitle };
};

const $PointCloudActions: React.FC2<PointCloudActionsProps> = ({ pointCloud, project }) => {
  const [{ annotationsByIdentifier, calculationsByIdentifier }] = useAnnotations();
  const [exportPointcloud] = useProjectExportPointcloudMutation();
  const [deleteExport] = useProjectDeleteExportMutation();
  const { formatDateAndTime } = useFormatDate();
  const { onOpen: openExportModal, onClose: closeExportModal, open: exportModalOpen } = useOpen();
  const translations = useExportTranslations();

  const calculationsOnPointCloudNav = useMemo(() => {
    const calculationsOnPointCloud = Object.values(calculationsByIdentifier)
      .flat()
      .filter((calculation) => {
        const annotation = annotationsByIdentifier[calculation.annotationIdentifier];
        const annotationOverlapsPointCloud =
          (annotation?.__typename === 'AreaAnnotation' || annotation?.__typename === 'DistanceAnnotation') &&
          annotation?.points.some((point) => {
            return point.pointCloudId === pointCloud.id;
          });
        return annotationOverlapsPointCloud;
      });
    return <CalculationList title="Calculations on this point cloud" listedCalculations={calculationsOnPointCloud} />;
  }, [annotationsByIdentifier, calculationsByIdentifier, pointCloud.id]);

  const exportsOnPointCloud = useMemo(() => {
    if (!project) return;
    const exportLoading = project.exports.find(
      (pointcloudExport) => pointcloudExport.status === GeneralStatus.Progress,
    );

    const onSubmitExport: ExportModalProps['onSubmit'] = async (pointClouds, extension) => {
      pointClouds.forEach((currentPointCloud) => {
        exportPointcloud({
          variables: {
            projectId: project.id,
            pointCloudId: currentPointCloud.id,
            fileType: extension,
            classificationFilters: currentPointCloud.filters?.classifications?.length
              ? currentPointCloud.filters?.classifications.map((cl) => +cl.code)
              : undefined,
            ...(currentPointCloud.filters?.heightFilter && {
              heightFilters: [currentPointCloud.filters?.heightFilter.min, currentPointCloud.filters?.heightFilter.max],
            }),
            ...(currentPointCloud.filters?.intensityFilter && {
              intensityFilters: [
                currentPointCloud.filters?.intensityFilter.min,
                currentPointCloud.filters?.intensityFilter.max,
              ],
            }),
            ...(currentPointCloud.filters?.resolutionFilter && {
              resolutionFilter: currentPointCloud.filters.resolutionFilter.resolutionLevel + DATA_DEPTH,
            }),
            ...(currentPointCloud.filters?.clippingFilter && {
              clippingFilter:
                currentPointCloud.filters.clippingFilter.nrOfClippingBoxes +
                currentPointCloud.filters.clippingFilter.nrOfClippingClusters,
            }),
          },
        });
      });
    };

    const onCancelExport = () => {
      if (!exportLoading) return;
      deleteExport({
        variables: { projectId: project.id, exportId: exportLoading.exportId },
      });
    };

    const exportsOnPointCloud = project.exports
      .map((pointcloudExport) => {
        const date = new Date(pointcloudExport.createdAt);
        return {
          date: formatDateAndTime(date),
          exportId: pointcloudExport.exportId,
          paramInfo: pointcloudExport.paramInfo,
          status: pointcloudExport.status,
        };
      })
      .flat();

    return (
      <div className="flex flex-col p-4">
        <ExportList projectId={project.id} title="Exports on this point cloud" exports={exportsOnPointCloud} />
        <div className="items-center justify-center w-full mt-4">
          <Button
            variant="secondary"
            size="sm"
            onClick={openExportModal}
            className="w-full"
            loading={!!exportLoading}
            loadingTitle={<T _str="exporting" swc />}
            onCancel={onCancelExport}
          >
            <T _str="Export point cloud" swc />
          </Button>
          <div className="mt-3 text-xs italic text-white ">
            <T _str="This export can take some time. You will get a notification when your export is ready." />
          </div>
        </div>
        <ExportModal
          pointClouds={pointCloud ? [pointCloud] : []}
          onClose={closeExportModal}
          open={exportModalOpen}
          title={translations.exportTitle}
          onSubmit={onSubmitExport}
          multiplePointCloudsSupported={false}
        />
      </div>
    );
  }, [
    closeExportModal,
    deleteExport,
    exportModalOpen,
    exportPointcloud,
    formatDateAndTime,
    openExportModal,
    pointCloud,
    project,
    translations.exportTitle,
  ]);

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      {exportsOnPointCloud}
      {calculationsOnPointCloudNav}
    </div>
  );
};

export const PointCloudActions = memo($PointCloudActions);
