import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { T } from '../../../translation/src';
import { Export, useProjectDeleteExportMutation } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

interface ProjectDeleteExportModalProps {
  onClose: () => void;
  open?: boolean;
  exports?: Pick<Export, 'exportId'>[];
}
const $ProjectDeleteExportModal: React.FC2<ProjectDeleteExportModalProps> = ({ onClose, open, exports }) => {
  const { projectId = '' } = useParams();
  const [deleteExport, { loading: isSubmitting }] = useProjectDeleteExportMutation();

  const onSubmit = useCallback(() => {
    if (!exports) return;
    exports.forEach((e) => {
      deleteExport({
        variables: { projectId, exportId: e.exportId },
      });
    });
    onClose();
  }, [deleteExport, exports, onClose, projectId]);

  return (
    <SubmitModal
      title={<T _str="delete Export" swc />}
      actionButtonTitle={<T _str="delete Export" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={<T _str="Deleting this export will delete it for all users. Are you sure you want to delete?" />}
      />
    </SubmitModal>
  );
};

export const ProjectDeleteExportModal = memo($ProjectDeleteExportModal);
