import { FormikInput } from '../../../components/formik/FormInput';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import classNames from 'classnames';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import * as uuid from 'uuid';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(translations.isRequired).max(120, translations.maxLength120),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  name: string;
}

const useTranslations = () => {
  const namePlaceholder = useT('enter a group name', { swc: true });
  return { namePlaceholder };
};

interface AnnotationGroupModalProps {
  onClose: () => void;
  open?: boolean;
  annotationIdentifiers: string[];
}
const AnnotationGroupModal_: React.FC2<AnnotationGroupModalProps> = ({ onClose, open, annotationIdentifiers }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const [executeAction] = useExecuteAction();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values, helpers) => {
      executeAction({
        type: 'ADD_GROUP',
        action: { name: values.name, annotationIdentifiers, groupIdentifier: uuid.v4() },
      });
      onSuccess({ helpers });
    },
    [annotationIdentifiers, executeAction, onSuccess],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { name: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={<T _str="create group" swc />}
      createButtonTitle={<T _str="create group" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
    >
      <div className={classNames('grid grid-cols-1 gap-6 mb-6')}>
        <FormikInput autoFocus name="name" label={<T _str="name" swc />} placeholder={translations.namePlaceholder} />
      </div>
    </CreateModal>
  );
};

export const AnnotationGroupModal = memo(AnnotationGroupModal_);
