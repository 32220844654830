import classNames from 'classnames';
import { memo } from 'react';

const Footer_: React.FC2 = ({ children, className, ...props }) => {
  return (
    <div className={classNames(className, 'flex items-center text-lg mt-6')} {...props}>
      {children}
    </div>
  );
};

export const Footer = memo(Footer_);
