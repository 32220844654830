import classNames from 'classnames';
import { memo } from 'react';

const ProgressBar_: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className="flex items-center w-full mt-4 space-x-2">
      <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
        <div
          className={classNames(
            'bg-blue-600 text-xs text-blue-100 text-center p-0.5 leading-none rounded-full dark:bg-neon-green-500 dark:text-gray-800 font-bold h-3',
            !progress && 'px-0',
          )}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="flex justify-end flex-shrink-0 w-10">{progress}%</div>
    </div>
  );
};

export const ProgressBar = memo(ProgressBar_);
