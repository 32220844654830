import { memo, useCallback } from 'react';
import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { T } from '../../../translation/src';
import { Organisation, useOrganisationDeleteMutation } from '../../../types/graphqlTypes';

interface OrganisationDeleteModalProps {
  onClose: () => void;
  open?: boolean;
  organisation?: Pick<Organisation, 'id'>;
}
const OrganisationDeleteModal_: React.FC2<OrganisationDeleteModalProps> = ({ onClose, open, organisation }) => {
  const [deleteOrganisation, { loading: isSubmitting }] = useOrganisationDeleteMutation();

  const onSubmit = useCallback(() => {
    if (!organisation?.id) return;
    deleteOrganisation({
      variables: { organisationId: organisation?.id },
      onCompleted: onClose,
      update: (cache) => cache.evict({ id: `Organisation:${organisation?.id}` }),
    });
  }, [deleteOrganisation, onClose, organisation?.id]);

  return (
    <SubmitModal
      title={<T _str="delete organisation" swc />}
      actionButtonTitle={<T _str="delete organisation" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={
          <T _str="Deleting this organisation will delete all its resources too. Are you sure you want to delete this organisation? This action can not be undone!" />
        }
      />
    </SubmitModal>
  );
};

export const OrganisationDeleteModal = memo(OrganisationDeleteModal_);
