import { useMutation } from '@apollo/client';
import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { ORGANISATIONS } from '../../../graphql/organisation';
import { PROJECT_DELETE } from '../../../graphql/project';
import { T } from '../../../translation/src';
import { Project, ProjectDeleteMutation, ProjectDeleteMutationVariables } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';

interface ProjectDeleteModalProps {
  onClose: () => void;
  open?: boolean;
  project?: Pick<Project, 'id'>;
}
const ProjectDeleteModal_: React.FC2<ProjectDeleteModalProps> = ({ onClose, open, project }) => {
  const [deleteProject, { loading: isSubmitting }] = useMutation<ProjectDeleteMutation, ProjectDeleteMutationVariables>(
    PROJECT_DELETE,
  );

  const onSubmit = useCallback(() => {
    if (!project) return;
    deleteProject({
      variables: { projectId: project?.id },
      onCompleted: onClose,
      update: (cache) => cache.evict({ id: `Project:${project?.id}` }),
      refetchQueries: [{ query: ORGANISATIONS }],
      awaitRefetchQueries: true,
    });
  }, [deleteProject, onClose, project]);

  return (
    <SubmitModal
      title={<T _str="delete project" swc />}
      actionButtonTitle={<T _str="delete project" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={
          <T _str="Deleting this project will delete it for all users. Are you sure you want to delete this project?" />
        }
      />
    </SubmitModal>
  );
};

export const ProjectDeleteModal = memo(ProjectDeleteModal_);
