import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { T } from '../../../translation/src';
import useOpen from '../../../hooks/useOpen';
import { RendererContext } from '../../../contexts/RendererContext';
import { writeArrayBuffer } from 'geotiff';
import { ProjectionSystems } from '../../../utils/constants';
import { Project } from '../../../types/graphqlTypes';
import { Vector2 } from 'three';
import { FormikCheckBox } from '../../../components/formik/FormCheckBox';
import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { toast } from 'react-toastify';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';

enum ImageExportType {
  TIFF = 'tiff',
  JPG = 'jpg',
  PNG = 'png',
}

const exportTypes = [
  { value: ImageExportType.TIFF, label: 'TIFF' },
  { value: ImageExportType.JPG, label: 'JPG' },
  { value: ImageExportType.PNG, label: 'PNG' },
];

interface FormValues {
  includeObjects: boolean;
  fileType: ImageExportType;
}

interface ScreenshotParameters {
  origin: Vector2;
  width: number;
  height: number;
}

interface OrthophotoModalProps {
  project: Pick<Project, 'id' | 'name' | 'description' | 'previewUrl' | 'settings'>;
}

function isProjectionSystem(value?: string | null): value is keyof typeof ProjectionSystems {
  if (!value) return false;
  return value in ProjectionSystems;
}

const ExportOrthophotoModal_: React.FC2<OrthophotoModalProps> = ({ project }) => {
  const {
    onClose: closeOrthophotoModal,
    onOpenWithValue: openOrthophotoModalWithValue,
    value: screenshotParameters,
    open: orthophotoModalOpen,
  } = useOpen();
  const [loading, setLoading] = useState(false);
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const validationTranslations = useValidationTranslations();

  const onOrthoScreenshot = useCallback(
    async ({ value }: { value?: ScreenshotParameters }) => {
      openOrthophotoModalWithValue(value);
    },
    [openOrthophotoModalWithValue],
  );

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_ortho_screenshot', onOrthoScreenshot);
    return () => {
      viewer.scene.removeEventListener('pointorama_ortho_screenshot', onOrthoScreenshot);
    };
  }, [onOrthoScreenshot, viewer]);

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      closeOrthophotoModal();
      // Because of transition
      setTimeout(() => {
        resetForm();
        setLoading(false);
      }, 250);
    },
    [closeOrthophotoModal],
  );

  const onCancel = useCallback(() => {
    closeOrthophotoModal();
  }, [closeOrthophotoModal]);

  const exportTiff = useCallback(
    async (includeObjects: boolean, parameters: ScreenshotParameters) => {
      if (!viewer) return;
      const { origin, width, height } = parameters;

      const data = viewer.screenshotTool.renderScreenshot(origin, width, height, includeObjects);
      const projectionSystem = project.settings?.projectionSystem;
      const isValidProjectionSystem = isProjectionSystem(projectionSystem);
      const epsgCode = isValidProjectionSystem ? ProjectionSystems[projectionSystem].epsgCode : 32767;

      const tiffData = await writeArrayBuffer(data.buffer, {
        ImageWidth: data.pixelWidth,
        ImageLength: data.pixelHeight,
        BitsPerSample: [8, 8, 8, 8],
        Compression: 1,
        PhotometricInterpretation: 2, // RGB(A)
        SamplesPerPixel: 4, // For RGBA
        PlanarConfiguration: 1,
        ModelPixelScale: [data.worldWidth / data.pixelWidth, data.worldHeight / data.pixelHeight, 1],
        ModelTiepoint: [0, 0, 0, data.position.x, data.position.y, 0],
        GeographicTypeGeoKey: epsgCode,
      });

      const blob = new Blob([tiffData], { type: 'image/tiff' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = project.name || 'screenshot';
      link.click();
    },
    [viewer, project.name, project.settings?.projectionSystem],
  );

  const getImage = useCallback(
    (includeObjects: boolean, parameters: ScreenshotParameters) => {
      if (!viewer) return;
      const { origin, width, height } = parameters;

      const data = viewer.screenshotTool.renderScreenshot(origin, width, height, includeObjects);

      const canvas = document.createElement('canvas');
      canvas.width = data.pixelWidth;
      canvas.height = data.pixelHeight;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const imageData = new ImageData(new Uint8ClampedArray(data.buffer), data.pixelWidth, data.pixelHeight);

      ctx.putImageData(imageData, 0, 0);

      return canvas;
    },
    [viewer],
  );

  const exportPng = useCallback(
    async (includeObjects: boolean, parameters: ScreenshotParameters) => {
      const canvas = getImage(includeObjects, parameters);

      canvas?.toBlob((blob) => {
        if (!blob) return;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = project.name || 'screenshot';
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/png');
    },
    [project.name, getImage],
  );

  const exportJpg = useCallback(
    async (includeObjects: boolean, parameters: ScreenshotParameters) => {
      const canvas = getImage(includeObjects, parameters);

      canvas?.toBlob((blob) => {
        if (!blob) return;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${project.name}.jpg`;
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/jpg');
    },
    [getImage, project.name],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (options, helpers) => {
      if (!viewer) return;
      setLoading(true);

      try {
        if (options.fileType === ImageExportType.TIFF) {
          exportTiff(options.includeObjects, screenshotParameters);
        } else if (options.fileType === ImageExportType.PNG) {
          await exportPng(options.includeObjects, screenshotParameters);
        } else if (options.fileType === ImageExportType.JPG) {
          await exportJpg(options.includeObjects, screenshotParameters);
        }
        onSuccess({ helpers });
      } catch (error) {
        toast.error(validationTranslations.somethingWentWrong);
      } finally {
        setLoading(false);
      }
    },
    [
      viewer,
      onSuccess,
      exportTiff,
      screenshotParameters,
      exportPng,
      exportJpg,
      validationTranslations.somethingWentWrong,
    ],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({ initialValues: { includeObjects: false, fileType: ImageExportType.TIFF }, onSubmit }),
    [onSubmit],
  );

  return (
    <CreateModal
      title={<T _str="Export orthophoto" swc />}
      createButtonTitle={<T _str="export" swc />}
      formik={formik}
      onCancel={onCancel}
      open={orthophotoModalOpen}
      isSubmitting={loading}
      alwaysCancellable
    >
      <FormikSelect name="fileType" label={<T _str="Save as" />}>
        {exportTypes.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </FormikSelect>
      <FormikCheckBox name="includeObjects" label={<T _str="Include objects" />} />
    </CreateModal>
  );
};

export const ExportOrthophotoModal = memo(ExportOrthophotoModal_);
