import { memo } from 'react';
import { T } from '../../../translation/src';
import { OrthophotoLayer } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { Input } from '../../../components/inputs/Input';
import { useConvertedMeasurementInputProps } from '../../../hooks/useConvertedMeasurementInputProps';
import { useOrthophotoProperties, useOrthophotosLoaded } from '../../../hooks/potree/useOrthophotoProperties';

interface OrthophotoPropertiesProps {
  orthophoto: OrthophotoLayer;
}
const OrthophotoProperties_: React.FC2<OrthophotoPropertiesProps> = ({ orthophoto }) => {
  const convertMeasurementInputProps = useConvertedMeasurementInputProps();
  const [{ height }, { onChangeHeight }] = useOrthophotoProperties();
  const orthophotosLoadedByIdentifier = useOrthophotosLoaded();

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="orthophoto" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="settings" swc />}>
        {height !== undefined && height !== null && orthophotosLoadedByIdentifier[orthophoto.identifier]?.isLoaded && (
          <Property title={<T _str="height" swc />} className="mb-2">
            <div className="flex items-center w-full">
              <Input
                key={orthophoto.identifier}
                id="inputHeightMin"
                className="w-full mr-2"
                placeholder={'height'}
                onChange={onChangeHeight}
                {...convertMeasurementInputProps({ defaultValue: height })}
              />
            </div>
          </Property>
        )}
      </PropertiesSection>
    </div>
  );
};
export const OrthophotoProperties = memo(OrthophotoProperties_);
