import { ClipboardIcon, EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { memo, useCallback } from 'react';
import { Popover, PopoverListItem } from '../../components/Popover';
import useOpen from '../../hooks/useOpen';
import { T, useT } from '../../translation/src';
import { useApiKeysQuery, ApiKeysQueryResult, ApiKeyType } from '../../types/graphqlTypes';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { ApiKeyModal } from '../../modules/apiKey/ApiKeyModal';
import { useParams } from 'react-router-dom';
import { useKeyTypeOptions } from '../../hooks/useKeyTypeOptions';
import { maskString } from '../../utils/maskString';
import { useCopyToClipboard } from 'usehooks-ts';
import { toast } from 'react-toastify';
import { PageHeader } from '../../components/page/PageHeader';
import { PageContent } from '../../components/page/PageContent';
import { DeleteApiKeyModal } from '../../modules/apiKey/DeleteApiKeyModal';

const resolveTypenameToKeyType = (typename?: string): ApiKeyType => {
  if (typename === 'DefaultApiKey') return ApiKeyType.Default;
  if (typename === 'ApiKeyWithLicenseKey') return ApiKeyType.WithLicenseKey;
  throw new Error(`Unknown typename: ${typename}`);
};

const useTranslations = () => {
  const name = useT('name', { swc: true });
  const keyType = useT('key type', { swc: true });
  const licenseKey = useT('license key', { swc: true });
  const apiKey = useT('API key', { swc: true });
  const apiKeyCopied = useT('API key copied to clipboard', { swc: true });
  const licenseKeyCopied = useT('license key copied to clipboard', { swc: true });

  return { name, keyType, licenseKey, apiKey, apiKeyCopied, licenseKeyCopied };
};

type ApiKey = NonNullable<ApiKeysQueryResult['data']>['organisation']['apiKeys'][number];
const ApiKeyRow_: React.FC2<{
  apiKey: ApiKey;
}> = ({ apiKey }) => {
  const translations = useTranslations();
  const keyTypeOptions = useKeyTypeOptions();
  const keyTypeTranslation = keyTypeOptions.find(
    (option) => option.value === resolveTypenameToKeyType(apiKey.__typename),
  )?.label;
  const [, copy] = useCopyToClipboard();

  const onCopyApiKey = useCallback(() => {
    copy(apiKey.apiKey).then(() => {
      toast.success(translations.apiKeyCopied);
    });
  }, [apiKey.apiKey, copy, translations.apiKeyCopied]);

  const onCopyLicenseKey = useCallback(() => {
    if (apiKey.__typename !== 'ApiKeyWithLicenseKey') return;
    copy(apiKey.licenseKey).then(() => {
      toast.success(translations.licenseKeyCopied);
    });
  }, [apiKey, copy, translations.licenseKeyCopied]);

  const { onClose: closeApiKeyDeleteModal, onOpen: openApiKeyDeleteModal, open: apiKeyDeleteModalOpen } = useOpen();
  const { xsDevice, mdDevice, lgDevice } = useDeviceSize();

  const apiKeyContent = (
    <>
      {' '}
      {maskString(apiKey.apiKey)} <ClipboardIcon className="w-4 h-4 ml-2 cursor-pointer" onClick={onCopyApiKey} />
    </>
  );

  const apiKeyNameCell = (
    <div className="flex flex-col w-48">
      <div>{translations.name}</div>
      <div className="text-sm text-gray-500 dark:text-gray-300">{apiKey.name}</div>
    </div>
  );

  const apiKeyCell = (
    <div className="flex flex-col flex-grow">
      <div>{translations.apiKey}</div>
      <div className="flex items-center text-sm text-gray-500 dark:text-gray-300">{apiKeyContent}</div>
    </div>
  );

  const licenseKeyCell = (
    <div className="flex flex-col flex-grow">
      <div>{translations.licenseKey}</div>
      <div className="flex items-center text-sm text-gray-500 dark:text-gray-300">
        {apiKey.__typename === 'ApiKeyWithLicenseKey' ? (
          <>
            {maskString(apiKey.licenseKey)}{' '}
            <ClipboardIcon className="w-4 h-4 ml-2 cursor-pointer" onClick={onCopyLicenseKey} />{' '}
          </>
        ) : (
          '-'
        )}
      </div>
    </div>
  );

  const apiKeyTypeCell = (
    <div className="flex flex-col w-32">
      <div className="text-sm text-gray-500 dark:text-gray-300">
        <T _str="API key type" swc />
      </div>
      <div>{keyTypeTranslation}</div>
    </div>
  );

  const actionsCell = (
    <div className="flex flex-row items-center justify-end w-8">
      <Popover
        data-user-actions
        buttonChild={<EllipsisVerticalIcon className="w-4 text-gray-600 dark:text-gray-200" />}
        buttonClassName="px-1 py-1 rounded right-1 top-0 hover:bg-gray-200 dark:hover:bg-gray-600"
        panelClassName={'mt-2 w-64'}
      >
        <PopoverListItem onClick={openApiKeyDeleteModal} icon={TrashIcon}>
          <T _str="delete" swc />
        </PopoverListItem>
      </Popover>
    </div>
  );

  const modals = <DeleteApiKeyModal onClose={closeApiKeyDeleteModal} apiKey={apiKey} open={apiKeyDeleteModalOpen} />;

  if (xsDevice)
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-2 space-x-2 text-xs items-center">
          <div className="flex w-48">{apiKey.name}</div>
          <div className="flex flex-grow">{apiKeyContent}</div>
          {actionsCell}
        </div>
        {modals}
      </>
    );

  if (mdDevice)
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
          {apiKeyNameCell}
          {apiKeyCell}
          {apiKeyTypeCell}
          {actionsCell}
        </div>
        {modals}
      </>
    );

  if (lgDevice) {
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
          {apiKeyNameCell}
          {apiKeyTypeCell}
          {apiKeyCell}
          {licenseKeyCell}
          {actionsCell}
        </div>
        {modals}
      </>
    );
  }
  return (
    <>
      <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
        {apiKeyNameCell}
        {apiKeyCell}
        {licenseKeyCell}
        {apiKeyTypeCell}
        <div className="flex flex-row items-center justify-end w-8">{actionsCell}</div>
      </div>
      {modals}
    </>
  );
};
const ApiKeyRow = memo(ApiKeyRow_);

export const ApiKeys: React.FC = () => {
  const { organisationId = '' } = useParams();
  const { data: apiKeysData } = useApiKeysQuery({ variables: { organisationId } });
  const apiKeys = apiKeysData?.organisation.apiKeys || [];

  const { open: apiKeyModalOpen, onClose: closeApiKeyModal, onOpen: openAddApiKeyModal } = useOpen();

  return (
    <>
      <PageContent>
        <PageHeader
          title={<T _str="API keys" swc />}
          buttonTitle={<T _str="API Key" swc />}
          onClickButton={openAddApiKeyModal}
        />

        <div className="flex flex-col flex-grow space-y-4 overflow-auto">
          {apiKeys.map((apiKey) => (
            <ApiKeyRow key={apiKey.apiKey} apiKey={apiKey} />
          ))}
        </div>
      </PageContent>
      <ApiKeyModal open={apiKeyModalOpen} onClose={closeApiKeyModal} />
    </>
  );
};
