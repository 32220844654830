import { memo, useMemo } from 'react';
import { Popover } from '.';
import { Color } from '../../types/graphqlTypes';

interface ColorGridProps {
  colors: Color[];
  onChange: (color: Color) => void;
  close: () => void;
}

const ColorGrid: React.FC<ColorGridProps> = ({ colors, onChange, close }) => (
  <div className="grid w-46 grid-cols-5 gap-2">
    {colors.map((color) => (
      <div
        key={`${color.r}-${color.g}-${color.b}`}
        className="w-6 h-6 overflow-hidden border border-gray-500 rounded cursor-pointer"
        onClick={() => {
          close();
          onChange(color);
        }}
      >
        <svg width="1.5rem" height="1.5rem">
          <rect width="100%" height="100%" fill={`rgb(${color.r}, ${color.g}, ${color.b})`}></rect>
        </svg>
      </div>
    ))}
  </div>
);
interface ColorPopoverProps {
  value: Color;
  onChange: (value: Color) => void;
}
const ColorPopover_: React.FC2<ColorPopoverProps> = ({ value, onChange }) => {
  const buttonChild = useMemo(() => {
    return (
      <div
        className="w-5 h-5 overflow-hidden border border-gray-500 rounded cursor-pointer"
        style={{ backgroundColor: `rgba(${value.r},${value.g},${value.b},${value.a})` }}
      />
    );
  }, [value]);

  const colors = [
    { r: 50, g: 0, b: 0, a: 1 },
    { r: 100, g: 0, b: 0, a: 1 },
    { r: 150, g: 0, b: 0, a: 1 },
    { r: 200, g: 0, b: 0, a: 1 },
    { r: 250, g: 0, b: 0, a: 1 },
    { r: 250, g: 50, b: 0, a: 1 },
    { r: 250, g: 100, b: 0, a: 1 },
    { r: 250, g: 150, b: 0, a: 1 },
    { r: 250, g: 200, b: 0, a: 1 },
    { r: 250, g: 250, b: 0, a: 1 },
    { r: 200, g: 250, b: 0, a: 1 },
    { r: 150, g: 250, b: 0, a: 1 },
    { r: 100, g: 250, b: 0, a: 1 },
    { r: 50, g: 250, b: 0, a: 1 },
    { r: 0, g: 250, b: 0, a: 1 },
    { r: 0, g: 250, b: 50, a: 1 },
    { r: 0, g: 250, b: 100, a: 1 },
    { r: 0, g: 250, b: 150, a: 1 },
    { r: 0, g: 250, b: 200, a: 1 },
    { r: 0, g: 250, b: 250, a: 1 },
    { r: 0, g: 200, b: 250, a: 1 },
    { r: 0, g: 150, b: 250, a: 1 },
    { r: 0, g: 100, b: 250, a: 1 },
    { r: 0, g: 50, b: 250, a: 1 },
    { r: 0, g: 0, b: 250, a: 1 },
    { r: 0, g: 0, b: 200, a: 1 },
    { r: 0, g: 0, b: 150, a: 1 },
    { r: 0, g: 0, b: 100, a: 1 },
    { r: 0, g: 0, b: 50, a: 1 },
    { r: 0, g: 0, b: 0, a: 1 },
  ];

  const brandColors = [
    { r: 47, g: 89, b: 84, a: 1 },
    { r: 201, g: 217, b: 205, a: 1 },
    { r: 181, g: 255, b: 161, a: 1 },
    { r: 24, g: 24, b: 24, a: 1 },
    { r: 243, g: 241, b: 241, a: 1 },
  ];

  return (
    <Popover buttonChild={buttonChild} panelClassName="w-auto">
      {({ open, close }) => (
        <>
          <ColorGrid colors={colors} onChange={onChange} close={close} />
          <div className="my-4 border-t border-gray-400"></div>
          <ColorGrid colors={brandColors} onChange={onChange} close={close} />
        </>
      )}
    </Popover>
  );
};

export const ColorPopover = memo(ColorPopover_);
