import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

interface InfoContent {
  type: 'warning' | 'warning-red' | 'info';
  title: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.FC<{ className?: string }>;
}

const InfoContent_: React.FC2<InfoContent> = ({
  type,
  className: propsClassName,
  title,
  description,
  icon: IconProp,
}) => {
  const className = useMemo(() => {
    if (type === 'warning-red') return 'text-white bg-error-red-700';
    if (type === 'warning') return 'text-white bg-warning-yellow-700';
    return 'text-white bg-warning-yellow-700';
  }, [type]);

  const icon = useMemo(() => {
    if (IconProp) return <IconProp className="w-8 h-8" />;
    if (type === 'info') return <InformationCircleIcon className="w-8 h-8" />;
    return <ExclamationTriangleIcon className="w-8 h-8" />;
  }, [IconProp, type]);

  return (
    <div className={classNames('flex flex-col p-4  w-full', className, propsClassName)}>
      <div className="flex items-center mb-2 space-x-2">
        {icon}
        <div>{title}</div>
      </div>
      <div className="text-sm font-light">{description}</div>
    </div>
  );
};

export const InfoContent = memo(InfoContent_);
