import { Dispatch, SetStateAction, useEffect } from 'react';
import { keyBy } from 'lodash/fp';
import { InitialCadLayer, CadLayer } from '../../../contexts/CadLayersContext';
import { ProjectByIdQuery } from '../../../types/graphqlTypes';
import { ProjectionSystems } from '../../../utils/constants';
import { isValidProjectionSystem } from '../../../modules/project/ProjectProjectionSystemModal';
import { useDecompressCadLayers } from '../../../pages/projects/helpers/decompressCadLayers';
import { isNotNullOrUndefined } from '../../../utils/isNullOrEmpty';

export const useSyncCadLayers = ({
  projectionSystem,
  initialProjectCadLayers,
  cadLayers,
  setCadLayers,
}: {
  projectionSystem?: string | null | undefined;
  initialProjectCadLayers: ProjectByIdQuery['projectById']['state']['cadLayers'];
  cadLayers: Record<string, CadLayer | InitialCadLayer | null>;
  setCadLayers: Dispatch<SetStateAction<Record<string, CadLayer | InitialCadLayer | null>>>;
}) => {
  const { decompressCadLayers } = useDecompressCadLayers();

  useEffect(() => {
    if (!projectionSystem) return;
    if (!isValidProjectionSystem(projectionSystem)) {
      console.error(`Invalid projection system: ${projectionSystem}`);
      return;
    }
    const projectionSystemDefinition = ProjectionSystems[projectionSystem].parameters;
    if (initialProjectCadLayers) {
      const initialProjectCadLayersById = keyBy('identifier', initialProjectCadLayers);
      const newCadLayers = initialProjectCadLayers
        .slice()
        .filter((layer) => !cadLayers[layer.identifier] && !!layer.fileUrl);
      const removedCadLayerIdentifiers = Object.keys(cadLayers).filter(
        (identifier) => !initialProjectCadLayersById[identifier] && !!cadLayers[identifier],
      );
      const newCadLayersByIdentifier = keyBy(
        'identifier',
        newCadLayers.map((layer) => ({ ...layer, status: 'INIT' as const })),
      );
      const newStateLayers: Record<string, CadLayer | InitialCadLayer | null> = {
        ...Object.entries(cadLayers).reduce((acc, [identifier, layer]) => {
          const newLayerInfo = initialProjectCadLayersById[identifier];
          if (!newLayerInfo) return { ...acc, [identifier]: layer };
          return {
            ...acc,
            [identifier]: {
              ...layer,
              visibleState: newLayerInfo.visibleState,
              name: newLayerInfo.name,
            },
          };
        }, {}),
        ...newCadLayersByIdentifier,
        ...removedCadLayerIdentifiers.reduce((acc, identifier) => ({ ...acc, [identifier]: null }), {}),
      };
      setCadLayers(newStateLayers);
      decompressCadLayers({
        layers: Object.values(newStateLayers).filter(isNotNullOrUndefined),
        projectionSystemDefinition,
        layersToProcess: newCadLayers,
      }).then((layers) => {
        const layersByIdentifier = keyBy(
          'identifier',
          layers.map((layer) => ({ ...layer, status: 'LOADED' as const })),
        );
        setCadLayers((layers) => ({ ...layers, ...layersByIdentifier }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProjectCadLayers, projectionSystem]);
};
