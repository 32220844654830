import { useApolloClient } from '@apollo/client';
import { FormikConfig } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import AccountMultipleIcon from '../../../assets/icons/account-multiple.svg?react';
import { InfoContent } from '../../../components/InfoContent';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikEmailInput } from '../../../components/formik/FormEmailInput';
import { USERS } from '../../../graphql/user';
import { T, useT } from '../../../translation/src';
import {
  Project,
  UserRole,
  UsersDocument,
  useProjectAddGuestMutation,
  useProjectRemoveGuestMutation,
  useUsersQuery,
} from '../../../types/graphqlTypes';

const useTranslations = () => {
  const share = useT('share', { swc: true });
  const send = useT('send', { swc: true });
  const addGuestsByEmail = useT('add guests by email', { swc: true });
  return { share, addGuestsByEmail, send };
};

interface FormValues {
  emails: string[];
}

interface ShareProjectModalProps {
  onClose: () => void;
  open?: boolean;
  project?: Pick<Project, 'id' | 'name'>;
}
const ShareProjectModal_: React.FC2<ShareProjectModalProps> = ({ onClose, open, project }) => {
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const { data: usersData } = useUsersQuery({ variables: { organisationId } });
  const { value: isSubmitting, setValue: setIsSubmitting } = useBoolean(false);
  const [addProjectGuest, { loading: projectGuestLoading }] = useProjectAddGuestMutation();
  const [removeProjectGuest] = useProjectRemoveGuestMutation();
  const apolloClient = useApolloClient();

  const guests = useMemo(
    () =>
      usersData?.usersByOrganisationId.filter(
        (user) => user.role === UserRole.Guest && user.allowedProjectIds.includes(project?.id || ''),
      ) || [],
    [project?.id, usersData?.usersByOrganisationId],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values) => {
      setIsSubmitting(true);
      const { emails } = values;
      await Promise.all(
        emails.map(async (email) => {
          await addProjectGuest({ variables: { projectId: project?.id || '', email } });
        }),
      );
      apolloClient.refetchQueries({ include: [UsersDocument] });
      setIsSubmitting(false);
      onClose();
    },
    [addProjectGuest, apolloClient, onClose, project?.id, setIsSubmitting],
  );

  const onRemoveGuest = useCallback(
    ({ userId }: { userId: string }) => {
      removeProjectGuest({
        variables: { projectId: project?.id || '', userId },
        update: (cache) => {
          cache.writeQuery({
            query: USERS,
            variables: { organisationId },
            data: {
              usersByOrganisationId:
                usersData?.usersByOrganisationId.map((user) => {
                  if (user.id === userId)
                    return { ...user, allowedProjectIds: user.allowedProjectIds.filter((id) => id !== project?.id) };
                  return user;
                }) || [],
            },
          });
        },
      });
    },
    [organisationId, project?.id, removeProjectGuest, usersData?.usersByOrganisationId],
  );

  const formik: FormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: { emails: [] },
      onSubmit,
    };
  }, [onSubmit]);

  if (!project) return null;

  return (
    <CreateModal<FormValues>
      open={open}
      onClose={onClose}
      title={`${translations.share} ${project.name}`}
      formik={formik}
      isSubmitting={isSubmitting}
      createButtonTitle={translations.send}
    >
      <InfoContent
        type="info"
        title={<T _str="All members within this organisation automatically have access." />}
        icon={AccountMultipleIcon}
      />

      {guests.length ? (
        <>
          <div className="mt-5 mb-4 font-bold">
            <T _str="guests with access" swc />
          </div>
          <div className="flex flex-col space-y-3">
            {guests.map((guest) => {
              return (
                <div
                  className="flex items-center justify-between"
                  key={guest.id}
                  onClick={() => onRemoveGuest({ userId: guest.id })}
                >
                  <div className="flex flex-col justify-between">
                    <div className="font-bold">{guest.name}</div>
                    <div className="text-xs text-gray-700">{guest.email}</div>
                  </div>
                  <div className="text-purple-600 underline cursor-pointer">
                    <T _str="remove access" swc />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
      <FormikEmailInput label={translations.addGuestsByEmail} name="emails" className="mt-5" />
    </CreateModal>
  );
};

export const ShareProjectModal = memo(ShareProjectModal_);
