import { MouseEventHandler, memo, useCallback, useContext, useMemo, useState } from 'react';
import LocationIcon from '../../../../assets/icons/location.svg?react';
import VectorLineIcon from '../../../../assets/icons/vector-line.svg?react';
import VectorPolygonIcon from '../../../../assets/icons/vector-polygon.svg?react';
import { CadLayersContextType, isInitialCadLayer, isLoadedCadLayer } from '../../../../contexts/CadLayersContext';
import { useCustomSelector } from '../../../../redux/store';
import { useSelectCadItem } from '../../../../hooks/potree/useSelectCadItem';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  FolderIcon,
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import LayersIcon from '../../../../assets/icons/layers.svg?react';
import useOpen from '../../../../hooks/useOpen';
import { AnnotationsIconsWrapper } from '../Annotations/AnnotationsIconsWrapper';
import { ContextMenu } from '../../../../components/ContextMenu';
import { T } from '../../../../translation/src';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import classNames from 'classnames';
import { ICadObjectGroup, CadObject } from '../../../../utils/CADFileParser';
import { omit } from 'lodash/fp';
import { Button, CircleSpinner } from '../../../../components/Button';
import { UserContext } from '../../../../contexts/UserContext';
import { v4 as uuid } from 'uuid';

const ICONS_BY_TYPE = {
  POINT: LocationIcon,
  LINE: VectorLineIcon,
  POLYGON: VectorPolygonIcon,
};

const CadObjectComponent_: React.FC2<{
  cadObject: CadObject;
  parentSelected: boolean;
  cadLayerIdentifier: string;
  index: number;
  subIndex?: number | null;
  visible: boolean;
}> = ({ cadObject, parentSelected, cadLayerIdentifier, index, subIndex, visible }) => {
  const user = useContext(UserContext);
  const userId = user.id;
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected = selectedCadItems?.some((cadItem) => cadItem.identifier === cadObject.identifier) || parentSelected;
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem({ identifier: cadObject.identifier, type: 'cadObject' }, { isCtrl: false, isShift: false });
  }, [cadObject, onClickCadItem]);
  const [executeAction] = useExecuteAction();

  const onConvertCadObject = useCallback(() => {
    executeAction({
      type: 'CONVERT_CAD_OBJECT',
      action: {
        cadObjects: [
          {
            ...omit('visible', cadObject),
            annotationIdentifier: uuid(),
            identifier: uuid(),
            originalIdentifier: cadObject.identifier,
          },
        ],
      },
    });
    executeAction({
      type: 'HIDE_CAD_OBJECT',
      action: { cadLayerIdentifier, index, subIndex, isGroup: false, userId, visible: false },
    });
  }, [executeAction, cadObject, cadLayerIdentifier, index, subIndex, userId]);

  const onToggleHide = useCallback(() => {
    executeAction({
      type: 'HIDE_CAD_OBJECT',
      action: { cadLayerIdentifier, index, subIndex, isGroup: false, userId, visible: !cadObject.visible },
    });
  }, [cadLayerIdentifier, cadObject.visible, executeAction, index, subIndex, userId]);

  const onDeleteCadObject = useCallback(() => {
    executeAction({ action: { index, cadLayerIdentifier, subIndex }, type: 'DELETE_CAD_OBJECT' });
  }, [cadLayerIdentifier, executeAction, index, subIndex]);

  return (
    <ContextMenu
      buttonChild={
        <SelectItemContent
          onClick={onClick}
          icon={ICONS_BY_TYPE[cadObject.type as keyof typeof ICONS_BY_TYPE]}
          active={selected}
          activeLevel={parentSelected ? 1 : 0}
          selectStyle="primary"
          idle={!visible}
          className={'relative pl-24'}
        >
          <div className="overflow-hidden">{cadObject.name}</div>
          <AnnotationsIconsWrapper
            identifier={cadObject.identifier}
            visible={visible}
            cadObject={cadObject}
            onToggleHide={onToggleHide}
          />
        </SelectItemContent>
      }
      panelClassName="divide-y"
    >
      <div className="flex flex-col">
        <SelectItemContent icon={TrashIcon} onClick={onDeleteCadObject}>
          <T _str="delete" swc />
        </SelectItemContent>
        <SelectItemContent icon={PlusCircleIcon} onClick={onConvertCadObject}>
          <T _str="Convert to object" swc />
        </SelectItemContent>
      </div>
    </ContextMenu>
  );
};
export const CadObjectComponent = memo(CadObjectComponent_);

const CadObjectGroupComponent_: React.FC2<{
  CadObjectGroup: ICadObjectGroup;
  parentSelected: boolean;
  cadLayerIdentifier: string;
  index: number;
  visible?: boolean;
}> = ({ CadObjectGroup: cadObjectGroup, parentSelected, cadLayerIdentifier, index, ...props }) => {
  const user = useContext(UserContext);
  const userId = user.id;
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected =
    selectedCadItems?.some((cadItem) => cadItem.identifier === cadObjectGroup.identifier) || parentSelected;
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem(
      { identifier: cadObjectGroup.identifier, type: 'cadObjectGroup' },
      { isCtrl: false, isShift: false },
    );
  }, [cadObjectGroup, onClickCadItem]);
  const { open: folderOpen, onOpen: onOpenFolder, onClose: onCloseFolder } = useOpen(false);
  const [showMoreIndex, setShowMoreIndex] = useState(1);
  const canShowMore = cadObjectGroup.cadObjects.length > showMoreIndex * 50 && folderOpen;

  const onToggleFolderOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (folderOpen) {
        setShowMoreIndex(1);
        return onCloseFolder();
      }
      return onOpenFolder();
    },
    [folderOpen, onCloseFolder, onOpenFolder],
  );
  const visible = cadObjectGroup.visible;
  const [executeAction] = useExecuteAction();
  const onDeleteCadObjectGroup = useCallback(() => {
    executeAction({ action: { index, cadLayerIdentifier }, type: 'DELETE_CAD_OBJECT_GROUP' });
  }, [executeAction, index, cadLayerIdentifier]);

  const onToggleHide = useCallback(() => {
    executeAction({
      type: 'HIDE_CAD_OBJECT',
      action: { cadLayerIdentifier, index, isGroup: true, userId, visible: !visible },
    });
  }, [cadLayerIdentifier, executeAction, index, userId, visible]);

  return (
    <>
      <ContextMenu
        buttonChild={
          <SelectItemContent
            onClick={onClick}
            icon={FolderIcon}
            active={selected || parentSelected}
            activeLevel={parentSelected ? 1 : 0}
            selectStyle="primary"
            idle={!visible}
            className={'relative pl-16'}
          >
            {
              <div
                className="absolute flex items-center justify-center w-5 h-5 rounded left-9 hover:text-neon-green-400"
                onClick={onToggleFolderOpen}
              >
                {folderOpen ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
              </div>
            }
            <div className="overflow-hidden">{cadObjectGroup.name}</div>
            <AnnotationsIconsWrapper
              identifier={cadObjectGroup.identifier}
              visible={visible}
              cadObjectGroup={cadObjectGroup}
              onToggleHide={onToggleHide}
            />
          </SelectItemContent>
        }
        panelClassName="divide-y"
      >
        <div className="flex flex-col">
          <SelectItemContent icon={TrashIcon} onClick={onDeleteCadObjectGroup}>
            <T _str="delete" swc />
          </SelectItemContent>
        </div>
      </ContextMenu>
      <>
        {folderOpen &&
          cadObjectGroup.cadObjects
            .slice(0, showMoreIndex * 50)
            .map((cadObject, cacObjectIndex) => (
              <CadObjectComponent
                cadObject={cadObject}
                parentSelected={selected}
                key={cadObject.identifier}
                cadLayerIdentifier={cadLayerIdentifier}
                index={index}
                subIndex={cacObjectIndex}
                visible={cadObject.visible}
              />
            ))}
        {canShowMore && (
          <div className="w-40 ml-32">
            <Button
              size="xs"
              variant="secondary"
              className="w-full"
              onClick={() => setShowMoreIndex((prev) => prev + 1)}
            >
              <T _str="Show more" swc />
            </Button>
          </div>
        )}
      </>
    </>
  );
};
export const CadObjectGroupComponent = memo(CadObjectGroupComponent_);

const CadLayerComponent_: React.FC2<{ cadLayer: CadLayersContextType['cadLayers'][number] }> = ({ cadLayer }) => {
  const user = useContext(UserContext);
  const userId = user.id;
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);
  const selected = useMemo(
    () => selectedCadItems?.some((cadItem) => cadItem.identifier === cadLayer.identifier) || false,
    [selectedCadItems, cadLayer.identifier],
  );
  const { onClickCadItem } = useSelectCadItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickCadItem({ identifier: cadLayer.identifier, type: 'cadLayer' }, { isCtrl: false, isShift: false });
  }, [cadLayer, onClickCadItem]);
  const { open: folderOpen, onOpen: onOpenFolder, onClose: onCloseFolder } = useOpen(false);
  const onToggleFolderOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (folderOpen) return onCloseFolder();
      return onOpenFolder();
    },
    [folderOpen, onCloseFolder, onOpenFolder],
  );
  const visible = isInitialCadLayer(cadLayer) ? false : cadLayer.visible;
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: cadLayer.name,
    cadLayerIdentifier: cadLayer.identifier,
  });
  const [executeAction] = useExecuteAction();
  const onDeleteCadLayer = useCallback(() => {
    executeAction({ action: { cadLayerIdentifier: cadLayer.identifier }, type: 'DELETE_CAD_LAYER' });
  }, [executeAction, cadLayer.identifier]);

  const onToggleHide = useCallback(() => {
    executeAction({
      type: 'HIDE_CAD_OBJECT',
      action: {
        isLayer: true,
        userId,
        visible: !visible,
        index: -1,
        layerIdentifier: cadLayer.identifier,
        cadLayerIdentifier: cadLayer.identifier,
      },
    });
  }, [cadLayer.identifier, executeAction, userId, visible]);

  return (
    <>
      {nameEditable ? (
        nameInputComponent
      ) : (
        <ContextMenu
          buttonChild={
            <SelectItemContent
              onClick={onClick}
              icon={LayersIcon}
              active={selected}
              activeLevel={0}
              selectStyle="primary"
              idle={!visible}
              className={'relative pl-8'}
              onDoubleClick={onToggleNameEditable}
            >
              {
                <div
                  className="absolute flex items-center justify-center w-5 h-5 rounded left-1 hover:text-neon-green-400"
                  onClick={onToggleFolderOpen}
                >
                  {folderOpen ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
                </div>
              }
              <div className="overflow-hidden">{cadLayer.name}</div>
              {isLoadedCadLayer(cadLayer) ? (
                <AnnotationsIconsWrapper
                  identifier={cadLayer.identifier}
                  visible={visible}
                  cadLayer={cadLayer}
                  onToggleHide={onToggleHide}
                />
              ) : (
                <div className="flex items-center justify-center w-6 h-6 ml-auto" title="Progress">
                  <CircleSpinner className="w-4 h-4 text-neon-green-300" />
                </div>
              )}
            </SelectItemContent>
          }
          panelClassName="divide-y"
        >
          <div className="flex flex-col">
            <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
              <T _str="rename" swc />
            </SelectItemContent>
          </div>
          <div className="flex flex-col">
            <SelectItemContent icon={TrashIcon} onClick={onDeleteCadLayer}>
              <T _str="delete" swc />
            </SelectItemContent>
          </div>
        </ContextMenu>
      )}
      {folderOpen &&
        !isInitialCadLayer(cadLayer) &&
        cadLayer.cadObjectGroups?.map((cadObjectGroup, index) => (
          <CadObjectGroupComponent
            index={index}
            cadLayerIdentifier={cadLayer.identifier}
            CadObjectGroup={cadObjectGroup}
            parentSelected={selected}
            key={cadObjectGroup.identifier}
            visible={!visible ? false : cadObjectGroup.visible}
          />
        ))}
    </>
  );
};
export const CadLayerComponent = memo(CadLayerComponent_);

interface CadLayersProps {
  className?: string;
  cadLayers: CadLayersContextType['cadLayers'];
}

const CadLayers_: React.FC2<CadLayersProps> = ({ className, cadLayers }) => {
  return (
    <div className={classNames(className, 'py-2')}>
      {cadLayers?.map((cadLayer) => {
        return <CadLayerComponent cadLayer={cadLayer} key={cadLayer.identifier} />;
      })}
    </div>
  );
};
export const CadLayers = memo(CadLayers_);
