import Logo from '../../assets/logo-same-color.svg?react';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikInput } from '../../components/formik/FormInput';
import useQueryParams from '../../hooks/useQueryParams';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().trim().required(translations.isRequired),
        confirmPassword: yup
          .string()
          .required(translations.isRequired)
          .oneOf([yup.ref('password')], translations.passwordsMustMatch),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  password: string;
  confirmPassword: string;
}

const useTranslations = () => ({
  password: useT('password', { swc: true }),
  confirmPassword: useT('confirm password', { swc: true }),
});
const ResetPassword_: React.FC2 = () => {
  const translations = useTranslations();
  const { token = '' } = useQueryParams(['token']);
  const validationTranslations = useValidationTranslations();
  const navigate = useNavigate();
  const validationSchema = useValidationSchema();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);
        const response = await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/reset-password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ password: values.password, token }),
          credentials: 'include',
        });
        if (response.status === 200) return navigate('/');
        toast.error(validationTranslations.somethingWentWrong);
      } catch (e) {
        toast.error(validationTranslations.somethingWentWrong);
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate, validationTranslations.somethingWentWrong, token],
  );

  const initialValues = useMemo(() => ({ password: '', confirmPassword: '' }), []);

  return (
    <div className="flex items-center justify-center w-full min-h-full px-4 py-12 sm:px-6 lg:px-8 dark:bg-[#313131] dark:text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <Logo className="w-auto mx-auto" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900 dark:text-gray-200">
            <T _str="Reset your password" />
          </h2>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {/* @ts-ignore */}
          <Form className="mt-8">
            <FormikInput name="password" type="password" label={translations.password} />
            <FormikInput name="confirmPassword" type="password" label={translations.confirmPassword} />
            <div>
              <Button
                variant="primary"
                type="submit"
                className="items-center justify-center w-full"
                loading={isSubmitting}
              >
                <T _str="reset password" />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export const ResetPassword = memo(ResetPassword_);
