// @ts-nocheck
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Children, PropsWithChildren, ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';

interface OptionProps<Value extends string | number | any[] = any> {
  value: Value;
  className?: string;
  label: string;
}

const Option_: React.FC2<OptionProps> = ({ value, className, children }) => {
  return (
    <ComboboxOption
      className={({ focus }) =>
        classNames(
          `relative select-none py-2 pl-10 pr-4 cursor-pointer  ${
            focus
              ? 'group bg-neon-green-300 dark:bg-eerie-black dark:text-white'
              : 'bg-white text-gray-900 dark:bg-[#313131] dark:text-white'
          }`,
          className,
        )
      }
      value={value}
    >
      {({ selected }) => (
        <>
          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{children}</span>
          {selected ? (
            <span
              className={classNames(
                'text-neon-green-700 dark:text-neon-green-300',
                'absolute inset-y-0 left-0 flex items-center pl-3',
              )}
            >
              <CheckIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </ComboboxOption>
  );
};
export const Option = memo(Option_);

export const Divider: React.FC2 = () => {
  return (
    <Label>
      <div className="border-t-2 border-black dark:border-white" />
    </Label>
  );
};

export interface ComboBoxProps<Value extends string | number | any[] = string> {
  value: Value | undefined;
  name?: string;
  onChange: (event: { target: { value: Value; name: string } }) => void;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  onCustomValue?: (value: string) => void;
}
const ComboBox_ = <Value extends string | number | any | any[] = string>({
  value,
  name,
  onChange: propsOnChange,
  children,
  className,
  error,
  disabled,
  onCustomValue,
}: PropsWithChildren<ComboBoxProps<Value>>): ReactElement<any, any> | null => {
  const [inputValue, setInputValue] = useState('');
  const [useFilter, setUseFilter] = useState(false);

  const getChild = useCallback(
    (value: Value) => {
      return Children.toArray(children).find((child) => child.props.value === value);
    },
    [children],
  );

  useEffect(() => {
    if (value) {
      const selectedChild = getChild(value);
      if (selectedChild?.props.label) {
        setInputValue(selectedChild.props.label);
      }
    }
  }, [value, getChild]);

  const filteredOptions = useMemo(() => {
    if (useFilter && inputValue?.length) {
      return Children.toArray(children).filter((child) => {
        // @ts-ignore
        if (child.props.label) return child.props.label.toLowerCase().includes(inputValue.toLowerCase());
        return undefined;
      });
    } else return children;
  }, [children, useFilter, inputValue]);

  const showOptions: boolean = useMemo(() => {
    return Children.toArray(filteredOptions).length > 0;
  }, [filteredOptions]);

  const onChange = useCallback(
    (selectedValue: Value) => {
      propsOnChange?.({ target: { value: selectedValue, name: name || '' } });
    },
    [name, propsOnChange],
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseFilter(true);
    setInputValue(e.target.value);
  };

  const onClickButton = () => {
    setUseFilter(false);
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const inputValue = event.currentTarget.value;
        if (inputValue.length) {
          const isCustomValue = !Children.toArray(children).some((child) => {
            // @ts-ignore
            return child.props.label && child.props.label === inputValue;
          });
          if (isCustomValue && onCustomValue) {
            onCustomValue(inputValue);
          }
        }
      }
    },
    [children, onCustomValue],
  );

  return (
    <div className={classNames(!className?.includes('w-') && 'w-full', 'text-sm h-10 cursor-pointer', className)}>
      <Combobox value={value} onChange={onChange} disabled={disabled}>
        <div className="relative h-full">
          <div className="w-full">
            <ComboboxInput
              value={inputValue}
              className={classNames(
                'bg-white border border-gray-300 hover:border-gray-400 dark:bg-[#313131] dark:text-white h-full relative w-full py-2 pl-3 pr-8 text-left rounded-md focus:outline-none sm:text-sm',
                !!error && 'border-red-500',
              )}
              onChange={onChangeInput}
              onKeyDown={onKeyDown}
            ></ComboboxInput>
            <ComboboxButton
              onClick={onClickButton}
              className={classNames(
                'h-10 w-10 absolute inset-y-0 right-0 flex items-center pr-2',
                !!error && 'border-red-500',
              )}
            >
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className={classNames('text-neon-green-700 dark:text-neon-green-300', 'w-5 h-5')}
                  aria-hidden="true"
                />
              </span>
            </ComboboxButton>
          </div>
          {showOptions && (
            <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <ComboboxOptions className="bg-white ring-black dark:bg-[#313131] dark:ring-white absolute z-40 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredOptions}
              </ComboboxOptions>
            </Transition>
          )}
        </div>
      </Combobox>
    </div>
  );
};
export const ComboBox = memo(ComboBox_) as typeof ComboBox_;
