import classNames from 'classnames';
import { FormikConfig, FormikHelpers } from 'formik';
import moment from 'moment';
import { memo, useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { CreateModal } from '../../components/Modal/CreateModal';
import { FormikInput } from '../../components/formik/FormInput';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';
import { useSuperadminUpdateTrialDateMutation } from '../../types/graphqlTypes';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        endDate: yup.string().required(translations.isRequired),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  endDate: string;
}

const useTranslations = () => {
  const namePlaceholder = useT('enter a group name', { swc: true });
  return { namePlaceholder };
};

interface UpdateTrialDateModalProps {
  onClose: () => void;
  open?: boolean;
  organisationId?: string;
  initialValues?: { endDate: string };
}
const UpdateTrialDateModal_: React.FC2<UpdateTrialDateModalProps> = ({
  onClose,
  open,
  organisationId,
  initialValues,
}) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const [updateTrial, { loading: updateTrialLoading }] = useSuperadminUpdateTrialDateMutation();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      if (!organisationId) return;
      const date = moment(values.endDate, 'YYYY-MM-DD').endOf('day').toDate();
      await updateTrial({ variables: { endDate: date, organisationId } });
      onSuccess({ helpers });
    },
    [onSuccess, organisationId, updateTrial],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: initialValues || { endDate: new Date().toISOString().split('T')[0] },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema, initialValues],
  );

  return (
    <CreateModal
      title={<T _str="extend trial period" swc />}
      createButtonTitle={<T _str="submit" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={updateTrialLoading}
    >
      <div className={classNames('grid grid-cols-1 gap-6 mb-6')}>
        <FormikInput type="date" autoFocus name="endDate" label={<T _str="end date" swc />} />
      </div>
    </CreateModal>
  );
};

export const UpdateTrialDateModal = memo(UpdateTrialDateModal_);
