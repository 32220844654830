import { useContext } from 'react';
import { RendererContext } from '../../contexts/RendererContext';
import { Measure } from '../../../public/potree/potree/utils/Measure';
import { Annotation } from '../../types/graphqlTypes';
import { useFlatAnnotationList } from './useFlatAnnotationList';

// Could be expanded in the future to support more types of annotations
export enum DxfExportTypes {
  'POINT',
  'DISTANCE',
  'AREA',
}

export const useExportDXF = () => {
  const rendererContext = useContext(RendererContext);

  const { allAnnotations } = useFlatAnnotationList();

  const isExportDXFPossible = (annotations?: Annotation[]): boolean => {
    if (annotations) {
      return annotations.some((annotation) => {
        return annotation.type && annotation.type in DxfExportTypes;
      });
    } else {
      const scene = rendererContext.viewer?.scene;
      if (!scene) return false;
      // if no annotations are specified, check all

      return allAnnotations.some((annotation) => {
        return annotation.type in DxfExportTypes;
      });
    }
  };

  const onExportDXF = (annotations?: Annotation[]) => {
    const scene = rendererContext.viewer?.scene;
    if (!scene) return;

    const getMeasurements = (annotations?: Annotation[]) => {
      let measurements: Measure[] = [];
      if (annotations?.length) {
        annotations.forEach((annotation) => {
          const measurement = scene.measurements.find((measure) => measure.identifier === annotation.identifier);
          if (measurement) measurements.push(measurement);
        });
      } else {
        // if no annotations are specified, export all
        measurements = [...scene.measurements];
      }
      return measurements;
    };

    const measurements = getMeasurements(annotations);
    scene.exportDXF(measurements);
  };

  return [onExportDXF, isExportDXFPossible] as const;
};
