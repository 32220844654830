import { EllipsisHorizontalIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Badge } from '../../../components/Badge';
import { Button } from '../../../components/Button';
import { ListItem } from '../../../components/ListItem';
import { Popover } from '../../../components/Popover';
import { Input, InputProps } from '../../../components/inputs/Input';
import { Option, Select, SelectProps } from '../../../components/inputs/Select';
import { VolumeCalculation } from '../../../contexts/AnnotationContext';
import { ConvertType, useCondFeetToMeter, useFormatNumber } from '../../../hooks/useFormatNumber';
import useOpen from '../../../hooks/useOpen';
import { T, useT } from '../../../translation/src';
import {
  AreaAnnotation,
  CalculationBaseSurfaces,
  GeneralStatus,
  PointCloud,
  useProjectVolumeCalculateMutation,
} from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RendererReadOnlyContext } from '../../../contexts/RendererReadOnlyContext';
import { useCancelCalculation } from '../../../hooks/modules/project/useCancelCalculation';
import { useAppliedFilters, DATA_DEPTH } from '../../../hooks/potree/usePointCloudProperties';
import { useConvertedMeasurementInputProps } from '../../../hooks/useConvertedMeasurementInputProps';
import { CalculationModal, CalculationModalProps } from '../../project/CalculationModal';
import { ProjectDeleteCalculationModal } from '../../project/ProjectDeleteCalculationModal';
import { FiltersInfo } from './FiltersInfo';
import { PropertiesSection, Property } from './PropertiesSection';

const useBaseSurfaceTranslations = (): { [key in CalculationBaseSurfaces]: string } => {
  const polygonPoints = useT('polygon points', { swc: true });
  const customHeight = useT('custom height', { swc: true });
  return { [CalculationBaseSurfaces.Polygon]: polygonPoints, [CalculationBaseSurfaces.CustomHeight]: customHeight };
};

const useTranslations = () => {
  const invalidCalculation = useT(
    'This calculation is not valid anymore due to changes on the polygon object. You should delete this one and recalculate it.',
  );
  const areaCalculation = useT('area calculation', { swc: true });
  return { invalidCalculation, areaCalculation };
};

interface AreaCalculationsProps {
  annotation: AreaAnnotation;
  calculations: VolumeCalculation[];
  otherCalculationsNav?: React.ReactNode;
  project: {
    id: string;
    pointClouds: Pick<PointCloud, 'id' | 'displayName' | 'availableClasses'>[];
  };
}
const AreaCalculations_: React.FC2<AreaCalculationsProps> = ({
  annotation,
  calculations,
  otherCalculationsNav,
  project,
}) => {
  const { projectId = '' } = useParams();
  const { formatNumber } = useFormatNumber();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const [calculateVolume, { loading: calculateVolumeLoading }] = useProjectVolumeCalculateMutation();
  const [baseSurface, setBaseSurface] = useState(CalculationBaseSurfaces.Polygon);
  const baseSurfaceTranslations = useBaseSurfaceTranslations();
  const [height, setHeight] = useState(0);
  const appliedFilters = useAppliedFilters({ project });
  const translations = useTranslations();
  const { condFeetToMeter } = useCondFeetToMeter();
  const convertMeasurementInputProps = useConvertedMeasurementInputProps();
  const {
    onOpenWithValue: openDeleteCalculationModal,
    onClose: closeDeleteCalculationModal,
    open: deleteCalculationModalOpen,
    value: deleteCalculationValue,
  } = useOpen<Pick<VolumeCalculation, 'id'>>();
  const { onOpen: openCalculationModal, onClose: closeCalculationModal, open: calculationModalOpen } = useOpen();

  const onChangeBaseSurface: SelectProps<CalculationBaseSurfaces>['onChange'] = useCallback(
    (event) => setBaseSurface(event.target.value),
    [],
  );

  const onChangeHeight: Required<InputProps>['onChange'] = useCallback(({ target }) => {
    setHeight(Number(target.value));
  }, []);
  const onCalculateVolume = useCallback(() => {
    if (Object.values(appliedFilters).some((value) => value.hasFilters)) return openCalculationModal();
    if (project.pointClouds.length > 1) return openCalculationModal();
    const convertedHeight = condFeetToMeter(height);
    calculateVolume({
      variables: {
        projectId,
        pointCloudId: project.pointClouds[0].id,
        annotationIdentifier: annotation.identifier,
        baseSurface,
        ...(baseSurface === CalculationBaseSurfaces.CustomHeight && { referenceHeight: convertedHeight }),
      },
    });
  }, [
    appliedFilters,
    openCalculationModal,
    project.pointClouds,
    condFeetToMeter,
    height,
    calculateVolume,
    projectId,
    annotation.identifier,
    baseSurface,
  ]);

  const onSubmitCalculation: CalculationModalProps['onSubmit'] = useCallback(
    async (pointClouds) => {
      const pointCloud = pointClouds[0];
      calculateVolume({
        variables: {
          projectId,
          pointCloudId: pointCloud.id,
          baseSurface,
          ...(baseSurface === CalculationBaseSurfaces.CustomHeight && { referenceHeight: height }),
          classificationFilters:
            pointCloud.filters?.classifications && pointCloud.filters?.classifications.length > 0
              ? pointCloud.filters?.classifications.map((cl) => +cl.code)
              : undefined,
          annotationIdentifier: annotation.identifier,
          ...(pointCloud.filters?.heightFilter && {
            heightFilters: [pointCloud.filters?.heightFilter.min, pointCloud.filters?.heightFilter.max],
          }),
          ...(pointCloud.filters?.intensityFilter && {
            intensityFilters: [pointCloud.filters?.intensityFilter.min, pointCloud.filters?.intensityFilter.max],
          }),
          ...(pointCloud.filters?.resolutionFilter && {
            resolutionFilter: pointCloud.filters?.resolutionFilter.resolutionLevel + DATA_DEPTH,
          }),
          ...(pointCloud.filters?.clippingFilter && {
            clippingFilter:
              pointCloud.filters.clippingFilter.nrOfClippingBoxes +
              pointCloud.filters.clippingFilter.nrOfClippingClusters,
          }),
        },
      });
    },
    [annotation.identifier, baseSurface, calculateVolume, height, projectId],
  );

  const calculationsLoading = useMemo(() => {
    return calculations.some((calculation) => calculation.status === GeneralStatus.Progress);
  }, [calculations]);
  const progressCalculation = useMemo(
    () => calculations.find((calculation) => calculation.status === GeneralStatus.Progress),
    [calculations],
  );

  const { onCancelCalculation } = useCancelCalculation({
    projectId,
    calculationIds: progressCalculation ? [progressCalculation.id] : [],
    __typename: 'VolumeCalculation',
  });

  return (
    <>
      <div className="flex flex-col space-y-4 divide-y divide-[##515256]">
        <PropertiesSection title={<T _str="volume" swc />}>
          {!calculations.length && isReadOnly && (
            <div>
              <T _str="No calculations yet" />
            </div>
          )}
          {calculations
            .filter((calculation) => calculation.status === GeneralStatus.Success)
            .map((calculation) => {
              return (
                <div
                  key={calculation.id}
                  className={classNames(
                    'relative flex flex-col p-2 space-y-2 border rounded-sm',
                    calculation.isOutDated ? 'border-orange-500' : 'border-gray-400',
                  )}
                >
                  <Property title={<T _str="base surface" swc />}>
                    <span>{baseSurfaceTranslations[calculation.baseSurface]}</span>{' '}
                    {calculation.baseSurface === CalculationBaseSurfaces.CustomHeight && (
                      <span>({calculation.paramInfo.referenceHeight} m)</span>
                    )}
                  </Property>
                  <Property title={<T _str="filters" swc />}>
                    <FiltersInfo filters={calculation.paramInfo} />
                  </Property>
                  <Property title={<T _str="volume" swc />}>
                    {formatNumber(calculation.result?.volume || 0, { convertType: ConvertType.VOLUME, addUnit: true })}
                  </Property>
                  <Property title={<T _str="volume pos" swc />}>
                    {formatNumber(calculation.result?.volumePositive || 0, {
                      convertType: ConvertType.VOLUME,
                      addUnit: true,
                    })}
                  </Property>
                  <Property title={<T _str="volume neg" swc />}>
                    {formatNumber(calculation.result?.volumeNegative || 0, {
                      convertType: ConvertType.VOLUME,
                      addUnit: true,
                    })}
                  </Property>
                  <Property title={<T _str="area" swc />}>
                    {formatNumber(calculation.result?.area || 0, { convertType: ConvertType.AREA, addUnit: true })}
                  </Property>
                  {!isReadOnly && (
                    <Popover
                      data-project-actions
                      className="absolute top-0 right-0"
                      buttonChild={<EllipsisHorizontalIcon className="w-5 text-white" />}
                      buttonClassName="absolute flex items-center justify-center px-1 rounded right-1 top-0 hover:bg-gray-200"
                      panelClassName="mt-6"
                    >
                      <ListItem onClick={() => openDeleteCalculationModal(calculation)} icon={TrashIcon}>
                        <T _str="delete" swc />
                      </ListItem>
                    </Popover>
                  )}
                  {calculation.isOutDated && (
                    <Badge
                      type="warning"
                      className="absolute cursor-pointer -top-5 -right-3"
                      title={translations.invalidCalculation}
                    />
                  )}
                </div>
              );
            })}
          {!isReadOnly && (
            <div className="flex flex-col">
              <Property title={<T _str="base surface" swc />}>
                <Select<CalculationBaseSurfaces>
                  className="w-full"
                  onChange={onChangeBaseSurface}
                  value={baseSurface || CalculationBaseSurfaces.Polygon}
                  name="visualization"
                >
                  {Object.values(CalculationBaseSurfaces).map((surface) => (
                    <Option key={surface} value={surface}>
                      {baseSurfaceTranslations[surface]}
                    </Option>
                  ))}
                </Select>
              </Property>
              {baseSurface === CalculationBaseSurfaces.CustomHeight && (
                <Property title={<T _str="height" swc />} className="mt-4">
                  <Input onChange={onChangeHeight} {...convertMeasurementInputProps({ defaultValue: height })} />
                </Property>
              )}
              <div className="flex items-center justify-center w-full mt-4">
                <Button
                  variant="secondary"
                  onClick={onCalculateVolume}
                  className="w-full"
                  size="sm"
                  loading={calculationsLoading || calculateVolumeLoading}
                  loadingTitle={<T _str="calculating" swc />}
                  onCancel={onCancelCalculation}
                >
                  <T _str="calculate volume" swc />
                </Button>
              </div>
              <div className="mt-3 text-xs italic text-white">
                <T _str="This calculation can take some time. You will get a notification when your calculation is ready." />
              </div>
            </div>
          )}
        </PropertiesSection>
        {otherCalculationsNav}
      </div>
      <ProjectDeleteCalculationModal
        onClose={closeDeleteCalculationModal}
        calculations={deleteCalculationValue ? [deleteCalculationValue] : undefined}
        open={deleteCalculationModalOpen}
      />
      <CalculationModal
        pointClouds={project.pointClouds}
        onClose={closeCalculationModal}
        open={calculationModalOpen}
        title={translations.areaCalculation}
        onSubmit={onSubmitCalculation}
      />
    </>
  );
};

export const AreaCalculations = memo(AreaCalculations_);
