import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikInput } from '../../../components/formik/FormInput';
import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { useSectorOptions } from '../../../pages/authentication/Register';
import { T, useT } from '../../../translation/src';
import { OrganisationCreateMutation, useOrganisationCreateMutation } from '../../../types/graphqlTypes';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(translations.isRequired),
        sector: yup.string().required(translations.isRequired),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  name: string;
  sector: string;
}

const useTranslations = () => {
  const name = useT('name', { swc: true });
  const industry = useT('industry', { swc: true });
  return { name, industry };
};
export interface AddOrganisationModalProps {
  onClose: (args?: { isSuccess: boolean; data?: OrganisationCreateMutation['organisationCreate'] }) => void;
  open?: boolean;
}
const AddOrganisationModal_: React.FC2<AddOrganisationModalProps> = ({ onClose, open }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const [addOrganisation, { loading: addOrganisationLoading }] = useOrganisationCreateMutation();
  const sectorOptions = useSectorOptions();

  const onSuccess = useCallback(
    ({
      helpers: { resetForm },
      data,
    }: {
      helpers: FormikHelpers<FormValues>;
      data?: OrganisationCreateMutation['organisationCreate'];
    }) => {
      onClose({ isSuccess: true, data });
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      const { data } = await addOrganisation({
        variables: { name: values.name, sector: values.sector },
        refetchQueries: ['organisations', 'currentUser'],
        awaitRefetchQueries: true,
      });

      onSuccess({ helpers, data: data?.organisationCreate });
    },
    [addOrganisation, onSuccess],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { name: '', sector: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={<T _str="add organisation" swc />}
      createButtonTitle={<T _str="create" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={addOrganisationLoading}
      noOverflow
    >
      <FormikInput name="name" label={translations.name} placeholder={translations.name} />
      <FormikSelect name="sector" label={translations.industry} placeholder={translations.industry}>
        {sectorOptions.options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </FormikSelect>
    </CreateModal>
  );
};

export const AddOrganisationModal = memo(AddOrganisationModal_);
