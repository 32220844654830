import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import { IReactMultiEmailProps, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { InputProps } from '../../inputs/Input';
import { Label } from '../Label';

interface FormEmailInputProps extends InputProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}

const FormInput_: React.FC2<Omit<FormEmailInputProps, 'onFocus' | 'onBlur'>> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  value,
  onChange: propsOnChange,
  ...props
}) => {
  const onChange: Required<IReactMultiEmailProps>['onChange'] = useCallback(
    (emails) => {
      // @ts-ignore
      propsOnChange?.({ target: { value: emails, name: name || '' } });
    },
    [propsOnChange, name],
  );

  const getLabel: Required<IReactMultiEmailProps>['getLabel'] = useCallback((email, index, removeEmail) => {
    return (
      <div data-tag key={index}>
        <div data-tag-item>{email}</div>
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
        </span>
      </div>
    );
  }, []);

  return (
    <label className={classNames('block relative text-black dark:text-white', propsClassName)}>
      <Label>{label}</Label>
      <ReactMultiEmail
        id={name}
        emails={value as string[]}
        onChange={onChange}
        className={classNames(inputClassName, 'multi-email-input', error && 'multi-email-input-error', 'mt-1')}
        getLabel={getLabel}
        {...props}
      />
      <div className="flex items-center h-4 mt-1 text-xs text-error-red-500">{!hideErrorLabel && error}</div>
    </label>
  );
};

export const FormInput = memo(FormInput_) as typeof FormInput_;

interface FormikEmailInputProps extends FormEmailInputProps {
  name: string;
}

const FormikEmailInput_: React.FC2<FormikEmailInputProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return <FormInput error={meta.touched && meta.error ? meta.error : undefined} {...props} {...field} />;
};

export const FormikEmailInput = memo(FormikEmailInput_) as typeof FormikEmailInput_;
