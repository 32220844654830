import classNames from 'classnames';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { memo } from 'react';

const PageContent_: React.FC2 = ({ children }) => {
  const { xsDevice } = useDeviceSize();
  return (
    <div
      className={classNames(
        'flex flex-col w-full h-full dark:bg-[#313131] dark:text-white',
        xsDevice ? 'px-6' : 'px-14',
      )}
    >
      {children}
    </div>
  );
};

export const PageContent = memo(PageContent_);
