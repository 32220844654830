import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import { Select, SelectProps } from '../../inputs/Select';
import { Label } from '../Label';

export interface FormSelectProps extends Omit<SelectProps, 'error'> {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}

const FormSelect_: React.FC2<FormSelectProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  children,
  ...props
}) => {
  return (
    <div className={classNames('block relative', propsClassName)}>
      <Label>{label}</Label>
      <Select name={name} className={classNames(inputClassName, 'mt-1')} {...props} error={!!error}>
        {children}
      </Select>
      <div className="flex items-center h-4 mt-1 text-xs text-red-500">{!hideErrorLabel && error}</div>
    </div>
  );
};

export const FormSelect = memo(FormSelect_) as typeof FormSelect_;

interface FormikSelectProps extends FormSelectProps {
  name: string;
}

const FormikSelect_: React.FC2<
  Omit<FormikSelectProps, 'value' | 'onChange'> & { onChange?: FormikSelectProps['onChange'] }
> = ({ name, onChange: customOnChange, ...props }) => {
  const [{ onChange: fieldOnChange, ...field }, meta] = useField(name);

  const onChange: FormikSelectProps['onChange'] = useCallback(
    (e) => {
      fieldOnChange(e);
      customOnChange?.(e);
    },
    [fieldOnChange, customOnChange],
  );

  return (
    <FormSelect error={meta.touched && meta.error ? meta.error : undefined} {...props} {...field} onChange={onChange} />
  );
};

export const FormikSelect = memo(FormikSelect_) as typeof FormikSelect_;
