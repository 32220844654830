import { DragDropContext, DragDropContextProps, Droppable } from '@hello-pangea/dnd';
import { keyBy, orderBy, uniqBy } from 'lodash/fp';
import { MouseEventHandler, memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RendererReadOnlyContext } from '../../../../contexts/RendererReadOnlyContext';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { useAnnotations } from '../../../../hooks/potree/useRenderer';
import { useSelectAnnotation } from '../../../../hooks/potree/useSelectAnnotation';
import { unSelectAll } from '../../../../redux/rendererReducer';
import { useCustomSelector } from '../../../../redux/store';
import type { PointAnnotation } from '../../../../types/graphqlTypes';
import { Annotation } from './Annotation';
import { AnnotationGroup } from './AnnotationGroup';
import { getConvertedIndices } from './utils/getConvertedIndices';

const Annotations_: React.FC2<{ defaultSelectedAnnotationId?: string }> = ({ defaultSelectedAnnotationId }) => {
  const [{ indexedAnnotations }] = useAnnotations();
  const closedGroupItems = useRef<HTMLElement[]>([]);
  const hiddenAnnotationItems = useRef<HTMLElement[]>([]);
  const isReadOnly = useContext(RendererReadOnlyContext);
  const selectedDefaultAnnotation = useRef(false);
  const { selectedAnnotations } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedAnnotations', 'lastSelectedAnnotation'],
  );
  const selectedIdentifiers = useMemo(
    () => selectedAnnotations.map((annotation) => annotation.identifier),
    [selectedAnnotations],
  );
  const annotationsByIdentifier = useMemo(() => {
    const annotationsFlat = indexedAnnotations.flatMap((annotation) => [
      annotation,
      ...(annotation.__typename === 'AnnotationGroup' ? annotation.annotations : []),
    ]);
    return keyBy('identifier', annotationsFlat);
  }, [indexedAnnotations]);
  const orderedSelectedAnnotations = useMemo(() => {
    const selectedAnnotationsWithIndices = selectedAnnotations.map((selectedAnnotation) => ({
      ...selectedAnnotation,
      index: annotationsByIdentifier[selectedAnnotation.identifier]?.index,
    }));
    return orderBy('index', 'asc', selectedAnnotationsWithIndices);
  }, [annotationsByIdentifier, selectedAnnotations]);
  const [executeAction] = useExecuteAction();
  const dispatch = useDispatch();

  const onClickContainer: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.target !== event.currentTarget) return;
      dispatch(unSelectAll({}));
    },
    [dispatch],
  );

  const { onClickAnnotation } = useSelectAnnotation();

  useEffect(() => {
    if (defaultSelectedAnnotationId) {
      const annotation = annotationsByIdentifier[defaultSelectedAnnotationId];
      // Only select the annotation the first time on Render. Otherwise, it will reselect the identifier over and over again when creating new annotations, ...
      if (!annotation || selectedDefaultAnnotation.current) return;
      if (annotation.__typename === 'AnnotationGroup')
        onClickAnnotation({ identifier: annotation.identifier, type: 'group' }, {});
      else
        onClickAnnotation(
          {
            identifier: annotation.identifier,
            type: 'annotation',
            groupIdentifier: (annotation as PointAnnotation).groupIdentifier || undefined,
          },
          {},
        );
      selectedDefaultAnnotation.current = true;
    }
  }, [defaultSelectedAnnotationId, annotationsByIdentifier, onClickAnnotation]);

  const onDragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    (result) => {
      closedGroupItems.current.forEach((el) => el.classList.remove('hidden'));
      hiddenAnnotationItems.current.forEach((el) => el.classList.remove('hidden'));
      const [type, identifier] = result.draggableId.split('_');
      try {
        const isMultiSelectDrag = orderedSelectedAnnotations.some((annotation) => annotation.identifier === identifier);
        let identifiers = [{ type, identifier }];
        if (isMultiSelectDrag) {
          const orderedIdentifiers = orderBy(
            'index',
            (result.destination?.index || 0) < result.source.index ? 'desc' : 'asc',
            [{ type, identifier, index: annotationsByIdentifier[identifier]?.index }, ...orderedSelectedAnnotations],
          );
          identifiers = uniqBy(
            'identifier',
            orderedIdentifiers.map((annotation) => ({ type: annotation.type, identifier: annotation.identifier })),
          );
        }
        const { toIndex, toGroupIdentifier } = getConvertedIndices({
          result,
          annotations: indexedAnnotations,
          selectedIdentifiers: identifiers,
        });
        executeAction({
          type: 'UPDATE_POSITION',
          action: {
            toIndex,
            identifiers,
            toGroupIdentifier,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (e) {
        if ((e as any)?.message !== 'NOT_SUPPORTED') throw e;
      }
    },
    [indexedAnnotations, annotationsByIdentifier, executeAction, orderedSelectedAnnotations],
  );

  const onCloseGroup = useCallback(({ groupId }: { groupId: string }) => {
    const elements = document.querySelectorAll(`[data-annotation-group-id="${groupId}"]`) as any as HTMLElement[];
    elements.forEach((el) => el.classList.add('hidden'));
    closedGroupItems.current = closedGroupItems.current.concat(...elements);
  }, []);
  const onHideGroup = useCallback(({ groupId }: { groupId: string }) => {
    const elements = document.querySelectorAll(`[data-group-id="${groupId}"]`) as any as HTMLElement[];
    elements.forEach((el) => el.classList.add('hidden'));
    hiddenAnnotationItems.current = hiddenAnnotationItems.current.concat(...elements);
  }, []);
  const onHideAnnotation = useCallback(({ annotationId }: { annotationId: string }) => {
    const elements = document.querySelectorAll(`[data-annotation-id="${annotationId}"]`) as any as HTMLElement[];
    elements.forEach((el) => el.classList.add('hidden'));
    hiddenAnnotationItems.current = hiddenAnnotationItems.current.concat(...elements);
  }, []);

  const onBeforeCaptureDrag: NonNullable<DragDropContextProps['onBeforeCapture']> = useCallback(
    (drag) => {
      const [type, identifier] = drag.draggableId.split('_');
      console.log('identifier', identifier, orderedSelectedAnnotations);
      if (type === 'group') onCloseGroup({ groupId: identifier });
      // Case: when an annotation is selected, but you want to drag another random annotation.
      const isMultiSelectDrag = orderedSelectedAnnotations.some((annotation) => annotation.identifier === identifier);
      if (!isMultiSelectDrag) return;
      orderedSelectedAnnotations.forEach((annotation) => {
        if (annotation.identifier === identifier) return;
        if (annotation.type === 'group') {
          onCloseGroup({ groupId: annotation.identifier });
          onHideGroup({ groupId: annotation.identifier });
        }
        onHideAnnotation({ annotationId: annotation.identifier });
      });
    },
    [onCloseGroup, onHideAnnotation, orderedSelectedAnnotations, onHideGroup],
  );

  return (
    <div className="flex flex-col flex-grow w-full h-full mt-8 space-y-1 overflow-y-auto" onClick={onClickContainer}>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCaptureDrag}>
        <Droppable droppableId="root">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="h-full overflow-y-auto">
              {indexedAnnotations.map((annotation, index) => {
                if (annotation.__typename !== 'AnnotationGroup')
                  return (
                    <Annotation
                      isReadOnly={isReadOnly}
                      type={annotation.__typename || 'PointAnnotation'}
                      active={selectedIdentifiers.includes(annotation.identifier)}
                      annotation={annotation}
                      key={annotation.identifier}
                      isFirst={index === 0}
                      isLast={index === indexedAnnotations.length - 1}
                      onClickAnnotation={onClickAnnotation}
                      rootIndex={index}
                    />
                  );
                return (
                  <AnnotationGroup
                    isReadOnly={isReadOnly}
                    group={annotation}
                    active={selectedIdentifiers.includes(annotation.identifier)}
                    key={annotation.identifier}
                    selectedAnnotations={selectedAnnotations}
                    isFirst={index === 0}
                    isLast={index === indexedAnnotations.length - 1}
                    onClickAnnotation={onClickAnnotation}
                    index={annotation.index}
                    rootIndex={index}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const Annotations = memo(Annotations_);
