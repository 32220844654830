import { FunctionComponent, memo, SVGProps } from 'react';
import { HeroIcon } from '../../types';
import classNames from 'classnames';

interface IconBoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon?:
    | HeroIcon
    | FunctionComponent<
        SVGProps<SVGSVGElement> & {
          title?: string;
        }
      >;
  text?: string;
  highlight?: boolean;
  grayedOut?: boolean;
}
const IconBox_: React.FC2<IconBoxProps> = ({ icon: Icon, text, highlight, grayedOut, ...props }) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center cursor-pointer w-9 h-9 hover:bg-neon-green-300 dark:hover:bg-[#868994] dark:hover:text-neon-green-300',
        highlight && 'bg-neon-green-300 dark:bg-black dark:text-neon-green-300',
        grayedOut && 'text-[#6F7173] hover:text-black',
      )}
      {...props}
    >
      {Icon && <Icon className="w-4 h-4" />}
      {text && <div>{text}</div>}
    </div>
  );
};
export const IconBox = memo(IconBox_);
