import classNames from 'classnames';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { PropsWithChildren, ReactElement, memo } from 'react';
import { Button } from '../../Button';
// import { Button } from '../../Button';
import { T } from '../../../translation/src';
import { Modal } from '../../Modal';

interface CreateModalProps<FormValues extends FormikValues = FormikValues> {
  onClose?: () => void;
  onCancel?: () => void;
  open?: boolean;
  title: React.ReactNode;
  formik: FormikConfig<FormValues>;
  createButtonTitle?: React.ReactNode;
  cancelButtonTitle?: React.ReactNode;
  hideSubmitButton?: React.ReactNode;
  modalBodyClassName?: string;
  modalContainerClassName?: string;
  isSubmitting?: boolean;
  formClassName?: string;
  noOverflow?: boolean;
  onlyCloseOnButtonClick?: boolean;
  alwaysCancellable?: boolean;
}

const CreateModal_ = <FormValues extends FormikValues = FormikValues>({
  onClose,
  onCancel,
  open,
  title,
  createButtonTitle,
  cancelButtonTitle,
  formik,
  modalBodyClassName,
  modalContainerClassName,
  formClassName,
  children,
  isSubmitting,
  noOverflow,
  hideSubmitButton,
  onlyCloseOnButtonClick,
  alwaysCancellable,
}: PropsWithChildren<CreateModalProps<FormValues>>): ReactElement<any, any> | null => {
  return (
    <Modal.Container
      onClose={onlyCloseOnButtonClick ? undefined : onClose}
      open={open}
      className={modalContainerClassName}
      noOverflow={noOverflow}
    >
      <Formik<FormValues> {...formik}>
        <Form className={classNames('flex flex-col w-full h-full', formClassName)}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Body className={modalBodyClassName}>{children}</Modal.Body>
          <Modal.Footer className="space-x-4">
            {!hideSubmitButton && (
              <Button variant="primary" type="submit" loading={isSubmitting}>
                {createButtonTitle || <T _str="submit" swc />}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={onCancel ? onCancel : onClose}
              disabled={isSubmitting && !alwaysCancellable}
            >
              {cancelButtonTitle ? cancelButtonTitle : <T _str="cancel" swc />}
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal.Container>
  );
};

export const CreateModal = memo(CreateModal_) as typeof CreateModal_;
