import { Option, Select, SelectProps } from '../../../components/inputs/Select';
import { DATA_DEPTH, usePointCloudProperties } from '../../../hooks/potree/usePointCloudProperties';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';
import { T, useT } from '../../../translation/src';
import { memo, useCallback, useMemo } from 'react';
import { Property } from './PropertiesSection';

const useTranslations = () => {
  const allPoints = useT('all points');
  return { allPoints };
};

const ResolutionFilter_: React.FC2 = () => {
  const [{ resolvedPoints, resolutionLevel, depth, scale }, helpers] = usePointCloudProperties();
  const { formatNumber } = useFormatNumber();
  const translations = useTranslations();

  const resolutionLevels = useMemo(() => {
    return Array.from({ length: depth + 1 - DATA_DEPTH }, (_, index) => index).reverse();
  }, [depth]);

  const resolutionValuesInMeter = useMemo(() => {
    return resolutionLevels.map((level) => Math.pow(2, 14 - level) / scale);
  }, [resolutionLevels, scale]);

  const resolutionLabels = useMemo(() => {
    const labels = resolutionValuesInMeter.map((value) =>
      formatNumber(value, { convertType: ConvertType.DISTANCE, addUnit: true }),
    );
    labels[0] += ` (${translations.allPoints})`;
    return labels;
  }, [formatNumber, resolutionValuesInMeter, translations]);

  const currentResolutionLabel = useMemo(() => {
    const currentIndex =
      resolutionLevel !== undefined && resolutionLevel !== Infinity
        ? resolutionLevels.indexOf(resolutionLevel)
        : resolutionLevels.indexOf(depth - DATA_DEPTH);
    return resolutionLabels[currentIndex];
  }, [resolutionLevel, resolutionLevels, resolutionLabels, depth]);

  const onChangeResolution: SelectProps<any>['onChange'] = useCallback(
    (event) => {
      const resolution = event.target.value;
      const index = resolutionLabels.indexOf(resolution);
      if (index === -1) {
        helpers.onChangeResolution({ value: Infinity });
      } else {
        helpers.onChangeResolution({ value: resolutionLevels[index] });
      }
    },
    [helpers, resolutionLabels, resolutionLevels],
  );

  return (
    <Property title={<T _str="resolution" swc />}>
      <div className="flex flex-col w-full">
        <Select<any>
          className="w-full"
          onChange={onChangeResolution}
          value={currentResolutionLabel}
          name="resolution"
          clearable
        >
          {resolutionLabels.map((value) => (
            <Option key={value} value={value}>
              <div className="flex items-center">{value}</div>
            </Option>
          ))}
        </Select>
        {resolvedPoints ? (
          <div className="text-sm text-gray-500 mt-1">
            {<T _str="available points: " swc />}
            {formatNumber(resolvedPoints, { convertType: ConvertType.NONE, addUnit: false })}
          </div>
        ) : undefined}
      </div>
    </Property>
  );
};
export const ResolutionFilter = memo(ResolutionFilter_);
