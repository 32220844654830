import { keyBy } from 'lodash/fp';
import epsg from 'epsg-index/all.json';
import { PointCloudCommandManager } from '@pointorama/pointcloud-commander';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { RendererContextType } from '../../../contexts/RendererContext';
import { getCadObjectsByIdentifier } from '../../../hooks/potree/useRenderer';
import { isNotNullOrUndefined } from '../../../utils/isNotNullOrUndefined';
import { getPotreeFilter } from '../../../pages/projects/helpers/getPotreeFilter';
import * as THREE from 'three';
import { VectorsAreAlmostEqual } from '../../../utils/AlmostEqual';
import { ProjectByIdQuery } from '../../../types/graphqlTypes';
import { CadLayer, InitialCadLayer, isLoadedCadLayer } from '../../../contexts/CadLayersContext';
import { EventInput } from '../../../workers/OrthophotoReaderWorker';
import { EventOutput } from '../../../workers/OrthophotoReaderWorker';
import Worker from '../../../workers/OrthophotoReaderWorker?worker';
import { ProjectionSystems, ProjectionSystemsByEpsgCode } from '../../../utils/constants';

type Project = ProjectByIdQuery['projectById'];

function assertEpsgJson(epsg: object): asserts epsg is Record<number, { proj4: string }> {
  if (!epsg) throw new Error('epsg.json is missing');
  if (typeof epsg !== 'object') throw new Error('epsg.json is not an object');
}

export const useSyncProjectState = ({
  project,
  calculationsByIdentifier,
  cadLayers,
  setCadLayers,
  initializedPointclouds,
  wmsLayersContextValue,
  orthophotoLayersContextValue,
  commander,
  viewer,
}: {
  project?: Project;
  calculationsByIdentifier: Record<string, Project['calculations']>;
  cadLayers: Record<string, CadLayer | InitialCadLayer | null>;
  setCadLayers: Dispatch<SetStateAction<Record<string, CadLayer | InitialCadLayer | null>>>;
  initializedPointclouds: string[];
  wmsLayersContextValue: { wmsLayers: Project['state']['wmsLayers'] };
  orthophotoLayersContextValue: { orthophotoLayers: Project['state']['orthophotoLayers'] };
  commander: PointCloudCommandManager;
  viewer: RendererContextType['viewer'];
}) => {
  const getGeoTiffData = useCallback(async ({ url }: EventInput['data']) => {
    const worker = new Worker();
    const result = await new Promise<EventOutput>((resolve) => {
      worker.onmessage = (event: MessageEvent) => {
        resolve(event.data);
      };
      worker.postMessage({ url });
    });
    worker.terminate();
    return result;
  }, []);

  const transformGeoTiffCoordinates = useCallback(
    (origin: number[], crs: number) => {
      assertEpsgJson(epsg);
      const customCrs = ProjectionSystemsByEpsgCode[crs];
      const sourceCrs = customCrs
        ? ProjectionSystems[customCrs as keyof typeof ProjectionSystems].parameters
        : epsg[crs]?.proj4;
      const targetCrs =
        project?.settings?.projectionSystem &&
        ProjectionSystems[project.settings.projectionSystem as keyof typeof ProjectionSystems].parameters;
      const transformedCoordinates = sourceCrs && targetCrs ? proj4(sourceCrs, targetCrs, origin) : origin;
      return transformedCoordinates;
    },
    [project?.settings?.projectionSystem],
  );

  const syncAnnotations = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentMeasurements = viewer.scene.measurements.filter((measure) => measure.finished);
      const currentMeasurementsByIdentifier = keyBy('identifier', currentMeasurements);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename === 'BoxAnnotation') return;
        const color =
          annotation.annotationColor &&
          new THREE.Color(
            `rgb(${annotation.annotationColor.r},
                ${annotation.annotationColor.g},
                  ${annotation.annotationColor.b})`,
          );
        if (annotation.__typename === 'PointAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          const position = new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z);
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            if (
              position.x === currentMeasurement.points[0].position.x &&
              position.y === currentMeasurement.points[0].position.y &&
              position.z === currentMeasurement.points[0].position.z &&
              color
                ? color.equals(currentMeasurement.color)
                : true
            ) {
              return;
            }
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.PointAnnotation({
            viewer: viewer,
            point: { position },
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          viewer.scene.addMeasurement(measure);
        } else if (annotation.__typename === 'DistanceAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            const pointsAreSame =
              annotation.points.length === currentMeasurement.points.length &&
              annotation.points.every((point, index) => {
                const currentPoint = currentMeasurement.points[index];
                return new THREE.Vector3(point.position.x, point.position.y, point.position.z).equals(
                  currentPoint.position,
                );
              });
            if (pointsAreSame && color ? color.equals(currentMeasurement.color) : true) return;
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.DistanceAnnotation({
            viewer: viewer,
            points: annotation.points.map((annotation) => ({
              position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
            })),
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          viewer.scene.addMeasurement(measure);
        } else if (annotation.__typename === 'AreaAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          const calculatedVolumes = calculationsByIdentifier[annotation.identifier]
            ? calculationsByIdentifier[annotation.identifier]
                .filter((calculation) => !calculation.isOutDated)
                .map((calculation) => {
                  if (calculation.result?.__typename === 'VolumeCalculationResult') {
                    return calculation.result.volume;
                  }
                  return null;
                })
                .filter(isNotNullOrUndefined)
            : [];
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            const pointsAreSame =
              annotation.points.length === currentMeasurement.points.length &&
              annotation.points.every((point, index) => {
                const currentPoint = currentMeasurement.points[index];
                return new THREE.Vector3(point.position.x, point.position.y, point.position.z).equals(
                  currentPoint.position,
                );
              });
            const volumesAreSame =
              currentMeasurement.volumes.length === calculatedVolumes.length &&
              currentMeasurement.volumes.every((volume, index) => volume === calculatedVolumes[index]);
            if (pointsAreSame && color ? color.equals(currentMeasurement.color) : true && volumesAreSame) return;
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.AreaAnnotation({
            viewer: viewer,
            points: annotation.points.map((annotation) => ({
              position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
            })),
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          measure.addVolumeLabels(calculatedVolumes);
          viewer.scene.addMeasurement(measure);
        }
      });
      currentMeasurements.forEach((measurement) => {
        if (measurement instanceof Potree.PointoramaAnnotation && !annotationsByIdentifier[measurement.identifier]) {
          viewer.scene.removeMeasurement(measurement);
        }
      });
    },
    [project?.state.annotations, calculationsByIdentifier],
  );
  const cadLayersArray = useMemo(() => Object.values(cadLayers).filter(isNotNullOrUndefined), [cadLayers]);
  const syncCadObjects = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!cadLayers || !viewer) return;
      const currentMeasurements = viewer.scene.measurements.filter((measure) => measure.finished);
      const currentMeasurementsByIdentifier = keyBy('identifier', currentMeasurements);
      const cadObjectsByIdentifier = getCadObjectsByIdentifier(cadLayersArray);
      cadLayersArray.forEach((layer) => {
        if (layer.status === 'INIT') return;
        layer.cadObjectGroups?.forEach((cadObjectGroup) => {
          cadObjectGroup.cadObjects.forEach((cadObject) => {
            const color = cadObject.color
              ? new THREE.Color(`rgb(${cadObject.color.r}, ${cadObject.color.g}, ${cadObject.color.b})`)
              : new THREE.Color(1, 0, 0);
            if (cadObject.type === 'POINT') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const position = new THREE.Vector3(cadObject.position.x, cadObject.position.y, cadObject.position.z);
              const measure = new Potree.PointCadObject({
                viewer,
                point: { position },
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            } else if (cadObject.type === 'LINE') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const measure = new Potree.DistanceCadObject({
                viewer,
                points: cadObject.points.map((cadObject) => ({
                  position: new THREE.Vector3(cadObject.x, cadObject.y, cadObject.z),
                })),
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            } else if (cadObject.type === 'POLYGON') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const measure = new Potree.AreaCadObject({
                viewer,
                points: cadObject.points.map((cadObject) => ({
                  position: new THREE.Vector3(cadObject.x, cadObject.y, cadObject.z),
                })),
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            }
          });
        });
        const cadLayerState = cadLayers[layer.identifier];

        if (isLoadedCadLayer(cadLayerState) && cadLayerState.status !== 'LOADED') {
          setCadLayers((layers) => ({ ...layers, [layer.identifier]: { ...cadLayerState, status: 'LOADED' } }));
        }
      });
      currentMeasurements.forEach((measurement) => {
        if (measurement instanceof Potree.CadObject && !cadObjectsByIdentifier[measurement.identifier]) {
          viewer.scene.removeMeasurement(measurement);
        }
      });
    },
    [cadLayers, cadLayersArray, setCadLayers],
  );
  const syncBoxes = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentBoxes = viewer.scene.volumes
        .filter((volume) => volume instanceof Potree.BoxVolume)
        .filter((volume) => volume.finished);
      const currentBoxesByIdentifier = keyBy('identifier', currentBoxes);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'BoxAnnotation') return;
        const currentBox = currentBoxesByIdentifier[annotation.identifier];
        const position = new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z);
        const scale = new THREE.Vector3(annotation.scale.x, annotation.scale.y, annotation.scale.z);
        const rotation = new THREE.Vector3(annotation.rotation.x, annotation.rotation.y, annotation.rotation.z);
        const color =
          annotation.annotationColor &&
          new THREE.Color(`rgb(
                  ${annotation.annotationColor.r},
                  ${annotation.annotationColor.g},
                  ${annotation.annotationColor.b})`);
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);
        if (currentBox) {
          if (
            position.x === currentBox.position.x &&
            position.y === currentBox.position.y &&
            position.z === currentBox.position.z &&
            scale.x === currentBox.scale.x &&
            scale.y === currentBox.scale.y &&
            scale.z === currentBox.scale.z &&
            rotation.x === currentBox.rotation.x &&
            rotation.y === currentBox.rotation.y &&
            rotation.z === currentBox.rotation.z &&
            (color ? color.equals(currentBox.color) : true) &&
            annotation.visible === currentBox.visible &&
            filter === currentBox.assignedClipTask
          ) {
            return;
          }
          viewer.scene.removeVolume(currentBox);
          viewer.transformationTool.setTransformable(undefined);
        }
        const box = new Potree.BoxVolume({
          identifier: annotation.identifier,
          clip: true,
          finished: true,
          position,
          scale,
          rotation,
          color,
          visible: annotation.visible,
          selected: currentBox?.selected || false,
        });
        box.setClipTask(filter);
        viewer.volumeTool.scene.add(box);
        viewer.scene.addVolume(box);
      });
      currentBoxes.forEach((box) => {
        if (!annotationsByIdentifier[box.identifier]) {
          viewer.scene.removeVolume(box);
          viewer.transformationTool.setTransformable(undefined);
        }
      });
    },
    [project?.state.annotations],
  );
  const syncPointClusters = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentPointClusters = viewer.scene.pointClusters.filter((pCluster) => pCluster.finished);
      const currentPointclustersByIdentifier = keyBy('identifier', currentPointClusters);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'PointCluster') return;
        const currentPointCluster = currentPointclustersByIdentifier[annotation.identifier];
        const segments = annotation.segments.map((segment) => {
          return { pointcloudId: segment.pointcloudId, segmentId: segment.segmentId };
        });
        const selected = currentPointCluster?.selected || false;
        const sameSegments =
          JSON.stringify(segments.slice().sort()) === JSON.stringify(currentPointCluster?.segments.slice().sort());
        const color =
          annotation.annotationColor &&
          new THREE.Color(`rgb(
                  ${annotation.annotationColor.r},
                  ${annotation.annotationColor.g},
                  ${annotation.annotationColor.b})`);
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);

        if (currentPointCluster) {
          if (
            sameSegments &&
            currentPointCluster.assignedClipTask === filter &&
            currentPointCluster.visible === annotation.visible &&
            currentPointCluster.classification === annotation.annotationClass &&
            (color ? color.equals(currentPointCluster.color) : true)
          )
            return;
          currentPointCluster.segments = segments;
          currentPointCluster.assignedClipTask = filter;
          currentPointCluster.classification = annotation.annotationClass || -1;
          currentPointCluster.visible = annotation.visible;
          if (color) currentPointCluster.color = color;
        } else {
          const pointCluster = new Potree.PointCluster({
            identifier: annotation.identifier,
            segments,
            selected,
            clipTask: filter,
            classification: annotation.annotationClass || -1,
            visible: annotation.visible,
            color,
          });
          viewer.scene.addPointCluster(pointCluster);
          pointCluster.finish();
        }
      });

      currentPointClusters.forEach((pCluster) => {
        if (!annotationsByIdentifier[pCluster.identifier]) {
          viewer.scene.removePointCluster(pCluster);
        }
      });
    },
    [project?.state.annotations],
  );

  const syncPlanes = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentPlanes = viewer.scene.planes.filter((plane) => plane.finished);
      const currentPlanesByIdentifier = keyBy('identifier', currentPlanes);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'Plane') return;
        const currentPlane = currentPlanesByIdentifier[annotation.identifier];
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);
        const tolerance = annotation.annotationFilter?.tolerance;
        if (currentPlane) {
          const position = currentPlane.position;
          const normal = currentPlane.getNormal();

          if (currentPlane) {
            currentPlane.visible = annotation.visible;
            if (
              position.x === annotation.position.x &&
              position.y === annotation.position.y &&
              position.z === annotation.position.z &&
              VectorsAreAlmostEqual(normal, annotation.normal) && // normal is calculated, results might not be exactly the same
              currentPlane.assignedClipTask === filter &&
              currentPlane.tolerance === tolerance
            ) {
              return;
            }
            viewer.scene.removePlane(currentPlane);
          }
        }
        const plane = new Potree.PointoramaPlane({
          identifier: annotation.identifier,
          finished: true,
          position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
          normal: new THREE.Vector3(annotation.normal.x, annotation.normal.y, annotation.normal.z),
          visible: annotation.visible,
          selected: currentPlane?.selected,
          clipTask: filter,
          tolerance: tolerance,
        });

        viewer.planeTool.scene.add(plane);
        viewer.scene.addPlane(plane);
      });

      currentPlanes.forEach((plane) => {
        if (!annotationsByIdentifier[plane.identifier]) {
          viewer.scene.removePlane(plane);
        }
      });
    },
    [project?.state.annotations],
  );

  const syncClassifications = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.customClasses || !viewer) return;

      const currentCustomClasses = viewer.classificationsInfo.classes.filter((c) => c.custom);
      const currentCustomClassesByIdentifier = keyBy('identifier', currentCustomClasses);
      const customClassesByIdentifier = keyBy('id', project.customClasses);

      project.customClasses.forEach((customClass) => {
        const currentClass = currentCustomClassesByIdentifier[customClass.id];
        const color: [number, number, number, number] = [
          customClass.color.r / 255,
          customClass.color.g / 255,
          customClass.color.b / 255,
          customClass.color.a,
        ];
        if (currentClass) {
          if (currentClass.name === customClass.name && currentClass.color === color) return;
          viewer.classificationsInfo.removeCustomClass({ identifier: currentClass.identifier });
        }

        viewer.classificationsInfo.addCustomClass({
          identifier: customClass.id,
          name: customClass.name,
          code: customClass.code,
          color,
        });
      });

      currentCustomClasses.forEach((c) => {
        if (!customClassesByIdentifier[c.identifier]) {
          viewer.classificationsInfo.removeCustomClass({ identifier: c.identifier });
        }
      });
    },
    [project?.customClasses],
  );

  const syncWmsLayers = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.wmsLayers || !viewer) return;
      if (!project.pointClouds.every((pointcloud) => initializedPointclouds.includes(pointcloud.id))) return;
      wmsLayersContextValue.wmsLayers.forEach((layer) => {
        if (layer.visible) viewer.addWmsLayer(layer);
        else viewer.removeWmsLayer({ identifier: layer.identifier });
      });
    },
    [initializedPointclouds, project?.pointClouds, project?.state.wmsLayers, wmsLayersContextValue.wmsLayers],
  );

  const syncOrthophotoLayers = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.orthophotoLayers || !viewer) return;
      if (!project.pointClouds.every((pointcloud) => initializedPointclouds.includes(pointcloud.id))) return;

      const sceneOrthophotos = viewer.scene.orthophotos;
      const sceneOrthophotosByIdentifier = keyBy('identifier', sceneOrthophotos);
      const databaseOrthophotosByIdentifier = keyBy('identifier', project.state.orthophotoLayers);

      orthophotoLayersContextValue.orthophotoLayers.forEach((layer) => {
        const currentOrthophoto = sceneOrthophotosByIdentifier[layer.identifier];
        if (currentOrthophoto) {
          if (currentOrthophoto.visible === layer.visible) return;
          else currentOrthophoto.visible = layer.visible;
        } else if (layer.url) {
          const orthophoto = viewer.scene.addOrthophotoLayer(layer.identifier, layer.elevation || 0);
          getGeoTiffData({ url: layer.url }).then((geotiff) => {
            orthophoto.initialise({
              visible: layer.visible,
              ...geotiff,
              origin: transformGeoTiffCoordinates(geotiff.origin, geotiff.crs),
              elevation: layer.elevation || 0,
            });
          });
        }
      });

      sceneOrthophotos.forEach((photo) => {
        if (!databaseOrthophotosByIdentifier[photo.identifier]) {
          viewer.scene.removeOrthophotoLayer({ identifier: photo.identifier });
        }
      });
    },
    [
      initializedPointclouds,
      project?.pointClouds,
      project?.state.orthophotoLayers,
      orthophotoLayersContextValue.orthophotoLayers,
      getGeoTiffData,
      transformGeoTiffCoordinates,
    ],
  );

  useEffect(() => {
    if (project?.state) {
      commander.setState({
        ...project.state,
        cadLayers: project.state.cadLayers.map((cadLayer) => ({
          ...cadLayer,
          type: 'CAD',
          visibleState: cadLayer.visibleState
            .map((state) => {
              if (state.__typename === 'CadLayerVisibleStateDelete') return { ...state, type: 'DELETE' as const };
              else if (state.__typename === 'CadLayerVisibleStateHidden') return { ...state, type: 'HIDDEN' as const };
              return null;
            })
            .filter(isNotNullOrUndefined),
        })),
        pointClouds: project.pointClouds.map((pc) => ({ name: pc.displayName, identifier: pc.id })),
        wmsLayers: project.state.wmsLayers || [],
      });
      syncAnnotations({ viewer });
      syncCadObjects({ viewer });
      syncWmsLayers({ viewer });
      syncOrthophotoLayers({ viewer });
      syncBoxes({ viewer });
      syncPointClusters({ viewer });
      syncClassifications({ viewer });
      syncPlanes({ viewer });
    }
  }, [
    commander,
    project?.state,
    project?.pointClouds,
    syncAnnotations,
    syncCadObjects,
    syncPointClusters,
    viewer,
    syncWmsLayers,
    syncOrthophotoLayers,
    syncBoxes,
    cadLayers,
    syncClassifications,
    syncPlanes,
  ]);
};
