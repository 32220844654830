import { T } from '../../../translation/src';
import { memo, useContext } from 'react';
import { useDownloadExport } from '../../../hooks/useDownloadExport';
import { Export, ExportParamInfo, GeneralStatus } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { Popover } from '../../../components/Popover';
import { ArrowDownTrayIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { RendererReadOnlyContext } from '../../../contexts/RendererReadOnlyContext';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ListItem } from '../../../components/ListItem';
import { ProjectDeleteExportModal } from '../../project/ProjectDeleteExportModal';
import useOpen from '../../../hooks/useOpen';
import { FiltersInfo } from './FiltersInfo';

interface ExportListProps {
  projectId: string;
  title: string;
  exports: {
    date: string;
    exportId: string;
    paramInfo: ExportParamInfo;
    status: GeneralStatus;
  }[];
}

const ExportList_: React.FC2<ExportListProps> = ({ projectId, title, exports: listedExports }) => {
  const { downloadExport } = useDownloadExport();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const {
    onOpenWithValue: openDeleteExportModal,
    onClose: closeDeleteExportModal,
    open: deleteExportModalOpen,
    value: deleteExportValue,
  } = useOpen<Pick<Export, 'exportId'>>();

  return (
    <div className="flex flex-col">
      <PropertiesSection title={<T _str={title} swc />}>
        {listedExports.length > 0 ? (
          listedExports.map((e) => {
            if (e.status === GeneralStatus.Success)
              return (
                <div key={e.exportId}>
                  <div
                    key={e.exportId}
                    className="relative flex flex-col p-2 space-y-2 border border-gray-400 rounded-sm"
                  >
                    <Property title={<T _str="date" swc />}>{e.date}</Property>
                    <Property title={<T _str="filetype" swc />}>{e.paramInfo.fileType}</Property>
                    <Property title={<T _str="filters" swc />}>
                      <FiltersInfo filters={e.paramInfo}></FiltersInfo>
                    </Property>
                    {!isReadOnly && (
                      <Popover
                        data-project-actions
                        className="absolute top-0 right-0"
                        buttonChild={<EllipsisHorizontalIcon className="w-5 dark:text-white dark:hover:text-black" />}
                        buttonClassName="absolute flex items-center justify-center px-1 rounded right-1 top-0 hover:bg-gray-200"
                        panelClassName="mt-6"
                      >
                        {({ close }) => (
                          <>
                            <ListItem
                              onClick={() => {
                                downloadExport({ projectId, exportId: e.exportId });
                                close();
                              }}
                              icon={ArrowDownTrayIcon}
                            >
                              <T _str="download" swc />
                            </ListItem>
                            <ListItem
                              onClick={() => {
                                openDeleteExportModal(e);
                              }}
                              icon={TrashIcon}
                            >
                              <T _str="delete" swc />
                            </ListItem>
                          </>
                        )}
                      </Popover>
                    )}
                  </div>
                  <ProjectDeleteExportModal
                    onClose={closeDeleteExportModal}
                    exports={deleteExportValue ? [deleteExportValue] : undefined}
                    open={deleteExportModalOpen}
                  />
                </div>
              );
            return undefined;
          })
        ) : (
          <div className="p-2 text-center text-gray-500">
            <T _str="No exports on this pointcloud" swc />
          </div>
        )}
      </PropertiesSection>
    </div>
  );
};

export const ExportList = memo(ExportList_);
