import { memo } from 'react';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';
import { T } from '../../../translation/src';
import { AnnotationLabels, DistanceAnnotation } from '../../../types/graphqlTypes';
import { EditableColorProperty, StaticColorProperty } from './ColorProperties';
import { PropertiesSection, Property } from './PropertiesSection';
import { useMeasurements } from '../../../hooks/useMeasurements';
import { HideLabelProperty } from './HideLabel';
import { ICadLine } from '../../../utils/CADFileParser';

interface DistancePropertiesProps {
  annotation: DistanceAnnotation;
}
const DistanceProperties_: React.FC2<DistancePropertiesProps> = ({ annotation }) => {
  const { formatNumber } = useFormatNumber();
  const { heightMeasurement, slopeDistanceMeasurement } = useMeasurements();

  const slopeDistance = slopeDistanceMeasurement(annotation.points.map((point) => point.position));
  const height = heightMeasurement(annotation.points.map((point) => point.position));
  const distance = Math.sqrt((height * height - slopeDistance * slopeDistance) * -1);

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="line" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={<T _str="3D Distance" swc />}>
          {formatNumber(slopeDistance, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="2D Distance" swc />}>
          {formatNumber(distance, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="Height Difference" swc />}>
          {formatNumber(height, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <HideLabelProperty annotations={[annotation]} label={AnnotationLabels.Length} />
        <EditableColorProperty annotations={[annotation]} />
      </PropertiesSection>
    </div>
  );
};
export const DistanceProperties = memo(DistanceProperties_);

const CadDistanceProperties_: React.FC2<{ cadLine: ICadLine }> = ({ cadLine }) => {
  const { formatNumber } = useFormatNumber();
  const { heightMeasurement, slopeDistanceMeasurement } = useMeasurements();

  const slopeDistance = slopeDistanceMeasurement(cadLine.points);
  const height = heightMeasurement(cadLine.points);
  const distance = Math.sqrt((height * height - slopeDistance * slopeDistance) * -1);

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="line" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={<T _str="3D Distance" swc />}>
          {formatNumber(slopeDistance, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="2D Distance" swc />}>
          {formatNumber(distance, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
        <Property title={<T _str="Height Difference" swc />}>
          {formatNumber(height, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <StaticColorProperty color={cadLine.color} />
      </PropertiesSection>
    </div>
  );
};

export const CadDistanceProperties = memo(CadDistanceProperties_);
