import { memo, useContext } from 'react';
import { Button } from '../../components/Button';
import { T } from '../../translation/src';
import { UserContext } from '../../contexts/UserContext';
import { POINTORAMA_CONTACT_URL } from '../../utils/constants';

const NoOrganisationFound_: React.FC2 = () => {
  const user = useContext(UserContext);
  if (user.organisations.length) {
    return <div className="dark:bg-[#313131] w-full" />;
  }

  return (
    <div className="flex items-center justify-center dark:bg-[#313131] dark:text-white flex-col w-full h-full">
      <div className="mb-6 text-lg font-bold">{'It seems that you are not a member of any organisation.'}</div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-3">
          {' '}
          <T _str="Please ask the admin of your organisation to send you an invitation, or contact us to create your own organisation." />
        </div>
        <div>
          <Button variant="primary" size="xs" onClick={() => window.open(POINTORAMA_CONTACT_URL, '_blank')}>
            <T _str="contact us" swc />{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const NoOrganisationFound = memo(NoOrganisationFound_);
