import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import { T, useT } from '../../../translation/src';
import { FileInput, FileInputProps } from '../../inputs/FileInput';
import { Label } from '../Label';

export const useFormikFileInputTranslations = () => {
  const filePlaceholder = useT('choose file', { swc: true });
  const fileLabel = useT('file', { swc: true });
  return { filePlaceholder, fileLabel };
};
export const FormikPointCloudFileInput: React.FC2<FormikFileInputProps> = (props) => {
  const translations = useFormikFileInputTranslations();
  return (
    <FormikFileInput
      accept=".las,.pts,.laz,.e57"
      label={`${translations.fileLabel} (.las, .pts, .laz, .e57)`}
      title={`${translations.filePlaceholder} (.las, .pts, .laz, .e57)`}
      {...props}
    />
  );
};

export const FormikCADFileInput: React.FC2<FormikFileInputProps> = (props) => {
  const translations = useFormikFileInputTranslations();
  return (
    <FormikFileInput
      accept=".kml,.gml,.geojson"
      label={`${translations.fileLabel} (.kml, .geojson)`}
      title={`${translations.filePlaceholder} (.kml, .geojson)`}
      {...props}
    />
  );
};

interface FormFileInputProps extends FileInputProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}
const FormFileInput_: React.FC2<FormFileInputProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  ...props
}) => {
  return (
    <label className={classNames('block', propsClassName)}>
      <Label>{label}</Label>
      <FileInput
        id={name}
        name={name}
        className={classNames(inputClassName, error && 'border-error-red-500', 'mt-1')}
        {...props}
      />
      <div className="flex items-center h-4 mt-1 text-xs text-error-red-500">{!hideErrorLabel && error}</div>
    </label>
  );
};

export const FormFileInput = memo(FormFileInput_) as typeof FormFileInput_;

interface FormikFileInputProps extends FormFileInputProps {
  name: string;
}

const FormikFileInput_: React.FC2<FormikFileInputProps> = ({ name, ...props }) => {
  const [{ onChange: onChangeField, value, ...field }, meta] = useField(name);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChangeField({ ...event, target: { ...event.target, name, value: event.target.files } });
    },
    [name, onChangeField],
  );

  return (
    <FormFileInput
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...field}
      onChange={onChange}
    />
  );
};

export const FormikFileInput = memo(FormikFileInput_) as typeof FormikFileInput_;
