import {
  EllipsisVerticalIcon,
  KeyIcon,
  PlayIcon,
  ShieldCheckIcon,
  StopIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { ListItemProps } from '../../components/ListItem';
import { StatusPill } from '../../components/Pill';
import { Popover, PopoverListItem } from '../../components/Popover';
import { Option, Select, SelectProps } from '../../components/inputs/Select';
import { UserContext } from '../../contexts/UserContext';
import { useUserRole } from '../../hooks/modules/user/useUserRole';
import { useUserRoleTranslations } from '../../hooks/translations/useUserRoleTranslations';
import { useFormatDate } from '../../hooks/useFormatDate';
import useOpen from '../../hooks/useOpen';
import { ProjectAccessModal } from '../../modules/project/ProjectAccessModal';
import { AddUserModal } from '../../modules/user/AddUserModal';
import { ChangeRoleModal } from '../../modules/user/ChangeRoleModal';
import { DeleteUserFromOrganisationModal } from '../../modules/user/DeleteUserFromOrganisationModal';
import { T } from '../../translation/src';
import {
  UserRole,
  UserStatus,
  UsersQuery,
  useUserDisableMutation,
  useUserEnableMutation,
  useUsersQuery,
} from '../../types/graphqlTypes';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { PageHeader } from '../../components/page/PageHeader';
import { PageContent } from '../../components/page/PageContent';

function getInitials(name: string): string {
  // Split the name into words
  const words: string[] = name.split(' ');

  // Initialize an array to store the initials
  const initials: string[] = [];

  // Iterate through the words and extract the first letter of each word
  for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
    const word = words[wordIndex];
    if (wordIndex === 0) {
      initials.push(word);
    } else {
      const matches = word.match(/[A-Za-z]/); // Find the first alphabetic character
      if (matches) {
        initials.push(matches[0].toUpperCase() + '.');
      }
    }
  }

  // Join the initials and return as a string
  return initials.join(' ');
}

const UserRow_: React.FC2<{
  user: UsersQuery['usersByOrganisationId'][0];
  userRole: UserRole;
  isCurrentUser?: boolean;
  organisationId: string;
}> = ({ user, userRole, isCurrentUser, organisationId }) => {
  const roleTranslations = useUserRoleTranslations();
  const { onClose: closeUserDeleteModal, onOpen: openUserDeleteModal, open: userDeleteModalOpen } = useOpen();
  const {
    setFalse: closeProjectAccessModal,
    setTrue: openProjectAccessModal,
    value: projectAccessModalOpen,
  } = useBoolean();
  const { setFalse: closeChangeRoleModal, setTrue: openChangeRoleModal, value: changeRoleModalOpen } = useBoolean();
  const [disableUser] = useUserDisableMutation();
  const [enableUser] = useUserEnableMutation();
  const { formatDate } = useFormatDate();
  const { xsDevice, mdDevice, lgDevice } = useDeviceSize();

  const onChangeUserRole: Required<ListItemProps>['onClick'] = useCallback(() => {
    if (user.role !== UserRole.Guest) return openChangeRoleModal();
    return openChangeRoleModal();
  }, [openChangeRoleModal, user.role]);

  const onDisableUser: Required<ListItemProps>['onClick'] = useCallback(() => {
    disableUser({
      variables: { organisationId, userId: user.id },
      optimisticResponse: {
        userDisable: {
          ...user,
          status: UserStatus.Disabled,
          __typename: 'User',
        },
      },
    });
  }, [disableUser, organisationId, user]);

  const onEnableUser: Required<ListItemProps>['onClick'] = useCallback(() => {
    enableUser({
      variables: { organisationId, userId: user.id },
      optimisticResponse: { userEnable: { ...user, status: UserStatus.Active, __typename: 'User' } },
    });
  }, [enableUser, organisationId, user]);

  const statusPill = useMemo(() => {
    if (user.status === UserStatus.Disabled)
      return (
        <StatusPill status="process" className="h-10 text-sm w-28">
          <T _str="disabled" swc />
        </StatusPill>
      );
    if (user.status === UserStatus.InvitePending)
      return (
        <StatusPill status="pending" className="h-10 text-sm w-28">
          <T _str="invite pending" swc />
        </StatusPill>
      );
    return (
      <StatusPill status="success" className="h-10 text-sm w-28">
        <T _str="active" swc />
      </StatusPill>
    );
  }, [user.status]);

  const projectsCell = useMemo(() => {
    if (user.role === UserRole.Guest) {
      let description = '-';
      if (user.allowedProjectIds.length) description = user.allowedProjects.map((project) => project.name).join(', ');
      return (
        <div className="truncate" title={description}>
          {description}
        </div>
      );
    }
    return (
      <div className="truncate">
        <T _str="all projects" swc />
      </div>
    );
  }, [user.allowedProjectIds.length, user.allowedProjects, user.role]);

  const userNameCell = (
    <div className="flex flex-col flex-grow">
      <div>{user.name}</div>
      <div className="text-sm text-gray-500 dark:text-gray-300">{user.email}</div>
    </div>
  );
  const userTypeCell = (
    <div className="flex flex-col w-32">
      <div className="text-sm text-gray-500 dark:text-gray-300">
        <T _str="user type" swc />
      </div>
      <div>{roleTranslations[user.role.toLocaleLowerCase()]}</div>
    </div>
  );
  const statusPillCell = <div className="flex flex-col items-center justify-center w-32">{statusPill}</div>;
  const actionsCell = (
    <div className="flex flex-row items-center justify-end w-8">
      {!isCurrentUser && (
        <Popover
          data-user-actions
          buttonChild={<EllipsisVerticalIcon className="w-4 text-gray-600 dark:text-gray-200" />}
          buttonClassName="px-1 py-1 rounded right-1 top-0 hover:bg-gray-200 dark:hover:bg-gray-600"
          panelClassName={'mt-2 w-64'}
        >
          <PopoverListItem onClick={onChangeUserRole} icon={ShieldCheckIcon}>
            <T _str="change role" swc />
          </PopoverListItem>
          <hr className="my-2 border-t border-gray-300" />
          {user.role === UserRole.Guest && (
            <PopoverListItem onClick={openProjectAccessModal} icon={KeyIcon}>
              <T _str="manage project access" swc />
            </PopoverListItem>
          )}
          {user.status === UserStatus.Active && (
            <PopoverListItem onClick={onDisableUser} icon={StopIcon}>
              <T _str="disable" swc />
            </PopoverListItem>
          )}
          {user.status === UserStatus.Disabled && (
            <PopoverListItem onClick={onEnableUser} icon={PlayIcon}>
              <T _str="re-activate" swc />
            </PopoverListItem>
          )}
          <PopoverListItem onClick={openUserDeleteModal} icon={TrashIcon}>
            <T _str="delete" swc />
          </PopoverListItem>
        </Popover>
      )}
    </div>
  );

  const modals = (
    <>
      <DeleteUserFromOrganisationModal onClose={closeUserDeleteModal} open={userDeleteModalOpen} user={user} />
      <ProjectAccessModal onClose={closeProjectAccessModal} open={projectAccessModalOpen} user={user} />
      <ChangeRoleModal onClose={closeChangeRoleModal} open={changeRoleModalOpen} user={user} />
    </>
  );

  if (xsDevice)
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-2 space-x-2 text-xs items-center">
          <div className="flex flex-col flex-grow">{getInitials(user.name)}</div>
          <div className="flex items-center w-14">
            <div>{roleTranslations[user.role.toLocaleLowerCase()]}</div>
          </div>
          {userRole === UserRole.Admin && actionsCell}
        </div>
        {modals}
      </>
    );

  if (mdDevice)
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
          {userNameCell}
          {userTypeCell}
          {userRole === UserRole.Admin && actionsCell}
        </div>
        {modals}
      </>
    );

  if (lgDevice) {
    return (
      <>
        <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
          {userNameCell}
          {userTypeCell}
          {statusPillCell}
          {userRole === UserRole.Admin && actionsCell}
        </div>
        {modals}
      </>
    );
  }
  return (
    <>
      <div className="flex bg-white border border-gray-300 rounded dark:bg-[#242424] dark:text-white dark:border-gray-500 p-4 space-x-2">
        {userNameCell}
        {userTypeCell}
        <div className="flex flex-col w-32">
          <div className="text-sm text-gray-500 dark:text-gray-300">
            {user.status === UserStatus.InvitePending ? <T _str="invitation date" swc /> : <T _str="join date" swc />}
          </div>
          <div>{formatDate(user.joinDate)}</div>
        </div>
        <div className="flex flex-col w-60">
          <div className="text-sm text-gray-500 dark:text-gray-300">
            <T _str="projects with access" swc />
          </div>
          {projectsCell}
        </div>
        {statusPillCell}
        {userRole === UserRole.Admin && (
          <div className="flex flex-row items-center justify-end w-8">{!isCurrentUser && actionsCell}</div>
        )}
      </div>
      {modals}
    </>
  );
};
const UserRow = memo(UserRow_);

export const Users: React.FC = () => {
  const { organisationId = '' } = useParams<{ organisationId: string }>();
  const usersQuery = useUsersQuery({ variables: { organisationId } });
  const roleTranslations = useUserRoleTranslations();
  const [filters, setFilters] = useState({ type: 'ALL' });
  const { open: addUserModalOpen, onClose: closeAddUserModal, onOpen: openAddUserModal } = useOpen();
  const currentUser = useContext(UserContext);
  const role = useUserRole();

  const users = useMemo(() => {
    return (usersQuery.data?.usersByOrganisationId || []).filter((user) => {
      if (user.role === UserRole.ApiKey) return false;
      if (filters.type !== 'ALL' && user.role !== filters.type) return false;
      return true;
    });
  }, [filters.type, usersQuery.data?.usersByOrganisationId]);

  const onFilter: SelectProps<string | number>['onChange'] = useCallback((event) => {
    setFilters((filters) => ({ ...filters, [event.target.name]: event.target.value }));
  }, []);

  const onAddUser = openAddUserModal;

  return (
    <>
      <PageContent>
        <PageHeader
          title={<T _str="users and guests" swc />}
          buttonTitle={<T _str="member" swc />}
          onClickButton={onAddUser}
        />

        <div className="flex mb-4">
          <Select
            onChange={onFilter}
            value={filters.type}
            name="type"
            isLabelPlaceholder
            placeholder={<T _str="type" swc />}
            className="w-40"
          >
            {['ALL', ...Object.values(UserRole)].map((role) => (
              <Option key={role} value={role}>
                {roleTranslations[role.toLocaleLowerCase()]}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col flex-grow space-y-4 overflow-auto">
          {users.map((user) => (
            <UserRow
              key={user.id}
              user={user}
              userRole={role}
              isCurrentUser={user.id === currentUser.id}
              organisationId={organisationId}
            />
          ))}
        </div>
      </PageContent>
      <AddUserModal open={addUserModalOpen} onClose={closeAddUserModal} />
    </>
  );
};
