import { Option, Select, SelectProps } from '../../../components/inputs/Select';
import { useUpdateProjectSettings } from '../../../hooks/modules/project/useUpdateProjectSettings';
import { T } from '../../../translation/src';
import { Project } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useProjectionSystemOptions } from '../../project/ProjectProjectionSystemModal';
import { PropertiesSection, Property } from './PropertiesSection';

interface MapPropertiesProps {
  project: Pick<Project, 'settings'>;
}
const MapProperties_: React.FC2<MapPropertiesProps> = ({ project }) => {
  const projectionSystemOptions = useProjectionSystemOptions();

  const [updateProjectSettings] = useUpdateProjectSettings();

  const onChangeProjectionSystem: SelectProps['onChange'] = useCallback(
    (event) => {
      updateProjectSettings({ settings: { projectionSystem: event.target.value } });
    },
    [updateProjectSettings],
  );

  return (
    <div className="flex flex-col flex-grow space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="OpenStreetMap" />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="settings" swc />}>
        <Property title={<T _str="projection system" swc />}>
          <Select
            className="w-full"
            onChange={onChangeProjectionSystem}
            value={project.settings?.projectionSystem || ''}
          >
            {projectionSystemOptions.map((option) => (
              <Option key={option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Property>
      </PropertiesSection>
    </div>
  );
};

export const MapProperties = memo(MapProperties_);
