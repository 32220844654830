import { PointCloud, UploadStatus } from '../../../../types/graphqlTypes';
import { memo, useContext } from 'react';
import { PointCloudLayer } from './PointCloudLayer';
import { useCustomSelector } from '../../../../redux/store';
import { RendererContext } from '../../../../contexts/RendererContext';

interface PointCloudLayersProps {
  pointClouds: Pick<PointCloud, 'cloudName' | 'id' | 'visible' | 'displayName' | 'uploadStatus' | 'availableClasses'>[];
}

const PointCloudLayers_: React.FC2<PointCloudLayersProps> = ({ pointClouds }) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const { selectedPointCloudName } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedPointCloudName', 'mapSelected'],
  );
  return (
    <div className="flex flex-col pb-2">
      {pointClouds.map((pointCloud) => (
        <PointCloudLayer
          pointCloud={pointCloud}
          active={selectedPointCloudName === pointCloud.cloudName}
          key={pointCloud.id}
          refreshSuggested={
            pointCloud.uploadStatus === UploadStatus.Success &&
            !viewer?.scene.pointclouds.some((p) => p.identifier === pointCloud.id)
          }
          isLastPointCloud={pointClouds.length === 1}
        />
      ))}
    </div>
  );
};
export const PointCloudLayers = memo(PointCloudLayers_);
