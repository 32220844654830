import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../components/Button';
import { InfoContent } from '../../../components/InfoContent';
import { Modal } from '../../../components/Modal';
import { PROJECT_DELETE } from '../../../graphql/project';
import { T } from '../../../translation/src';
import { Project, ProjectDeleteMutation, ProjectDeleteMutationVariables } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';

interface ProjectProcessModalProps {
  onClose: () => void;
  open?: boolean;
  project: Pick<Project, 'id' | 'name'>;
}
const ProjectProcessModal_: React.FC2<ProjectProcessModalProps> = ({ onClose, open, project }) => {
  const [deleteProject] = useMutation<ProjectDeleteMutation, ProjectDeleteMutationVariables>(PROJECT_DELETE);

  const onDelete = useCallback(() => {
    if (!project) return;
    deleteProject({
      variables: { projectId: project?.id },
      onCompleted: onClose,
      update: (cache) => cache.evict({ id: `Project:${project?.id}` }),
    });
  }, [deleteProject, onClose, project]);

  return (
    <Modal.Container onClose={onClose} open={open} className={'flex flex-col w-full h-full'}>
      <Modal.Header>
        <T _str="Unable to open {name}" args={{ name: project.name }} />
      </Modal.Header>
      <Modal.Body>
        {' '}
        <InfoContent
          type="warning"
          title={<T _str="Your file is being processed" swc />}
          description={
            <T _str="We’re rendering your file, this can take some time. You will get a notification when your project is ready to use." />
          }
        />
      </Modal.Body>
      <Modal.Footer className="justify-between space-x-4">
        <Button variant="primary" onClick={onClose}>
          <T _str="close this window" />
        </Button>
        <span
          className="inline-flex items-center text-sm text-red-500 cursor-pointer hover:text-red-700"
          onClick={onDelete}
        >
          <TrashIcon className="w-5 h-5" /> <T _str="cancel processing and delete" swc />
        </span>
      </Modal.Footer>
    </Modal.Container>
  );
};

export const ProjectProcessModal = memo(ProjectProcessModal_);
