import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo } from 'react';
import { TextArea, TextAreaProps } from '../../inputs/TextArea';
import { Label } from '../Label';

interface FormTextAreaProps extends TextAreaProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}

const FormTextArea_: React.FC2<FormTextAreaProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  ...props
}) => {
  return (
    <label className={classNames('block', propsClassName)}>
      <Label>{label}</Label>
      <TextArea
        id={name}
        name={name}
        className={classNames(inputClassName, error && 'border-error-red-500', 'mt-1')}
        {...props}
      />
    </label>
  );
};

export const FormTextArea = memo(FormTextArea_) as typeof FormTextArea_;

interface FormikTextAreaProps extends FormTextAreaProps {
  name: string;
}

const FormikTextArea_: React.FC2<FormikTextAreaProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return <FormTextArea error={meta.touched && meta.error ? meta.error : undefined} {...props} {...field} />;
};

export const FormikTextArea = memo(FormikTextArea_) as typeof FormikTextArea_;
