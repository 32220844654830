import classNames from 'classnames';
import * as React from 'react';
import { DetailedHTMLProps, InputHTMLAttributes, memo } from 'react';

interface RadioGroupProps {
  children: React.ReactNode;
  value: any;
  onChange: (event: { target: { value: any; name: string } }) => void;
  name: string;
}
const RadioGroup_: React.FC<RadioGroupProps> = ({ children, value, onChange, name }) => {
  return (
    <div className="flex flex-col space-y-1">
      {React.Children.map(children, (child: any) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as any, {
            key: child.key || (child.props as any).value,
            customKey: child.key,
            onChange: (event: any) => {
              onChange({ target: { value: event.target.value, name: name || '' } });
            },
            // @ts-ignore
            checked: value === child.props.value,
            name,
          });
        }
        return null;
      })}
    </div>
  );
};
export const RadioGroup = memo(RadioGroup_);

export type RadioProps = {
  className?: string;
  extraContent?: React.ReactNode;
  labelClassName?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
const Radio_: React.FC<RadioProps> = ({ className, children, name, extraContent, labelClassName, ...props }) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="flex items-center">
        <div className="relative flex items-center justify-center flex-shrink-0 w-4 h-4 bg-white rounded-full dark:bg-gray-100">
          <input
            aria-labelledby={name}
            type="radio"
            name={name}
            className={classNames(
              'absolute w-full h-full border border-gray-400 rounded-full appearance-none cursor-pointer checkbox focus:opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700  focus:outline-none checked:border-none focus:dark:ring-green-600 focus:dark:bg-green-600',
              props.checked ? 'dark:bg-green-600 hover:dark:bg-green-600' : 'dark:bg-white',
            )}
            {...props}
          />
          <div className="hidden w-full h-full border-4 border-indigo-700 rounded-full dark:border-green-600 check-icon z-1"></div>
        </div>
        <label
          id={name}
          className={classNames('ml-2 text-sm font-normal leading-4 text-gray-800 dark:text-gray-100', labelClassName)}
        >
          {children}
        </label>
      </div>
      {extraContent}
    </div>
  );
};

export const Radio = memo(Radio_);
Radio.displayName = 'Radio';
