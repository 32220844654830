import {
  ArrowsPointingOutIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
  XMarkIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { LineSvgProps, ResponsiveLine } from '@nivo/line';
import { Badge } from '../../../components/Badge';
import { Modal } from '../../../components/Modal';
import { Popover, PopoverListItem } from '../../../components/Popover';
import { ProjectionCalculation } from '../../../contexts/AnnotationContext';
import { ConvertType, useCondMeterToFeet, useFormatNumber } from '../../../hooks/useFormatNumber';
import useOpen from '../../../hooks/useOpen';
import { T, useT } from '../../../translation/src';
import { GeneralStatus, PointCloud } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback, useContext, useState } from 'react';
import { ProjectDeleteCalculationModal } from '../../project/ProjectDeleteCalculationModal';
import { FiltersInfo } from './FiltersInfo';
import { Option, Select } from '../../../components/inputs/Select';
import { UserContext } from '../../../contexts/UserContext';
import { Property } from './PropertiesSection';
import ArrowExpandIcon from '../../../assets/icons/arrow-expand.svg?react';
import { IconBox } from '../../../components/IconBox';

type Projection = {
  values: NonNullable<Required<ProjectionCalculation>['result']>['projection'];
  name?: string;
};

const useCommonGraphProps = (
  { projections }: { projections: Projection[] },
  tooltipColor: string,
  widthFactor?: number,
  legend?: boolean,
): LineSvgProps => {
  const { formatNumber } = useFormatNumber();
  const { condMeterToFeet } = useCondMeterToFeet();
  const { darkMode } = useContext(UserContext);
  const legendItemHeight = 25;
  const legendHeight = legend ? (projections.length + 1) * legendItemHeight : 0;
  const lineColors = darkMode
    ? ['#B18CE5', '#B5FFA1', '#C9D9CD', '#2F5954', '#F3F1F1']
    : ['#702ECF', '#5BCE3B', '#2F5954', '#181818'];
  let textColor = '';
  if (darkMode) {
    textColor = '#FFFFFF';
  } else {
    textColor = '#000000';
  }

  return {
    data: projections.map((projection) => ({
      id: projection.name || '',
      data: projection.values.map((p) => ({
        x: condMeterToFeet(p.distance, ConvertType.DISTANCE),
        y: condMeterToFeet(p.height, ConvertType.DISTANCE),
      })),
    })),
    xScale: { type: 'linear' },
    gridXValues: 10 * (widthFactor || 1),
    isInteractive: true,
    useMesh: true,
    yScale: {
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
    },
    yFormat: ' >-.2f',
    enableSlices: false,
    enableArea: true,
    areaBaselineValue: condMeterToFeet(Math.min(...projections.flatMap((p) => p.values.map((value) => value.height)))),
    enablePoints: false,
    crosshairType: 'cross',
    defs: [
      {
        id: 'gradient',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: 'inherit' },
          { offset: 45, color: 'inherit', opacity: 0.1 },
        ],
      },
    ],
    fill: [{ match: '*', id: 'gradient' }],
    colors: lineColors,
    lineWidth: 1,
    ...(legend && {
      margin: { top: 50, right: 50, bottom: legendHeight, left: 50 },
      legends: [
        {
          anchor: 'bottom-left',
          direction: 'column',
          itemHeight: legendItemHeight,
          itemWidth: 100,
          translateY: legendHeight,
          itemTextColor: textColor,
          symbolShape: 'circle',
        },
      ],
    }),
    theme: {
      axis: {
        ticks: {
          text: {
            fill: textColor,
          },
        },
      },
      crosshair: {
        line: {
          stroke: textColor,
        },
      },
    },
    tooltip: ({ point }) => {
      return (
        <div
          className={`flex flex-col p-2 text-white dark:text-black rounded shadow-lg`}
          style={{ backgroundColor: tooltipColor }}
        >
          <div className="inline-flex">
            <div className="w-16 mr-2 dark">
              <T _str="elevation" swc />
              {': '}
            </div>

            <div className="font-bold">
              {formatNumber(+point.data.yFormatted, { convertType: ConvertType.NONE, addUnit: true })}
            </div>
          </div>
          <div className="inline-flex">
            <div className="w-16 mr-2">
              <T _str="distance" swc />
              {': '}
            </div>

            <div className="font-bold">
              {formatNumber(+point.data.xFormatted, { convertType: ConvertType.NONE, addUnit: true })}
            </div>
          </div>
        </div>
      );
    },
  };
};

const ElevationProfileSimplified_: React.FC2<{ projections: Projection[] }> = ({ projections }) => {
  const [tooltipColor, setTooltipColor] = useState<string>('#B5FFA1');
  const graphProps = useCommonGraphProps({ projections: projections }, tooltipColor);

  return (
    <div className="w-full h-60">
      <ResponsiveLine
        {...graphProps}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        onMouseMove={(point) => setTooltipColor(point.color)}
      />
    </div>
  );
};
const ElevationProfileSimplified = memo(ElevationProfileSimplified_);

const ElevationModal_: React.FC2<{ projections: Projection[]; open: boolean; onClose: () => void }> = ({
  projections,
  open,
  onClose,
}) => {
  const maxWidthFactor = 50;
  const [widthFactor, setwidthFactor] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const [tooltipColor, setTooltipColor] = useState<string>('#B5FFA1');
  const graphProps = useCommonGraphProps({ projections }, tooltipColor, widthFactor, true);

  const onClickScaleUp: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
    if (widthFactor <= maxWidthFactor) setwidthFactor(widthFactor + 1);
  }, [widthFactor]);

  const onClickScaleDown: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
    if (widthFactor > 1) setwidthFactor(widthFactor - 1);
  }, [widthFactor]);

  const onClickFullScreen: React.MouseEventHandler<HTMLDivElement> = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };

  return (
    <div className="overflow-auto">
      <Modal.Container
        noOverflow
        onClose={onClose}
        open={open}
        panelClassName="w-3/4 max-w-7xl"
        fullScreen={fullScreen}
      >
        <Modal.Header className="flex justify-between">
          <T _str="elevation profile" swc />
          <div className="flex space-x-1 ">
            <XMarkIcon onClick={onClose} className="w-6 h-6 hover:bg-black hover:text-white" />
          </div>
        </Modal.Header>
        <Modal.Body className={classNames('w-full ', fullScreen ? 'h-5/6' : 'h-136')}>
          <div className="w-full h-full mb-16 overflow-auto scroll-p-2">
            <div style={{ width: `${widthFactor * 100}%`, height: '100%' }}>
              <ResponsiveLine
                {...graphProps}
                axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                onMouseMove={(point) => setTooltipColor(point.color)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-end justify-end">
          <div className="flex overflow-hidden bg-white border-2 border-gray-700 divide-x-2 divide-gray-700 rounded-lg dark:bg-black dark:border-0 dark:text-white">
            <IconBox icon={ArrowExpandIcon} onClick={onClickFullScreen} />
            <IconBox icon={MinusIcon} onClick={onClickScaleDown} />
            <IconBox icon={PlusIcon} onClick={onClickScaleUp} />
          </div>
        </Modal.Footer>
      </Modal.Container>
    </div>
  );
};
const ElevationModal = memo(ElevationModal_);

const useTranslations = () => {
  const invalidCalculation = useT(
    'This calculation is not valid anymore due to changes on the polyline object. You should delete this one and recalculate it.',
  );
  const distanceCalculation = useT('distance calculation', { swc: true });
  return { invalidCalculation, distanceCalculation };
};

interface DistanceCalculationProps {
  calculations: ProjectionCalculation[];
  project: {
    id: string;
    pointClouds: Pick<PointCloud, 'id' | 'displayName' | 'availableClasses'>[];
  };
}
const DistanceCalculation_: React.FC2<DistanceCalculationProps> = ({ calculations, project }) => {
  const [selectedPointClouds, setSelectedPointClouds] = useState(project.pointClouds);
  const translations = useTranslations();

  const {
    onOpenWithValue: openDeleteCalculationModal,
    onClose: closeDeleteCalculationModal,
    open: deleteCalculationModalOpen,
  } = useOpen();
  const {
    onOpen: openElevationProfileModal,
    onClose: closeElevationProfileModal,
    open: elevationProfileModalOpen,
  } = useOpen();

  const onChangePointCloud = useCallback((event: any) => {
    setSelectedPointClouds(event.target.value);
  }, []);

  const filterIds = selectedPointClouds.map((pointCloud) => pointCloud.id);
  const filteredCalculations = calculations.filter((calculation) =>
    filterIds.includes(calculation.pointCloudIdentifier),
  );
  // reverse puts them in the right order for the graph and legend, in line with layers tab
  const projections = filteredCalculations
    .slice()
    .reverse()
    .map((calculation) => {
      const pointCloud = selectedPointClouds.find((pc) => pc.id === calculation?.pointCloudIdentifier);
      return { values: calculation?.result?.projection || [], name: pointCloud?.displayName };
    });
  return (
    <>
      <div className="flex flex-col">
        {!!calculations.length &&
          !calculations.some((calculation) => calculation.status === GeneralStatus.Progress) && (
            <div className="mb-4">
              <div className="mb-2">
                <Property title={<T _str="pointcloud" swc />}>
                  <Select<any>
                    className="w-full"
                    onChange={onChangePointCloud}
                    value={selectedPointClouds}
                    name="classification"
                    multiple
                    clearable
                  >
                    {project.pointClouds.map((value) => (
                      <Option key={value.id} value={value}>
                        <div className="flex items-center">{value.displayName}</div>
                      </Option>
                    ))}
                  </Select>
                </Property>
              </div>
              <div
                className={classNames(
                  'relative pt-6 border rounded',
                  calculations[0].isOutDated ? 'border-orange-500' : 'border-gray',
                )}
              >
                <ElevationProfileSimplified key={calculations[0].id} projections={projections} />
                <div className="pt-2 m-2 mt-4 border-t border-gray-300">
                  <Property title={<T _str="filters" swc />}>
                    <FiltersInfo filters={calculations[0].paramInfo} />
                  </Property>
                </div>
                <Popover
                  data-project-actions
                  className="absolute top-0 right-0"
                  buttonChild={<EllipsisHorizontalIcon className="w-5 dark:text-white dark:hover:text-black" />}
                  buttonClassName="absolute flex items-center justify-center px-1 rounded right-2 top-2 hover:bg-gray-200"
                  panelClassName="mt-6"
                >
                  <PopoverListItem onClick={openElevationProfileModal} icon={ArrowsPointingOutIcon}>
                    <T _str="expand" swc />
                  </PopoverListItem>
                  <hr className="my-2 border-t border-gray-300" />
                  <PopoverListItem onClick={openDeleteCalculationModal} icon={TrashIcon}>
                    <T _str="delete" swc />
                  </PopoverListItem>
                </Popover>
                {calculations[0].isOutDated && (
                  <Badge
                    type="warning"
                    className="absolute cursor-pointer -top-3 right-2"
                    title={translations.invalidCalculation}
                  />
                )}
              </div>
            </div>
          )}
      </div>
      <ProjectDeleteCalculationModal
        onClose={closeDeleteCalculationModal}
        calculations={calculations}
        open={deleteCalculationModalOpen}
      />
      <ElevationModal onClose={closeElevationProfileModal} open={elevationProfileModalOpen} projections={projections} />
    </>
  );
};

export const DistanceCalculation = memo(DistanceCalculation_);
