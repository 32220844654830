import { useLazyQuery } from '@apollo/client';
import { PointCloudCommandManager } from '@pointorama/pointcloud-commander';
import { groupBy, keyBy, orderBy } from 'lodash/fp';
import React, { memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnnotationContext, AnnotationContextType } from '../../contexts/AnnotationContext';
import { CommandManagerContext } from '../../contexts/CommandManagerContext';
import { PotreeSourcesContext } from '../../contexts/PotreeSourcesContext';
import { RendererContext, RendererContextType } from '../../contexts/RendererContext';
import { RendererReadOnlyContext } from '../../contexts/RendererReadOnlyContext';
import { UserContext } from '../../contexts/UserContext';
import { REQUEST_READ_SAS_TOKEN } from '../../graphql/project';
import { useDefaultPointCloudState } from '../../hooks/modules/project/useDefaultPointCloudState';
import {
  changePointCloudAppearance,
  changePointCloudGradient,
  changePointCloudResolution,
} from '../../hooks/potree/usePointCloudProperties';
import { useProject } from '../../hooks/potree/useProject';
import { EMPTY_ARRAY, ProjectionSystems } from '../../utils/constants';
import {
  Annotation,
  MeasurementUnits,
  ProjectRequestReadSasTokenQuery,
  ProjectRequestReadSasTokenQueryVariables,
  UserRole,
} from '../../types/graphqlTypes';
import { CadLayer, CadObjectContext, InitialCadLayer } from '../../contexts/CadLayersContext';
import { isNotNullOrUndefined } from '../../utils/isNotNullOrUndefined';
import { WMSLayersContext } from '../../contexts/WmsLayersContext';
import { OrthophotoLayersContext } from '../../contexts/OrthophotoLayersContext';
import { usePreviewUpload } from '../../hooks/useUpload';
import { ViewerContextProvider } from '../../contexts/ViewerContext';
import { ProjectContent } from '../../modules/project/ProjectContent';
import { useSyncCadLayers } from '../../hooks/modules/project/useSyncCadLayers';
import { useSyncProjectState } from '../../hooks/modules/project/useSyncProjectState';

const Project_: React.FC2 = () => {
  const { isLoaded: sourcesLoaded } = useContext(PotreeSourcesContext);
  const { projectId = '' } = useParams();
  const [setAndUploadPreview] = usePreviewUpload();
  const [projectRequestReadSASToken] = useLazyQuery<
    ProjectRequestReadSasTokenQuery,
    ProjectRequestReadSasTokenQueryVariables
  >(REQUEST_READ_SAS_TOKEN, { fetchPolicy: 'network-only' });
  const projectQuery = useProject();
  const currentUser = useContext(UserContext);
  const { organisationId = '' } = useParams();
  const [defaultPointCloudState] = useDefaultPointCloudState();
  const project = projectQuery.data?.projectById;
  const initialProjectCadLayers = project?.state.cadLayers || EMPTY_ARRAY;
  const [cadLayers, setCadLayers] = useState<Record<string, CadLayer | InitialCadLayer | null>>({});
  const projectionSystem = project?.settings?.projectionSystem;

  useSyncCadLayers({ projectionSystem, initialProjectCadLayers, cadLayers, setCadLayers });

  const [pointcloudLoaded, setPointcloudLoaded] = useState(false);
  const [initializedPointclouds, setInitializedPointclouds] = useState<Array<string>>([]);
  const hasProject = !!project;
  const [rendererContext, setRendererContext] = React.useState<RendererContextType>({});
  const commander = useMemo(() => new PointCloudCommandManager(), []);
  const commandManagerContext = useMemo(() => ({ commander }), [commander]);
  const { measurementUnit } = useContext(UserContext);
  const calculationsByIdentifier = useMemo(() => {
    return groupBy('annotationIdentifier', project?.calculations || []);
  }, [project?.calculations]);
  const cadLayersArray = useMemo(() => Object.values(cadLayers).filter(isNotNullOrUndefined), [cadLayers]);

  const wmsLayersContextValue = useMemo(() => {
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.wmsLayerOrderedIdentifiers);
    const wmsLayers = orderedIdentifiers
      .map(({ identifier }) => project?.state.wmsLayers?.find((layer) => layer.identifier === identifier))
      .filter(isNotNullOrUndefined);
    return { wmsLayers };
  }, [project?.state.wmsLayerOrderedIdentifiers, project?.state.wmsLayers]);

  const orthophotoLayersContextValue = useMemo(() => {
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.orthophotoLayerOrderedIdentifiers);
    const orthophotoLayers = orderedIdentifiers
      .map(({ identifier }) => project?.state.orthophotoLayers?.find((layer) => layer.identifier === identifier))
      .filter(isNotNullOrUndefined);
    return { orthophotoLayers };
  }, [project?.state.orthophotoLayerOrderedIdentifiers, project?.state.orthophotoLayers]);

  useSyncProjectState({
    project,
    calculationsByIdentifier,
    cadLayers,
    setCadLayers,
    initializedPointclouds,
    wmsLayersContextValue,
    orthophotoLayersContextValue,
    commander,
    viewer: rendererContext.viewer,
  });

  useEffect(() => {
    const viewer = rendererContext.viewer;
    if (!viewer) return;
    project?.pointClouds.forEach((pointCloud) => {
      const renderedPointCloud = viewer.scene.pointclouds.find((pc) => pc.identifier === pointCloud.id);
      if (renderedPointCloud) {
        renderedPointCloud.visible = pointCloud.visible;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.pointClouds]);

  const setPreview = useCallback(
    async ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (project?.previewUrl) return;
      const blob = viewer?.getRenderPreview();
      if (!blob) return;
      const fileName = `defaultrenderpreview.png`;
      const file = new File([blob], fileName, { type: 'image/png' });

      await setAndUploadPreview(file, projectId);
    },
    [setAndUploadPreview, projectId, project],
  );

  useLayoutEffect(() => {
    if (!hasProject || !sourcesLoaded || pointcloudLoaded) return;
    if (!project.pointClouds) return;
    console.log('start loading pointclouds');
    setPointcloudLoaded(true);

    const loadPointCloudHeaderFiles = async ({ viewer }: { viewer: any }) => {
      const SASUrl = await projectRequestReadSASToken({ variables: { projectId } });
      const SAStoken = SASUrl.data?.projectRequestReadSASToken || '';
      localStorage.setItem('SASToken_' + projectId, SAStoken);
      Potree.projectId = projectId;
      project.pointClouds.forEach((pointCloud) => {
        Potree.loadPointCloud(
          `/${projectId}/${pointCloud.cloudName}.ppch?${SAStoken}`,
          `${pointCloud.cloudName}`,
          (e) => {
            console.log(`loadPointCloudHeaderFiles:: pointcloud loaded: ${pointCloud.cloudName}`);
            if (project.settings?.projectionSystem) {
              const projection =
                ProjectionSystems[project.settings.projectionSystem as keyof typeof ProjectionSystems].parameters;
              e.pointcloud.projection = projection;
            }
            const defaultState = defaultPointCloudState[pointCloud.id] || {};
            if (defaultState.resolution !== undefined && defaultState.resolution !== null)
              changePointCloudResolution({ pointCloud: e.pointcloud, value: defaultState.resolution });
            if (defaultState.appearance)
              changePointCloudAppearance({ pointCloud: e.pointcloud, value: defaultState.appearance });
            if (defaultState.classifications)
              defaultState.classifications.forEach((classification) =>
                viewer.setClassificationVisibility({
                  key: classification.code,
                  value: classification.visible,
                  pointCloudId: pointCloud.id,
                }),
              );
            if (defaultState.gradient)
              changePointCloudGradient({ pointCloud: e.pointcloud, gradient: defaultState.gradient });
            if (defaultState.heightMax !== undefined) e.pointcloud.material.heightMax = defaultState.heightMax;
            if (defaultState.heightMin !== undefined) e.pointcloud.material.heightMin = defaultState.heightMin;
            if (defaultState.intensityMax !== undefined) e.pointcloud.material.intensityMax = defaultState.intensityMax;
            if (defaultState.intensityMin !== undefined) e.pointcloud.material.intensityMin = defaultState.intensityMin;
            viewer.scene.addPointCloud(e.pointcloud);
            const material = e.pointcloud.material;
            material.size = 1;
            material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
            viewer.fitToScreen();
            viewer.setTopView();
            if (project.settings?.projectionSystem) {
              if (project.mapVisible) {
                viewer.viewerMode.enable2DModeFromMap();
                viewer.showMap();
              }
            }
            setInitializedPointclouds((pointclouds) => pointclouds.concat(pointCloud.id));
          },
          false,
          pointCloud.id,
          pointCloud.visible,
        );
      });
    };

    const initializeViewer = () => {
      // @ts-ignore
      const viewer = new Potree.Viewer(document.getElementById('potree_render_area'), { useDefaultRenderLoop: false });
      console.log('viewer initialized');
      setRendererContext({ viewer });
      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(3 * 1000 * 1000);
      viewer.loadSettingsFromURL();
      viewer.loadGUI(() => {
        viewer.setLanguage('en');
        viewer.toggleSidebar();
      });
      if (localStorage.theme === 'dark') {
        viewer.setBackground('dark');
      } else {
        viewer.setBackground('light');
      }
      if (measurementUnit === MeasurementUnits.Feet) {
        viewer.setLengthUnitAndDisplayUnit('m', 'ft');
      } else {
        viewer.setLengthUnitAndDisplayUnit('m', 'm');
      }
      console.log('start loading header files');
      loadPointCloudHeaderFiles({ viewer });
      setTimeout(() => {
        setPreview({ viewer });
      }, 10000);
    };

    initializeViewer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectId,
    projectRequestReadSASToken,
    sourcesLoaded,
    hasProject,
    project?.settings?.projectionSystem,
    project?.mapVisible,
    setPointcloudLoaded,
    setInitializedPointclouds,
    pointcloudLoaded,
    project?.pointClouds,
  ]);

  const annotationsByIdentifier = useMemo(
    () => keyBy('identifier', project?.state?.annotations || []),
    [project?.state?.annotations],
  );
  const annotations = useMemo(() => {
    const annotationsByGroupId = groupBy('groupIdentifier', project?.state.annotations);
    const groupsById = keyBy('identifier', project?.state.groups);
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.orderedIdentifiers);
    const annotations: AnnotationContextType['annotations'] = orderedIdentifiers.reduce(
      (result, orderInfo) => {
        const annotation = annotationsByIdentifier[orderInfo.identifier];
        if ((annotation as Annotation)?.groupIdentifier) return result;
        const group = groupsById[orderInfo.identifier];
        if (group) {
          const annotations = annotationsByGroupId[group.identifier] || [];
          const annotationsById = keyBy('identifier', annotations);
          const orderedIdentifiers = project?.state.orderedIdentifiers.filter(
            ({ identifier }) => !!annotationsById[identifier],
          );
          return [
            ...result,
            {
              ...group,
              annotations: orderBy('index', 'asc', orderedIdentifiers).map(
                (orderInfo) => annotationsById[orderInfo.identifier],
              ),
            },
          ];
        }
        return [...result, annotation];
      },
      [] as AnnotationContextType['annotations'],
    );
    return annotations;
  }, [annotationsByIdentifier, project?.state.annotations, project?.state.groups, project?.state.orderedIdentifiers]);

  const annotationContextValue = useMemo(() => {
    return { annotations, annotationsByIdentifier, calculationsByIdentifier };
  }, [annotations, annotationsByIdentifier, calculationsByIdentifier]);

  const cadLayersContextValue = useMemo(() => {
    return { cadLayers: cadLayersArray.slice() };
  }, [cadLayersArray]);

  const readOnly =
    !currentUser.isSuperAdmin &&
    currentUser.rolesByOrganisation.find((value) => value.organisationId === organisationId)?.role === UserRole.Guest;

  return (
    <RendererReadOnlyContext.Provider value={readOnly}>
      <RendererContext.Provider value={rendererContext}>
        <ViewerContextProvider project={project}>
          <CommandManagerContext.Provider value={commandManagerContext}>
            <AnnotationContext.Provider value={annotationContextValue}>
              <CadObjectContext.Provider value={cadLayersContextValue}>
                <WMSLayersContext.Provider value={wmsLayersContextValue}>
                  <OrthophotoLayersContext.Provider value={orthophotoLayersContextValue}>
                    <ProjectContent project={project} />
                  </OrthophotoLayersContext.Provider>
                </WMSLayersContext.Provider>
              </CadObjectContext.Provider>
            </AnnotationContext.Provider>
          </CommandManagerContext.Provider>
        </ViewerContextProvider>
      </RendererContext.Provider>
    </RendererReadOnlyContext.Provider>
  );
};

export const Project = memo(Project_);
