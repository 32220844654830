import { memo, useCallback, useContext } from 'react';
import { Annotation } from '../../../../types/graphqlTypes';
import { ViewerContext } from '../../../../contexts/ViewerContext';
import OrthoviewIcon from '../../../../assets/icons/orthoview.svg?react';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';
import { WMSLayersContext } from '../../../../contexts/WmsLayersContext';

const AnnotationsOrthoviewIcon_: React.FC2<{
  annotation?: Pick<Annotation, 'identifier' | 'groupIdentifier' | 'name'> & {
    type?: Annotation['type'];
    visible?: boolean;
  };
}> = ({ annotation }) => {
  const { in2DMode, planeId, toggle2DMode } = useContext(ViewerContext);
  const { toggleHide } = useToggleHide();
  const wmsLayersContextValue = useContext(WMSLayersContext);
  const wmsLayers = wmsLayersContextValue.wmsLayers;

  const onToggleOrthoview = useCallback(() => {
    if (in2DMode) {
      toggleHide({ identifiers: ['map'].concat(wmsLayers.map((layer) => layer.identifier)), visible: false });
    }
    if (!annotation) {
      toggle2DMode('map');
      return;
    }
    if (annotation.type === 'PLANE' || annotation.type === 'DISTANCE') {
      toggle2DMode(annotation.identifier);
    }
  }, [annotation, toggle2DMode, in2DMode, toggleHide, wmsLayers]);

  const identifier = annotation ? annotation.identifier : 'map';

  return (
    <div
      onClick={onToggleOrthoview}
      className="flex items-center justify-center w-6 h-6 text-[#6F7173] rounded-sm cursor-pointer hover:text-black dark:hover:text-neon-green-300"
    >
      {identifier === planeId ? (
        <OrthoviewIcon className="w-4 h-4 text-black dark:text-neon-green-300" />
      ) : (
        <OrthoviewIcon className="w-4 h-4" />
      )}
    </div>
  );
};
export const AnnotationsOrthoviewIcon = memo(AnnotationsOrthoviewIcon_);
