import classNames from 'classnames';
import { memo } from 'react';

interface ButtonToggleProps<Value = any> {
  options: { label: React.ReactNode; value: Value }[];
  onChange: (value: Value) => void;
  value: Value;
}
const ButtonToggle_: React.FC2<ButtonToggleProps> = ({ options, onChange, value: propsValue }) => {
  return (
    <div className="flex">
      {options.map(({ label, value }, index) => (
        <div key={index}>
          <div
            className={classNames(
              'cursor-pointer px-3 py-2 hover:bg-neon-green-100 border-2 border-gray-300 dark:bg-[#313131] dark:hover:bg-black dark:text-white',
              index === 0 && 'rounded-l-lg',
              index === options.length - 1 && 'rounded-r-lg',
              propsValue === value
                ? 'bg-neon-green-50 text-neon-green-900 border-neon-green-600 '
                : 'text-[#58595B] bg-white',
            )}
            onClick={() => onChange(value)}
          >
            {label}
          </div>
        </div>
      ))}
    </div>
  );
};

export const ButtonToggle = memo(ButtonToggle_);
