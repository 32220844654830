import { useMutation } from '@apollo/client';
import { Button } from '../../../components/Button';
import { InfoContent } from '../../../components/InfoContent';
import { Modal } from '../../../components/Modal';
import { PROJECT, PROJECT_ACTIVATE } from '../../../graphql/project';
import { T } from '../../../translation/src';
import { Project, ProjectActivateMutation, ProjectActivateMutationVariables } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useCurrentOrganisation } from '../../../hooks/modules/organisation/useCurrentOrganisation';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';

interface ProjectSuspendedModalProps {
  onClose: () => void;
  open?: boolean;
  project: Pick<Project, 'id' | 'name'> & { pointClouds: Array<{ pointCount?: number | null }> };
}
const ProjectSuspendedModal_: React.FC2<ProjectSuspendedModalProps> = ({ onClose, open, project }) => {
  const [activateProject] = useMutation<ProjectActivateMutation, ProjectActivateMutationVariables>(PROJECT_ACTIVATE);
  const { currentOrganisation: organisation } = useCurrentOrganisation();
  const { formatNumber } = useFormatNumber();

  const onOpenProject = useCallback(() => {
    if (!project) return;
    activateProject({
      variables: { projectId: project?.id },
      onCompleted: onClose,
      refetchQueries: [{ query: PROJECT, variables: { projectId: project.id } }],
    });
  }, [activateProject, onClose, project]);

  const pointsNeeded = project.pointClouds.reduce((sum, pointCloud) => {
    return sum + (pointCloud.pointCount || 0);
  }, 0);

  const canAddPointClouds = organisation?.subscription.canAddPointClouds;

  return (
    <Modal.Container onClose={onClose} open={open} className={'flex flex-col w-full h-full'}>
      <Modal.Header>
        <T _str="Unable to open {name}" args={{ name: project.name }} />
      </Modal.Header>
      <Modal.Body>
        {' '}
        <InfoContent
          type="warning"
          title={<T _str="Your project is being suspended" swc />}
          description={
            <T
              _str="This project contains {pointsNeeded} points. {optionalLine}"
              args={{
                pointsNeeded: formatNumber(pointsNeeded, { convertType: ConvertType.NONE, addUnit: false }),
                optionalLine: canAddPointClouds
                  ? ''
                  : 'Upgrade your plan or remove other point clouds to activate this project.',
              }}
            />
          }
        />
      </Modal.Body>
      <Modal.Footer className="justify-between space-x-4">
        <Button variant="primary" onClick={onOpenProject} disabled={!canAddPointClouds}>
          <T _str="activate this project" />
        </Button>
        <Button variant="secondary" onClick={onClose}>
          <T _str="cancel" />
        </Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export const ProjectSuspendedModal = memo(ProjectSuspendedModal_);
