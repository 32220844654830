import { memo } from 'react';
import { usePointCloudDrop, usePointCloudDropWithModals } from '../../hooks/modules/project/usePointCloudDrop';
import { T } from '../../translation/src';
import classNames from 'classnames';

const UploadCard_: React.FC<{ isPythagorasFile?: boolean; isSuperAdmin?: boolean }> = ({
  isPythagorasFile,
  isSuperAdmin,
}) => {
  const [{ modals: uploadModals }, { onNewFiles }] = usePointCloudDropWithModals({ isPythagorasFile });
  const [{ maxFiles }, { getRootProps, getInputProps }] = usePointCloudDrop({ isPythagorasFile, onNewFiles });

  return (
    <>
      <div
        className={classNames(
          'flex items-center justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md ',
          isSuperAdmin ? 'border-error-red-500' : 'border-gray-300 dark:border-gray-400',
        )}
        {...getRootProps()}
      >
        <div className="space-y-1 text-center">
          <svg
            className="w-12 h-12 mx-auto text-gray-400 dark:text-gray-300"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex flex-col text-sm text-gray-600 dark:text-gray-400">
            <div className="relative font-medium text-indigo-600 rounded-md cursor-pointer focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500 dark:text-neon-green-400 dark:focus-within:ring-neon-green-400 dark:hover:text-neon-green-500">
              <span>
                {isPythagorasFile ? <T _str="Upload pythagoras files" /> : <T _str="Upload pointcloud files" />}
              </span>
              <input
                type="file"
                className="sr-only"
                {...getInputProps()}
                accept={isPythagorasFile ? '.zip' : '.las,.pts,.laz,.e57'}
              />
            </div>
            <p className="pl-1">
              <T _str="or drag and drop" />
            </p>
          </div>
          <p className="text-xs text-gray-500">
            {isPythagorasFile ? <T _str="ZIP files only" /> : <T _str="LAS, PTS, LAZ, E57" />}
          </p>
          {!isPythagorasFile && (
            <p className="text-xs text-gray-500">
              <T _str="Max files: " />
              {maxFiles}
            </p>
          )}
        </div>
      </div>
      {uploadModals}
    </>
  );
};
export const UploadCard = memo(UploadCard_);
