export type NotNull<T> = T extends null ? never : T;
export type NotUndefined<T> = T extends undefined ? never : T;
export type NotNullOrUndefined<T> = NotNull<NotUndefined<T>>;

/**
 *
 * @param input
 */
export function isNotNull<T>(input: T): input is NotNull<T> {
  return input !== null;
}

/**
 *
 * @param value
 */
export function isNotUndefined<T>(value: T): value is NotUndefined<T> {
  return value !== undefined;
}

/**
 *
 * @param value
 */
export function isNotNullOrUndefined<T>(value: T): value is NotNullOrUndefined<T> {
  return value != null;
}

export function isNullOrEmpty(value: Array<unknown> | null | undefined): value is null | undefined | [];
export function isNullOrEmpty(value: string | null | undefined): value is null | undefined | '';

/**
 *
 * @param value
 */
export function isNullOrEmpty(value: Array<unknown> | string | null | undefined): value is null | undefined | '' | [] {
  return value == null || value.length === 0;
}
