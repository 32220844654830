import { Menu, Transition } from '@headlessui/react';
import { BellIcon, ChevronDownIcon, CogIcon, GlobeAltIcon, UserIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment, memo, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '../../../assets/icons/logout.svg?react';
import { SelectItem } from '../../../components/selects/SelectItem';
import { UserContext } from '../../../contexts/UserContext';
import { T } from '../../../translation/src';
import { useNotificationsQuery } from '../../../types/graphqlTypes';
import { Icon } from '../../../components/Icon';
import useOpen from '../../../hooks/useOpen';
import { UserSettingModal } from '../UserSettingsModal';
import { NotificationsModal } from '../NotificationsModal';

interface Props {
  variant: 'navigation' | 'header';
  forceReload: boolean;
  size: 'small' | 'large';
  onlyIcon?: boolean;
}

const NotificationBadge: React.FC<{ count: number; className?: string }> = ({ count, className }) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center w-6 h-6 font-normal text-white bg-red-500 rounded-full',
        count >= 10 ? 'text-xs' : 'text-sm',
        className,
      )}
    >
      {count >= 10 ? '9+' : count}
    </div>
  );
};

const AccountSelect_: React.FC2<Props> = ({ variant, forceReload, size, onlyIcon }) => {
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();
  const { data: notificationsData } = useNotificationsQuery();
  const unReadNotificationsCount =
    notificationsData?.notifications?.filter((notification) => !notification.isRead)?.length || 0;
  const { onOpen: openUserSettingsModal, onClose: closeUserSettingsModal, open: UserSettingsModalOpen } = useOpen();
  const { onOpen: openNotificationsModal, onClose: closeNotificationsModal, open: NotificationsModalOpen } = useOpen();

  const onLogout = useCallback(async () => {
    if (forceReload) {
      window.location.href = `${window.location.origin}/logout`;
    } else {
      navigate('/logout');
    }
  }, [forceReload, navigate]);

  const onNavigate = useCallback(
    (path: string) => {
      if (forceReload) {
        window.location.href = `${window.location.origin}${path}`;
      } else {
        navigate(path);
      }
    },
    [forceReload, navigate],
  );

  const onNavigateToProfile = useCallback(() => {
    onNavigate('/me/profile');
  }, [onNavigate]);
  const onNavigateAdminCenter = useCallback(() => onNavigate(`/admin-center`), [onNavigate]);

  const divClassNamesByType = {
    navigation: 'w-full',
    header: 'w-auto',
  };

  const MenuClassNamesByType = {
    navigation: '-translate-y-full',
    header: 'translate-y-16 -translate-x-24',
  };

  const smallElement = size === 'small';

  return (
    <div className={classNames(divClassNamesByType[variant], 'relative')}>
      <Menu as="div" className="relative z-50 inline-block w-full">
        <div>
          <Menu.Button
            className={classNames(
              'inline-flex items-center justify-between w-full py-2 text-sm font-medium group text-eerie-black dark:text-white hover:bg-neon-green-300 dark:hover:bg-black focus:outline-none h-14 rounded',
              smallElement ? 'px-2' : 'px-4',
            )}
          >
            <div className="relative flex items-start justify-start">
              {unReadNotificationsCount ? (
                <NotificationBadge
                  count={unReadNotificationsCount}
                  className="absolute left-0 transform -translate-y-1/2 top-1/2"
                />
              ) : null}
              {smallElement || onlyIcon ? (
                <Icon icon={UserIcon} className={unReadNotificationsCount ? 'w-5 h-5 ml-6' : 'w-5 h-5 ml-4'} />
              ) : (
                <div className={unReadNotificationsCount ? 'ml-8' : ''}>{currentUser.name}</div>
              )}
            </div>
            <ChevronDownIcon
              className={classNames(
                'w-5 h-5 -mr-1 text-eerie-black dark:group-hover:text-white dark:text-neon-green-300',
                smallElement ? 'ml-1' : 'ml-2',
              )}
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'absolute left-3 origin-top-right bg-white dark:bg-[#242424] dark:text-white divide-y divide-black rounded-md shadow-lg -top-2 ring-1 ring-gray-400 focus:outline-none',
              onlyIcon ? 'w-40' : 'w-64',
              MenuClassNamesByType[variant],
            )}
          >
            <div className="px-1 py-1">
              <SelectItem icon={BellIcon} onClick={openNotificationsModal}>
                <span className="relative">
                  <T _str="notifications" swc />{' '}
                  {unReadNotificationsCount ? (
                    <NotificationBadge
                      count={unReadNotificationsCount}
                      className="absolute transform -translate-y-1/2 -right-8 top-1/2"
                    />
                  ) : null}
                </span>
              </SelectItem>
              {currentUser.isSuperAdmin && (
                <SelectItem
                  icon={GlobeAltIcon}
                  onClick={onNavigateAdminCenter}
                  className="text-black bg-error-red-500 dark:hover:text-white"
                >
                  <T _str="admin center" swc />
                </SelectItem>
              )}
              <SelectItem icon={UserIcon} onClick={onNavigateToProfile}>
                <T _str="profile" swc />
              </SelectItem>
              <SelectItem icon={CogIcon} onClick={openUserSettingsModal}>
                <T _str="settings" swc />
              </SelectItem>
              <SelectItem icon={LogoutIcon} onClick={onLogout}>
                <T _str="logout" swc />
              </SelectItem>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <UserSettingModal onClose={closeUserSettingsModal} open={UserSettingsModalOpen} />
      <NotificationsModal onClose={closeNotificationsModal} open={NotificationsModalOpen} />
    </div>
  );
};

export const AccountSelect = memo(AccountSelect_);
