import classNames from 'classnames';
import React, { memo } from 'react';

export const getInputClassName = ({ disabled, className }: { disabled?: boolean; className?: string }) => {
  return classNames(
    'text-black dark:text-white w-full h-10 block rounded-md transition bg-white shadow-sm dark:bg-[#313131] dark:focus:ring-0',
    disabled && 'bg-[#A5ACB0] cursor-not-allowed',
    !className?.includes('border-error') && 'dark:border-gray-300 border-[#C2C6C8] dark:focus:border-neon-green-300',
    className,
  );
};

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const Input_: React.FC<InputProps> = ({ className, disabled, ...props }) => {
  return <input type="text" className={getInputClassName({ disabled, className })} {...props} />;
};

export const Input = memo(Input_) as typeof Input_;
