import { memo, useCallback } from 'react';
import { T } from '../../../translation/src';
import { ClipMethods, Plane, useProjectUpdateClippingMethodMutation } from '../../../types/graphqlTypes';
import { PropertiesSection, Property } from './PropertiesSection';
import { PositionEditProperties } from './PositionEditProperties';
import { PlaneAnglesEditProperties } from './PlaneAngleEditProperties';
import { ClipMethodProperty } from './ClipMethodProperties';
import { SelectProps } from '../../../components/inputs/Select';
import { Input } from '../../../components/inputs/Input';
import { useConvertedMeasurementInputProps } from '../../../hooks/useConvertedMeasurementInputProps';
import { usePlaneProperties } from '../../../hooks/potree/usePlaneProperties';

interface PlanePropertiesProps {
  projectId: string;
  plane: Plane;
}
const PlaneProperties_: React.FC2<PlanePropertiesProps> = ({ projectId, plane }) => {
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const convertMeasurementInputProps = useConvertedMeasurementInputProps();
  const clipMethod = plane.annotationFilter?.clipMethod || ClipMethods.None;
  const [{ tolerance }, { onEditTolerance }] = usePlaneProperties(projectId);

  const onChangeClippingMethod: SelectProps<ClipMethods>['onChange'] = useCallback(
    (event) => {
      updateClippingMethod({
        variables: { projectId, annotationIdentifiers: [plane.identifier], clipMethod: event.target.value },
      });
    },
    [updateClippingMethod, projectId, plane.identifier],
  );

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="plane" swc />
        </Property>
      </PropertiesSection>

      <PositionEditProperties identifier={plane.identifier} />
      <PlaneAnglesEditProperties identifier={plane.identifier}></PlaneAnglesEditProperties>

      <PropertiesSection title={<T _str="filters" swc />}>
        <ClipMethodProperty onChange={onChangeClippingMethod} clipMethod={clipMethod}></ClipMethodProperty>
        <Property title={<T _str="tolerance" swc />}>
          <Input
            key={plane.identifier.concat('Tolerance')}
            id="inputTolerance"
            onChange={onEditTolerance}
            min={0}
            {...convertMeasurementInputProps({ defaultValue: tolerance || 0.01 })}
          ></Input>
        </Property>
      </PropertiesSection>
    </div>
  );
};
export const PlaneProperties = memo(PlaneProperties_);
