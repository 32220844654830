import { useMutation } from '@apollo/client';
import { FormikPointCloudFileInput } from '../../../components/formik/FormFileInput';
import { FormikInput } from '../../../components/formik/FormInput';
import { FormikTextArea } from '../../../components/formik/FormTextArea';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { PROJECTS, PROJECT_DUPLICATE } from '../../../graphql/project';
import { T, useT } from '../../../translation/src';
import { Project, ProjectDuplicateMutation, ProjectDuplicateMutationVariables } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { FormikConfig } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useValidationSchema } from '../ProjectModal';

interface FormValues {
  name: string;
  description: string;
  files?: FileList;
}

const useTranslations = () => {
  const namePlaceholder = useT('enter a project name', { swc: true });
  const descriptionPlaceholder = useT('enter a project description', { swc: true });
  const filePlaceholder = useT('choose file', { swc: true });
  return { namePlaceholder, descriptionPlaceholder, filePlaceholder };
};

interface ProjectDuplicateModalProps {
  onClose: () => void;
  open?: boolean;
  project: Pick<Project, 'id' | 'name' | 'description'>;
}
const ProjectDuplicateModal_: React.FC2<ProjectDuplicateModalProps> = ({ onClose, open, project }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const [duplicateProject, { loading: isSubmitting }] = useMutation<
    ProjectDuplicateMutation,
    ProjectDuplicateMutationVariables
  >(PROJECT_DUPLICATE);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values) => {
      duplicateProject({
        variables: { projectId: project?.id, ...values },
        onCompleted: onClose,
        refetchQueries: [{ query: PROJECTS, variables: { organisationId } }],
        awaitRefetchQueries: true,
      });
    },
    [duplicateProject, onClose, organisationId, project],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { name: (project?.name || '') + ' (copy)', description: project?.description || '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema, project],
  );

  return (
    <CreateModal
      title={<T _str="duplicate '{name}'" args={{ name: project.name }} swc />}
      createButtonTitle={<T _str="duplicate project" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
    >
      <div className={classNames('grid grid-cols-1 gap-6 mb-6', !project && 'mt-6')}>
        <FormikInput autoFocus name="name" label={<T _str="name" swc />} placeholder={translations.namePlaceholder} />
        <FormikTextArea
          name="description"
          label={<T _str="description (optional)" swc />}
          placeholder={translations.descriptionPlaceholder}
        />
        {!project && <FormikPointCloudFileInput name="files" />}
      </div>
    </CreateModal>
  );
};

export const ProjectDuplicateModal = memo(ProjectDuplicateModal_);
