import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { EarthLoader } from '../../../components/loaders/EarthLoader';
import { usePotreeEvents } from '../../../hooks/potree/usePotreeEvents';
import { useRealtimeUpdates } from '../../../hooks/potree/useRealtimeUpdates';
import { useRendererShortcuts } from '../../../hooks/potree/useRendererShortcuts';
import { RendererHeader } from '../../../modules/renderer/RendererHeader';
import { RendererLeftPanel } from '../../../modules/renderer/RendererLeftPanel';
import { RendererRightPanel } from '../../../modules/renderer/RendererRightPanel';
import { MapTool, ScreenTools } from '../../../modules/renderer/ScreenTools';
import { ProjectByIdQuery } from '../../../types/graphqlTypes';
import { useLocalStorage } from 'usehooks-ts';
import useOpen from '../../../hooks/useOpen';
import { useDeviceSize } from '../../../hooks/useDeviceSize';

const ProjectContent_: React.FC2<{ project?: ProjectByIdQuery['projectById'] }> = ({ project }) => {
  usePotreeEvents({ project });
  useRealtimeUpdates();
  useRendererShortcuts();

  useEffect(() => {
    const onTouchEnd = (e: TouchEvent) => {
      e.target?.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          clientX: e.changedTouches[0].clientX,
          clientY: e.changedTouches[0].clientY,
        }),
      );
      e.preventDefault();
    };
    // replace default touchEnd behavior with mouse click. (bugfix: see PK-254)
    window.addEventListener('touchend', onTouchEnd);
    return () => {
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, []);

  const showOldSideBar = localStorage.getItem('showOldSideBar') === 'true';

  const rendererHeaderProps = useMemo(
    () =>
      project?.id && {
        project: {
          id: project?.id,
          name: project?.name,
          description: project.description,
          previewUrl: project.previewUrl,
        },
      },
    [project?.id, project?.name, project?.description, project?.previewUrl],
  );

  const allPanelsTogether = false; // email === 'jonathan@softvalla.com';

  const { mdDevice } = useDeviceSize();

  const [leftPanelOpenStorage, setLeftPanelOpenStorage] = useLocalStorage('left-panel-open', !mdDevice);
  const { onClose: closeLeftPanel, onOpen: openLeftPanel, open: leftPanelOpen } = useOpen(leftPanelOpenStorage);

  useEffect(() => {
    setLeftPanelOpenStorage(leftPanelOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPanelOpen]);

  const [rightPanelOpenStorage, setRightPanelOpenStorage] = useLocalStorage('right-panel-open', !mdDevice);
  const { onClose: closeRightPanel, onOpen: openRightPanel, open: rightPanelOpen } = useOpen(rightPanelOpenStorage);

  useEffect(() => {
    setRightPanelOpenStorage(rightPanelOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelOpen]);

  const onOpenLeftPanel = useCallback(() => {
    if (leftPanelOpen) return;
    openLeftPanel();
    if (mdDevice) closeRightPanel();
  }, [closeRightPanel, leftPanelOpen, mdDevice, openLeftPanel]);

  const onOpenRightPanel = useCallback(() => {
    if (rightPanelOpen) return;
    openRightPanel();
    if (mdDevice) closeLeftPanel();
  }, [closeLeftPanel, mdDevice, openRightPanel, rightPanelOpen]);

  useEffect(() => {
    if (!mdDevice) return;
    if (leftPanelOpen && rightPanelOpen) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdDevice]);

  const hideLeftArrow = mdDevice && rightPanelOpenStorage;
  const hideRightArrow = mdDevice && leftPanelOpenStorage;

  return (
    <div className="flex flex-col w-full h-full bg-white dark:bg-[#313131] dark:text-white">
      {<EarthLoader />}
      {rendererHeaderProps && <RendererHeader {...rendererHeaderProps} minimize={mdDevice} />}
      <div className="flex w-full h-full overflow-auto">
        {!allPanelsTogether && (
          <RendererLeftPanel
            project={project}
            closePanel={closeLeftPanel}
            openPanel={onOpenLeftPanel}
            panelOpen={leftPanelOpen}
            hideArrow={hideLeftArrow}
          />
        )}
        <div className="relative w-full h-full">
          <div className="absolute top-0 left-0 w-full h-full potree_container">
            <div id="potree_render_area">
              <div id="olContainer" className="absolute top-0 left-0 w-full h-full bg-neon-green-300" />
            </div>
            {showOldSideBar && <div id="potree_sidebar_container"></div>}
          </div>
          <MapTool className="absolute bottom-4 left-4" project={project} />
          <ScreenTools className="absolute transform -translate-x-1/2 bottom-4 left-1/2" project={project} />
        </div>
        {!allPanelsTogether ? (
          <RendererRightPanel
            project={project}
            closePanel={closeRightPanel}
            openPanel={onOpenRightPanel}
            panelOpen={rightPanelOpen}
            hideArrow={hideRightArrow}
          />
        ) : (
          <div className="flex flex-col flex-shrink-0 h-full w-96">
            <div className="overflow-auto h-1/2">
              <RendererLeftPanel
                project={project}
                closePanel={closeLeftPanel}
                openPanel={onOpenLeftPanel}
                panelOpen={leftPanelOpen}
                hideArrow={hideLeftArrow}
              />
            </div>
            <div className="overflow-auto h-1/2">
              <RendererRightPanel
                project={project}
                closePanel={closeRightPanel}
                openPanel={onOpenRightPanel}
                panelOpen={rightPanelOpen}
                hideArrow={hideRightArrow}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const ProjectContent = memo(ProjectContent_);
