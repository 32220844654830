import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  usePointCloudUploadStatusLazyQuery,
  useProjectCalculationsLazyQuery,
  useProjectExportsLazyQuery,
} from '../../types/graphqlTypes';
import { pusher } from '../pusher';
import { useDownloadExport } from '../../hooks/useDownloadExport';

export const useRealtimeUpdates = () => {
  const { projectId = '' } = useParams();
  const [fetchProjectCalculations] = useProjectCalculationsLazyQuery();
  const [fetchProject] = usePointCloudUploadStatusLazyQuery();
  const [fetchExports] = useProjectExportsLazyQuery();
  const { downloadExport } = useDownloadExport();

  useEffect(() => {
    const channel = pusher.subscribe(`project-${projectId}`);
    channel.bind('calculation-done', async () => {
      await fetchProjectCalculations({ variables: { projectId }, fetchPolicy: 'network-only' });
    });
    channel.bind('convert-done', async () => {
      await fetchProject({ variables: { projectId }, fetchPolicy: 'network-only' });
    });
    channel.bind('export-done', async ({ exportId }: { exportId: string }) => {
      await fetchExports({ variables: { projectId }, fetchPolicy: 'network-only' });
      downloadExport({ projectId, exportId });
    });
    return () => {
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
};
