import { InfoContent } from '../../../components/InfoContent';
import { SubmitModal } from '../../../components/Modal/SubmitModal';
import { T } from '../../../translation/src';
import { Calculation, useProjectDeleteCalculationMutation } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

interface ProjectDeleteCalculationModalProps {
  onClose: () => void;
  open?: boolean;
  calculations?: Pick<Calculation, 'id'>[];
}
const ProjectDeleteCalculationModal_: React.FC2<ProjectDeleteCalculationModalProps> = ({
  onClose,
  open,
  calculations,
}) => {
  const { projectId = '' } = useParams();
  const [deleteCalculation, { loading: isSubmitting }] = useProjectDeleteCalculationMutation();

  const onSubmit = useCallback(() => {
    if (!calculations) return;
    calculations.forEach((calculation) => {
      deleteCalculation({
        variables: { projectId, calculationId: calculation?.id },
        onCompleted: onClose,
      });
    });
  }, [deleteCalculation, onClose, calculations, projectId]);

  return (
    <SubmitModal
      title={<T _str="delete calculation" swc />}
      actionButtonTitle={<T _str="delete calculation" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={
          <T _str="Deleting this calculation will delete it for all users. Are you sure you want to delete?" />
        }
      />
    </SubmitModal>
  );
};

export const ProjectDeleteCalculationModal = memo(ProjectDeleteCalculationModal_);
