import { memo, useMemo } from 'react';

interface BadgeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type: 'warning';
  size?: 's' | 'm';
}
const Badge_: React.FC2<BadgeProps> = ({ className: propsClassName, type, size = 'm', ...props }) => {
  const className = useMemo(() => {
    let className = 'flex items-center justify-center rounded-full ' + propsClassName;
    if (size === 's') className += ' w-4 h-4';
    if (size === 'm') className += ' w-6 h-6';
    if (type === 'warning') className += ' bg-warning-yellow-500 text-white';
    return className;
  }, [type, propsClassName, size]);

  const content = useMemo(() => {
    if (type === 'warning') return <div>!</div>;
    return null;
  }, [type]);

  return (
    <div className={className} {...props}>
      {content}
    </div>
  );
};

export const Badge = memo(Badge_);
