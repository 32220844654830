export const POINTORAMA_CONTACT_URL = 'https://www.pointorama.com/contact/';

export const EMPTY_ARRAY = [];

export const ProjectionSystems = {
  BelgianLamber72: {
    parameters:
      '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs',
    epsgCode: 31370,
  },
  'RD/NL': {
    // These parameters are from chatGpt. They are not the same as the ones from epsg.io, but they work for the only example we have available at the moment.
    parameters:
      '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.331,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs +type=crs',
    epsgCode: 28992,
  },
  ...Array.from(new Array(60))
    .map((_, i) => {
      return {
        parameters: `+proj=utm +zone=${i + 1} +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`,
        epsgCode: 32600 + i + 1,
      };
    })
    .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}`]: cur }), {} as Record<string, object>),
  ...Array.from(new Array(60))
    .map((_, i) => {
      return {
        parameters: `+proj=utm +zone=${i + 1} +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`,
        epsgCode: 32700 + i + 1,
      };
    })
    .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}S`]: cur }), {} as Record<string, object>),
};

export const ProjectionSystemsByEpsgCode: Record<number, string> = Object.fromEntries(
  Object.entries(ProjectionSystems).map(([key, value]) => [value.epsgCode, key]),
);
