import { FormikConfig, FormikHelpers, useField } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikCheckBox } from '../../../components/formik/FormCheckBox';
import { FormikPointCloudFileInput } from '../../../components/formik/FormFileInput';
import { ProgressBar } from '../../../components/loaders/ProgressBar';
import { useMaxFiles } from '../../../hooks/modules/project/usePointCloudDrop';
import { usePointCloudUpload } from '../../../hooks/useUpload';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import { useProjectByIdLazyQuery } from '../../../types/graphqlTypes';

const useTranslations = () => {
  const mergePointClouds = useT('merge pointclouds', { swc: true });
  return { mergePointClouds };
};

export const useValidationSchema = () => {
  const translations = useValidationTranslations();
  const maxFiles = useMaxFiles();
  const maxFilesError = useT('must be at most {max} files', { swc: true, args: { max: maxFiles } });

  const schema = useMemo(
    () =>
      yup.object().shape({
        files: yup
          .mixed()
          .required(translations.isRequired)
          .test('maxFiles', maxFilesError, (value) => !!value && (value as FileList).length <= maxFiles),
      }),
    [maxFiles, maxFilesError, translations.isRequired],
  );

  return schema;
};

const ShouldMergeCheckBox_: React.FC2<{ className?: string }> = ({ className }) => {
  const [{ value: files }] = useField('files');
  const translations = useTranslations();
  return (
    <FormikCheckBox
      name="shouldMerge"
      label={translations.mergePointClouds}
      disabled={(files?.length || 0) < 2}
      className={'mt-4'}
    />
  );
};
const ShouldMergeCheckBox = memo(ShouldMergeCheckBox_);

interface FormValues {
  files?: FileList;
  shouldMerge: boolean;
}

interface UploadPointCloudModalProps {
  onClose: () => void;
  open?: boolean;
}
const UploadPointCloudModal_: React.FC2<UploadPointCloudModalProps> = ({ onClose, open }) => {
  const { projectId = '' } = useParams();
  const validationSchema = useValidationSchema();
  const [{ progress }, { uploadFiles: uploadPointCloudFiles, resetProgress, onCancelUpload }] = usePointCloudUpload();
  const [fetchProject] = useProjectByIdLazyQuery();
  const [loading, setLoading] = useState(false);

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      resetProgress();
      // Because of transition
      setTimeout(() => {
        resetForm();
        setLoading(false);
      }, 250);
    },
    [onClose, resetProgress],
  );

  const onCancel = useCallback(() => {
    onCancelUpload();
    onClose();
  }, [onClose, onCancelUpload]);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ files, shouldMerge }, helpers) => {
      setLoading(true);
      await uploadPointCloudFiles({ files: files ? Array.from(files) : [], projectId, shouldMerge });
      await fetchProject({ variables: { projectId }, fetchPolicy: 'network-only' });
      onSuccess({ helpers });
    },
    [uploadPointCloudFiles, projectId, fetchProject, onSuccess],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: {
        files: undefined,
        shouldMerge: false,
      },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={<T _str="upload new point cloud(s)" swc />}
      createButtonTitle={<T _str="upload" swc />}
      formik={formik}
      onCancel={onCancel}
      open={open}
      isSubmitting={loading}
      alwaysCancellable
    >
      <FormikPointCloudFileInput name="files" multiple />
      <ShouldMergeCheckBox />
      {progress ? <ProgressBar progress={progress} /> : null}
    </CreateModal>
  );
};

export const UploadPointCloudModal = memo(UploadPointCloudModal_);
