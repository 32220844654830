import { keyBy } from 'lodash/fp';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ClassificationByCode } from '../../../public/potree/potree/materials/Classifications';
import { InputProps } from '../../components/inputs/Input';
import { RendererContext } from '../../contexts/RendererContext';
import { useCustomSelector } from '../../redux/store';
import { useT } from '../../translation/src';
import { PointCloud } from '../../types/graphqlTypes';
import { useDefaultPointCloudState } from '../modules/project/useDefaultPointCloudState';
import { useCondFeetToMeter } from '../useFormatNumber';
import { useRerender } from '../useRerender';
import { useClassifications } from './useRenderer';

export const changePointCloudResolution = ({ pointCloud, value }: { pointCloud: any; value: number }) => {
  if (!pointCloud || !pointCloud?.material) return;
  pointCloud.downSamplingLevel = value;

  const chunks = [...pointCloud.pcoGeometry.dataChunkCache.values()].filter(
    (chunk: any) => chunk.level === value + DATA_DEPTH,
  );
  pointCloud.resolvedPoints = chunks.reduce((sum, chunk) => {
    return sum + chunk.pointsAmount;
  }, 0);
};

export const useAppearanceTypeTranslations = (): { [key in EnumAppearanceTypes]: string } => {
  const rgba = useT('rgb', { uppercase: true });
  const classification = useT('classification', { swc: true });
  const height = useT('height', { swc: true });
  const intensity = useT('intensity', { swc: true });
  return { rgba, classification, height, 'intensity gradient': intensity };
};

export enum EnumAppearanceTypes {
  RGBA = 'rgba',
  CLASSIFICATION = 'classification',
  HEIGHT = 'height',
  INTENSITY = 'intensity gradient',
}

export const DATA_DEPTH = 7;
export const MAX_DEPTH = 21;

export const changePointCloudAppearance = ({ pointCloud, value }: { pointCloud: any; value: EnumAppearanceTypes }) => {
  if (!pointCloud || !pointCloud?.material) return;
  pointCloud.material.activeAttributeName = value;

  const attribute = pointCloud.getAttribute(value);

  const isIntensity = attribute ? ['intensity', 'intensity gradient'].includes(attribute.name) : false;

  if (isIntensity) {
    if (pointCloud.material.intensityRange[0] === 0 && pointCloud.material.intensityRange[1] === 0) {
      pointCloud.material.intensityRange = attribute.range;
    }
  }
};

export const changePointCloudGradient = ({ pointCloud, gradient }: { pointCloud: any; gradient: string }) => {
  if (!pointCloud) return;
  pointCloud.material.gradient = Potree.Gradients[gradient];
  pointCloud.material.gradientName = gradient;
};

export type HeightFilter = { min: number; max: number };
export type IntensityFilter = HeightFilter;
export type ResolutionFilter = { resolutionLevel: number; resolutionValue: number };
export type ClippingFilter = { nrOfClippingBoxes: number; nrOfClippingClusters: number };
export type AppliedFiltersArray = (
  | { name: 'classifications'; value: (ClassificationByCode & { key: string })[] }
  | { name: 'heightFilter'; value: HeightFilter }
  | { name: 'intensityFilter'; value: IntensityFilter }
  | { name: 'resolutionFilter'; value: ResolutionFilter }
  | { name: 'clippingFilter'; value: ClippingFilter }
)[];
export interface AppliedFilters {
  classifications?: (ClassificationByCode & { key: string })[];
  heightFilter?: HeightFilter;
  intensityFilter?: IntensityFilter;
  resolutionFilter?: ResolutionFilter;
  clippingFilter?: ClippingFilter;
}
export const useAppliedFilters = ({
  project,
}: {
  project: { pointClouds: Pick<PointCloud, 'availableClasses' | 'id'>[] };
}) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const appliedFilters = useMemo(() => {
    const pointCloudsById = keyBy('id', project.pointClouds);
    const appliedFilters = viewer?.scene.pointclouds.reduce((result, pc) => {
      const projectPointCloud = pointCloudsById[pc.identifier];
      const noIntensity = !pc?.material?.initialIntensityMax && !pc?.material?.initialIntensityMin;
      const appliedClippingBoxes = viewer.scene.volumes.filter(
        (volume) =>
          volume.assignedClipTask === Potree.ClipTask.SHOW_INSIDE ||
          volume.assignedClipTask === Potree.ClipTask.SHOW_OUTSIDE,
      );
      const appliedClippingClusters = viewer.scene.pointClusters.filter(
        (cluster) =>
          cluster.assignedClipTask === Potree.ClipTask.SHOW_INSIDE ||
          cluster.assignedClipTask === Potree.ClipTask.SHOW_OUTSIDE ||
          !cluster.visible,
      );
      const filters = {
        classifications: !viewer.classificationsInfo.hiddenClassesByPointCloud[pc.identifier].length
          ? undefined
          : viewer.classificationsInfo
              .getClassesForPointCloud({ pointcloudId: pc.identifier })
              .filter(
                (classification) =>
                  classification.visible && projectPointCloud.availableClasses.includes(classification.code),
              ),

        heightFilter:
          pc.material.heightMin !== undefined &&
          pc.material.heightMax !== undefined &&
          (Math.round(pc.material.initialHeightMin) !== Math.round(pc.material.heightMin) ||
            Math.round(pc.material.initialHeightMax) !== Math.round(pc.material.heightMax))
            ? {
                min: Math.round(pc.material.heightMin),
                max: Math.round(pc.material.heightMax),
              }
            : undefined,
        intensityFilter:
          !noIntensity &&
          pc.material.intensityMin !== undefined &&
          pc.material.intensityMax !== undefined &&
          (Math.round(pc.material.initialIntensityMin) !== Math.round(pc.material.intensityMin) ||
            Math.round(pc.material.initialIntensityMax) !== Math.round(pc.material.intensityMax))
            ? {
                min: Math.round(pc.material.intensityMin),
                max: Math.round(pc.material.intensityMax),
              }
            : undefined,
        resolutionFilter:
          pc.downSamplingLevel !== undefined && pc.downSamplingLevel !== Infinity && pc.pcoGeometry?.scale !== undefined
            ? {
                resolutionLevel: pc.downSamplingLevel,
                resolutionValue: Math.pow(2, 14 - pc.downSamplingLevel) / pc.pcoGeometry.scale,
              }
            : undefined,
        clippingFilter:
          appliedClippingBoxes.length || appliedClippingClusters.length
            ? {
                nrOfClippingBoxes: appliedClippingBoxes.length,
                nrOfClippingClusters: appliedClippingClusters.length,
              }
            : undefined,
      };
      const filtersArray = [
        { name: 'classifications', value: filters.classifications },
        { name: 'heightFilter', value: filters.heightFilter },
        { name: 'intensityFilter', value: filters.intensityFilter },
        { name: 'resolutionFilter', value: filters.resolutionFilter },
        { name: 'clippingFilter', value: filters.clippingFilter },
      ];
      return {
        ...result,
        [pc.identifier]: {
          filtersArray,
          filters,
          hasFilters: filtersArray.some((filter) => filter.value !== undefined),
        },
      };
    }, {}) as { [key: string]: { filtersArray: AppliedFiltersArray; hasFilters: boolean; filters: AppliedFilters } };
    return appliedFilters || [];
  }, [
    project.pointClouds,
    viewer?.classificationsInfo,
    viewer?.scene.pointclouds,
    viewer?.scene.volumes,
    viewer?.scene.pointClusters,
  ]);

  return appliedFilters;
};

export const usePointCloudProperties = ({
  useLastSelectedPointCloud,
}: { useLastSelectedPointCloud?: boolean } = {}) => {
  const [_, setDefaultState, { setDefaultStateProperty }] = useDefaultPointCloudState();
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();
  const [{ classifications, hiddenClassesByPointCloudId }] = useClassifications();
  const { selectedPointCloudName } = useCustomSelector((state) => state.rendererProvider, ['selectedPointCloudName']);
  const { condFeetToMeter } = useCondFeetToMeter();

  const selectedPointCloud = viewer?.scene.pointclouds.find(
    (pointCloud) =>
      pointCloud.name === selectedPointCloudName ||
      (useLastSelectedPointCloud ? localStorage.getItem('lastSelectedPointCloudName') === pointCloud.name : false),
  );

  const onChangeResolution = ({ value }: { value: number }) => {
    changePointCloudResolution({ pointCloud: selectedPointCloud, value });
    setDefaultStateProperty(selectedPointCloud.identifier, 'resolution', value);
  };

  const onChangeAppearance = ({ value }: { value: EnumAppearanceTypes }) => {
    changePointCloudAppearance({ pointCloud: selectedPointCloud, value });
    setDefaultStateProperty(selectedPointCloud.identifier, 'appearance', value);
  };

  const onChangeHeightMin: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPointCloud) return;
      selectedPointCloud.material.heightMin = condFeetToMeter(Number(event.target.value));
      setDefaultStateProperty(selectedPointCloud.identifier, 'heightMin', selectedPointCloud.material.heightMin);
      rerender();
    },
    [rerender, selectedPointCloud, setDefaultStateProperty, condFeetToMeter],
  );

  const onChangeHeightMax: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPointCloud) return;
      selectedPointCloud.material.heightMax = condFeetToMeter(Number(event.target.value));
      setDefaultStateProperty(selectedPointCloud.identifier, 'heightMax', selectedPointCloud.material.heightMax);
      rerender();
    },
    [rerender, selectedPointCloud, setDefaultStateProperty, condFeetToMeter],
  );

  const onChangeIntensityMin: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPointCloud) return;
      selectedPointCloud.material.intensityMin = Number(event.target.value);
      setDefaultStateProperty(selectedPointCloud.identifier, 'intensityMin', selectedPointCloud.material.intensityMin);
      rerender();
    },
    [rerender, selectedPointCloud, setDefaultStateProperty],
  );

  const onChangeIntensityMax: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPointCloud) return;
      selectedPointCloud.material.intensityMax = Number(event.target.value);
      setDefaultStateProperty(selectedPointCloud.identifier, 'intensityMax', selectedPointCloud.material.intensityMax);
      rerender();
    },
    [rerender, selectedPointCloud, setDefaultStateProperty],
  );

  const onChangeHeightGradient = useCallback(
    (gradient: string) => {
      changePointCloudGradient({ pointCloud: selectedPointCloud, gradient });
      setDefaultStateProperty(selectedPointCloud.identifier, 'gradient', gradient);
      rerender();
    },
    [rerender, selectedPointCloud, setDefaultStateProperty],
  );

  const hiddenClasses = hiddenClassesByPointCloudId[selectedPointCloud?.identifier || ''];

  const onChangeClassificationVisibility = useCallback(
    ({ code, checked }: { code: number; checked: boolean }) => {
      if (!viewer) return;
      viewer.setClassificationVisibility({ key: code, value: checked, pointCloudId: selectedPointCloud.identifier });
      setDefaultStateProperty(
        selectedPointCloud.identifier,
        'classifications',
        viewer.classificationsInfo.getClassesForPointCloud({ pointcloudId: selectedPointCloud.identifier }),
      );
    },
    [viewer, setDefaultStateProperty, selectedPointCloud?.identifier],
  );

  const onResetHeight = useCallback(() => {
    if (!selectedPointCloud) return { heightMin: 0, heightMax: 0 };
    selectedPointCloud.material.heightMin = selectedPointCloud.material.initialHeightMin;
    selectedPointCloud.material.heightMax = selectedPointCloud.material.initialHeightMax;
    setDefaultState((prev) => ({
      ...prev,
      [selectedPointCloud.identifier]: {
        ...(prev[selectedPointCloud.identifier] || {}),
        heightMin: selectedPointCloud.material.heightMin,
        heightMax: selectedPointCloud.material.heightMax,
      },
    }));
    rerender();
    return { heightMin: selectedPointCloud.material.heightMin, heightMax: selectedPointCloud.material.heightMax };
  }, [rerender, selectedPointCloud, setDefaultState]);

  const onResetIntensity = useCallback(() => {
    if (!selectedPointCloud) return;
    selectedPointCloud.material.intensityMin = selectedPointCloud.material.initialIntensityMin;
    selectedPointCloud.material.intensityMax = selectedPointCloud.material.initialIntensityMax;
    setDefaultState((prev) => ({
      ...prev,
      [selectedPointCloud.identifier]: {
        ...(prev[selectedPointCloud.identifier] || {}),
        intensityMin: selectedPointCloud.material.intensityMin,
        intensityMax: selectedPointCloud.material.intensityMax,
      },
    }));
    rerender();
  }, [rerender, selectedPointCloud, setDefaultState]);

  useEffect(() => {
    if (!selectedPointCloud?.material) return;
    selectedPointCloud.material.addEventListener('active_attribute_changed', rerender);
    return () => {
      selectedPointCloud.material.removeEventListener('active_attribute_changed', rerender);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPointCloud?.name, !!selectedPointCloud?.material]);

  return [
    {
      resolutionLevel: selectedPointCloud?.downSamplingLevel,
      resolvedPoints: selectedPointCloud?.resolvedPoints,
      depth: selectedPointCloud?.pcoGeometry?.depth,
      scale: selectedPointCloud?.pcoGeometry?.scale,
      appearanceValue: selectedPointCloud?.material?.activeAttributeName,
      heightMin: selectedPointCloud?.material?.heightMin,
      heightMax: selectedPointCloud?.material?.heightMax,
      initialHeightMax: selectedPointCloud?.material?.initialHeightMax,
      initialHeightMin: selectedPointCloud?.material?.initialHeightMin,
      intensityMin: selectedPointCloud?.material?.intensityMin,
      intensityMax: selectedPointCloud?.material?.intensityMax,
      initialIntensityMax: selectedPointCloud?.material?.initialIntensityMax,
      initialIntensityMin: selectedPointCloud?.material?.initialIntensityMin,
      noIntensity:
        !selectedPointCloud?.material?.initialIntensityMax && !selectedPointCloud?.material?.initialIntensityMin,
      heightGradient: selectedPointCloud?.material?.gradientName,
      selectedPointCloudId: selectedPointCloud?.identifier,
      classifications: classifications.map((classification) => {
        return { ...classification, visible: !hiddenClasses.includes(classification.code) };
      }),
    },
    {
      onChangeResolution,
      onChangeAppearance,
      onChangeHeightMin,
      onChangeHeightMax,
      onChangeHeightGradient,
      onChangeIntensityMin,
      onChangeIntensityMax,
      onChangeClassificationVisibility,
      onResetHeight,
      onResetIntensity,
    },
  ] as const;
};
