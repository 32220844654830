import { T } from '../../../translation/src';
import { memo } from 'react';

interface GroupCalculationsProps {}
const GroupCalculations_: React.FC2<GroupCalculationsProps> = () => {
  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <div className="mt-4 text-sm">
        <T _str="Calculations on a group are not possible." />
      </div>
    </div>
  );
};

export const GroupCalculations = memo(GroupCalculations_);
