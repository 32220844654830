import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, memo, useCallback } from 'react';

interface ContainerProps {
  open?: boolean;
  onClose?: () => void;
  panelClassName?: string;
  noOverflow?: boolean;
  fullScreen?: boolean;
}

const Container_: React.FC2<ContainerProps> = ({
  children,
  open,
  onClose: onCloseProps,
  panelClassName,
  noOverflow,
  fullScreen,
  ...props
}) => {
  const onClose = useCallback(() => {
    onCloseProps?.();
  }, [onCloseProps]);
  return (
    <Transition appear show={open}>
      <Dialog as="div" className="relative z-30" onClose={onClose} {...props}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-30 bg-black/30" />
        </Transition.Child>
        <div className={classNames('fixed inset-0 z-50', !noOverflow && 'overflow-y-auto')}>
          <div className="flex items-center justify-center h-full p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'p-5 text-left align-middle transition-all transform shadow-xl rounded-2xl',
                  fullScreen ? 'max-w-full max-h-full' : !panelClassName?.includes('max-w-') && 'max-w-md',
                  fullScreen ? 'w-full h-full' : !panelClassName?.includes('w-') && 'w-full',
                  !noOverflow && 'overflow-hidden',
                  'bg-white text-black dark:bg-eerie-black dark:border dark:border-gray-700 dark:text-white' +
                    ' ' +
                    panelClassName,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Container = memo(Container_);
