import LocationIcon from '../../../assets/icons/location.svg?react';
import SelectIcon from '../../../assets/icons/select.svg?react';
import LineIcon from '../../../assets/icons/vector-line.svg?react';
import PolygonIcon from '../../../assets/icons/vector-polygon.svg?react';
import CubeIcon from '../../../assets/icons/cube.svg?react';
import Logo from '../../../assets/logo-simple.svg?react';
import ClusterIcon from '../../../assets/icons/magic-wand.svg?react';
import PlaneIcon from '../../../assets/icons/plane.svg?react';
import FloorplanIcon from '../../../assets/icons/floortplan.svg?react';
import ScreenshotIcon from '../../../assets/icons/screenshot.svg?react';
import { RendererToolType, useRendererTools } from '../../../hooks/potree/useRenderer';
import { ProjectDetailSelect } from '../../project/ProjectDetailSelect';
import { PossibleActions, Project } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, memo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import AccountMultipleIcon from '../../../assets/icons/account-multiple.svg?react';
import { Button } from '../../../components/Button';
import { Tooltip } from '../../../components/Tooltip';
import { RendererReadOnlyContext } from '../../../contexts/RendererReadOnlyContext';
import { T } from '../../../translation/src';
import { ShareProjectModal } from '../../project/ShareProjectModal';
import { UndoRedo } from './UndoRedo';
import { AccountSelect } from '../../user/AccountSelect';
import { WarningLabel } from '../../../components/WarningLabel';
import { useCustomSelector } from '../../../redux/store';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { useCurrentOrganisation } from '../../../hooks/modules/organisation/useCurrentOrganisation';
import { ViewerContext } from '../../../contexts/ViewerContext';
import { ExportOrthophotoModal } from '../../project/ExportOrthophotoModal';

interface ActionProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  active?: boolean;
  disabled?: boolean;
  icon: React.FC2;
}
export const Action: React.FC<ActionProps> = ({ active, icon: Icon, disabled, className, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        'flex w-11 h-11  items-center justify-center rounded mx-px',
        active
          ? 'bg-neon-green-300 text-black'
          : disabled
            ? 'bg-transparent text-gray-300'
            : 'bg-transparent dark:text-white hover:bg-neon-green-300 dark:hover:bg-black dark:hover:border border-gray-500',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer ',
        className,
      )}
    >
      <Icon />
    </div>
  );
};

interface RendererHeaderProps {
  project: Pick<Project, 'id' | 'name' | 'description' | 'previewUrl' | 'settings'>;
  minimize?: boolean; // remove elements to make it readable on small devices/windows
}
const RendererHeader_: React.FC2<RendererHeaderProps> = ({ project, minimize }) => {
  const [{ selectedTool }, { changeTool }] = useRendererTools();
  const { organisationId } = useParams();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const { value: shareModalOpen, setFalse: closeShareModal, setTrue: openShareModal } = useBoolean();
  const onNavigateProject = useCallback(() => {
    /**
     * TODO: find better way to navigate and destroy everything
     * case: click on project with map view enabled, and click back on logo (https://pointorama.atlassian.net/jira/software/projects/PK/boards/2?selectedIssue=PK-7)
     */
    window.location.href = `${window.location.origin}/organisations/${organisationId}/projects`;
  }, [organisationId]);

  const { currentOrganisation } = useCurrentOrganisation();

  const { mdDevice } = useDeviceSize();

  const { performanceWarning } = useCustomSelector((state) => state.rendererProvider, ['performanceWarning']);

  const { in2DMode } = useContext(ViewerContext);

  return (
    <>
      <div
        className={classNames(
          'z-20 flex items-center bg-gray-50 dark:bg-eerie-black dark:text-white',
          minimize ? 'px-4' : 'p-4',
        )}
      >
        <div className="flex items-center">
          <Logo className="flex-shrink-0 mr-4 cursor-pointer h-7" onClick={onNavigateProject} />
          <ProjectDetailSelect project={project} isReadOnly={isReadOnly} />
        </div>
        <div className="flex items-center justify-center flex-grow space-x-10">
          {!minimize && (
            <>
              {!isReadOnly && (
                <div className="flex">
                  <Tooltip message={<T _str="selection" swc />} delay={0}>
                    <Action
                      icon={SelectIcon}
                      active={selectedTool === RendererToolType.SELECT}
                      onClick={() => changeTool({ type: RendererToolType.SELECT })}
                    />
                  </Tooltip>
                </div>
              )}
              {!isReadOnly && (
                <div className="flex">
                  <Tooltip message={<T _str="point" swc />} delay={0}>
                    <Action
                      icon={LocationIcon}
                      active={selectedTool === RendererToolType.POINT}
                      onClick={() => changeTool({ type: RendererToolType.POINT })}
                    />
                  </Tooltip>
                  <Tooltip message={<T _str="line" swc />} delay={0}>
                    <Action
                      icon={LineIcon}
                      active={selectedTool === RendererToolType.DISTANCE}
                      onClick={() => changeTool({ type: RendererToolType.DISTANCE })}
                    />
                  </Tooltip>
                  <Tooltip message={<T _str="polygon" swc />} delay={0}>
                    <Action
                      icon={PolygonIcon}
                      active={selectedTool === RendererToolType.AREA}
                      onClick={() => changeTool({ type: RendererToolType.AREA })}
                    />
                  </Tooltip>
                  {!in2DMode ? (
                    <Tooltip message={<T _str="box" swc />} delay={0}>
                      <Action
                        icon={CubeIcon}
                        active={selectedTool === RendererToolType.BOX}
                        onClick={() => changeTool({ type: RendererToolType.BOX })}
                      />
                    </Tooltip>
                  ) : null}
                  {!currentOrganisation?.prohibitedActions.includes(PossibleActions.ToolPlane) && !in2DMode ? (
                    <Tooltip message={<T _str="plane" swc />} delay={0}>
                      <Action
                        icon={PlaneIcon}
                        active={selectedTool === RendererToolType.PLANE}
                        onClick={() => changeTool({ type: RendererToolType.PLANE })}
                      />
                    </Tooltip>
                  ) : null}
                  {!currentOrganisation?.prohibitedActions.includes(PossibleActions.ToolFloorPlan) && !in2DMode ? (
                    <Tooltip message={<T _str="floorplan" swc />} delay={0}>
                      <Action
                        icon={FloorplanIcon}
                        active={selectedTool === RendererToolType.FLOORPLAN}
                        onClick={() => changeTool({ type: RendererToolType.FLOORPLAN })}
                      />
                    </Tooltip>
                  ) : null}
                  {!in2DMode ? (
                    <Tooltip message={<T _str="point cluster" swc />} delay={0}>
                      <Action
                        icon={ClusterIcon}
                        active={selectedTool === RendererToolType.CLUSTER}
                        onClick={() => changeTool({ type: RendererToolType.CLUSTER })}
                      />
                    </Tooltip>
                  ) : null}
                  {in2DMode ? (
                    <Tooltip message={<T _str="screenshot" swc />} delay={0}>
                      <Action
                        icon={ScreenshotIcon}
                        active={selectedTool === RendererToolType.SCREENSHOT}
                        onClick={() => changeTool({ type: RendererToolType.SCREENSHOT })}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              )}
              {!isReadOnly && <UndoRedo />}
            </>
          )}
        </div>
        <div className="flex justify-end w-80">
          {!isReadOnly && (
            <Button variant="primary" onClick={openShareModal} className={minimize ? 'w-10 h-10' : ''}>
              <AccountMultipleIcon className="w-4 h-4" />
              {!minimize && (
                <span>
                  <T _str="share" swc />
                </span>
              )}
            </Button>
          )}
        </div>
        <div className="ml-4">
          <AccountSelect variant="header" forceReload={true} onlyIcon={minimize} size={mdDevice ? 'small' : 'large'} />
        </div>
      </div>
      {performanceWarning ? (
        <WarningLabel type="warning" label="Poor performance detected. Please ensure your browser is utilizing GPU." />
      ) : null}
      <ShareProjectModal open={shareModalOpen} project={project} onClose={closeShareModal} />
      <ExportOrthophotoModal project={project} />
    </>
  );
};

export const RendererHeader = memo(RendererHeader_);
