import { useProjectRequestDownloadTokenLazyQuery } from '../types/graphqlTypes';

export const useDownloadExport = () => {
  const [getDownloadToken, { loading }] = useProjectRequestDownloadTokenLazyQuery();

  const downloadExport = async ({ projectId, exportId }: { projectId: string; exportId: string }) => {
    if (!projectId) return;
    const { data: tokenData } = await getDownloadToken({ variables: { projectId } });
    await fetch(`${import.meta.env.VITE_NX_SERVER}/project/${projectId}/export/${exportId}/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: tokenData?.projectRequestDownloadToken }),
    })
      .then((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'export.zip';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }
        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => console.log('Error downloading export', e));
  };

  return { downloadExport, loading: loading || false };
};
