import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo, useCallback, useState } from 'react';
import { FileInputProps } from '../../inputs/FileInput';
import { Label } from '../Label';
import { CameraIcon } from '@heroicons/react/24/outline';

interface FormImageInputProps extends FileInputProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
  previewUrl?: string;
}
const FormImageInput_: React.FC2<FormImageInputProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  previewUrl,
  ...props
}) => {
  return (
    <label className={classNames('block', propsClassName)}>
      <Label>{label}</Label>
      <div className="relative flex items-center justify-center w-full text-white bg-gradient-to-br from-eerie-black to-ash-green-800 h-36">
        <input type="file" hidden={true} accept="image/*" {...props} />
        {previewUrl && <img src={previewUrl} className="object-cover w-full h-36" alt="Preview" />}
        <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
          <CameraIcon className="w-6 h-6" />
        </div>
      </div>
    </label>
  );
};

export const FormImageInput = memo(FormImageInput_) as typeof FormImageInput_;

interface FormikImageInputProps extends FormImageInputProps {
  name: string;
}

const FormikImageInput_: React.FC2<FormikImageInputProps> = ({ name, ...props }) => {
  const [{ onChange: onChangeField, value, ...field }, meta] = useField(name);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(props.previewUrl);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const file = event.target.files?.[0];
      if (file) {
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
      }
      onChangeField({ ...event, target: { ...event.target, name, value: event.target.files } });
    },
    [name, onChangeField],
  );

  return (
    <FormImageInput
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
      {...field}
      previewUrl={previewUrl}
      onChange={onChange}
    />
  );
};

export const FormikImageInput = memo(FormikImageInput_) as typeof FormikImageInput_;
