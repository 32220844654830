import { memo, useMemo } from 'react';
import { Tooltip } from '../../../components/Tooltip';
import { useClassificationTranslations } from '../../../hooks/translations/useClassifications';
import { T, useT } from '../../../translation/src';
import { Property } from './PropertiesSection';

const useTranslations = () => {
  const classifications = useT('classifications', { swc: true });
  const height = useT('height', { swc: true });
  const intensity = useT('intensity', { swc: true });
  const resolution = useT('resolution', { swc: true });
  const clipping = useT('clipping', { swc: true });
  return { classifications, height, intensity, resolution, clipping };
};

interface FiltersInfoProps {
  filters: {
    classificationFilters?: number[] | null;
    intensityFilters?: number[] | null;
    heightFilters?: number[] | null;
    resolutionFilter?: number | null;
    clippingFilter?: number | null;
  };
}
const FiltersInfo_: React.FC<FiltersInfoProps> = ({ filters }) => {
  const translations = useTranslations();
  const classificationTranslations = useClassificationTranslations();
  const appliedFilters = useMemo(() => {
    const appliedFilters = [];
    if (filters.classificationFilters?.length) appliedFilters.push(`${translations.classifications}`);
    if (filters.intensityFilters) appliedFilters.push(`${translations.intensity}`);
    if (filters.heightFilters) appliedFilters.push(`${translations.height}`);
    if (filters.resolutionFilter) appliedFilters.push(`${translations.resolution}`);
    if (filters.clippingFilter) appliedFilters.push(`${translations.clipping}`);
    return appliedFilters.join(', ');
  }, [
    filters.classificationFilters?.length,
    filters.heightFilters,
    filters.intensityFilters,
    filters.resolutionFilter,
    filters.clippingFilter,
    translations.classifications,
    translations.height,
    translations.intensity,
    translations.resolution,
    translations.clipping,
  ]);

  const hasNoFilters =
    !filters.classificationFilters?.length &&
    !filters.intensityFilters &&
    !filters.heightFilters &&
    !filters.resolutionFilter &&
    !filters.clippingFilter;

  if (hasNoFilters) return <T _str="none" swc />;

  return (
    <Tooltip
      className="p-2 z-50"
      message={
        <div className="flex flex-col space-y-2">
          {filters.classificationFilters?.length && (
            <Property title={<T _str="classifications" swc />}>
              {filters.classificationFilters
                .map((classification) => {
                  // @ts-ignore
                  const name = Potree.ClassificationScheme[+classification]?.name;
                  return classificationTranslations[name];
                })
                .join(', ')}
            </Property>
          )}
          {filters.intensityFilters && (
            <Property title={<T _str="intensity filters" swc />}>
              {filters.intensityFilters[0]} - {filters.intensityFilters[1]}
            </Property>
          )}
          {filters.heightFilters && (
            <Property title={<T _str="height filters" swc />}>
              {filters.heightFilters[0]} - {filters.heightFilters[1]}
            </Property>
          )}
          {filters.resolutionFilter && (
            <Property title={<T _str="resolution filter" swc />}>{filters.resolutionFilter}</Property>
          )}
          {filters.clippingFilter && (
            <Property title={<T _str="clipping regions" swc />}>{filters.clippingFilter}</Property>
          )}
        </div>
      }
    >
      {appliedFilters}
    </Tooltip>
  );
};

export const FiltersInfo = memo(FiltersInfo_);
