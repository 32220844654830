import { MapIcon } from '@heroicons/react/24/outline';
import { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { HideSelect } from '../../../../components/HideSelector';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { RendererReadOnlyContext } from '../../../../contexts/RendererReadOnlyContext';
import { UserContext } from '../../../../contexts/UserContext';
import useOpen from '../../../../hooks/useOpen';
import { selectMap } from '../../../../redux/rendererReducer';
import { useCustomSelector } from '../../../../redux/store';
import { T, useT } from '../../../../translation/src';
import { PointCloud, Project } from '../../../../types/graphqlTypes';
import {
  ProjectProjectionSystemModal,
  ProjectProjectionSystemModalProps,
} from '../../../project/ProjectProjectionSystemModal';
import { UploadPointCloudModal } from '../../../project/UploadPointCloudModal';
import { ShouldUpgradeModal } from '../../../user/ShouldUpgradeModal';
import { useToggleHideMap } from '../../../../hooks/modules/project/useToggleHideMap';
import { useCADObjects } from '../../../../hooks/potree/useRenderer';
import { CadLayers } from './CadLayers';
import { AddCADLayerModal } from '../../../project/AddCADLayerModal';
import { WMSLayerModal, WMSLayerModalProps } from '../../../project/WMSLayerModal';
import { WmsLayers } from './WmsLayers';
import { WMSLayersContext } from '../../../../contexts/WmsLayersContext';
import { OrthophotoLayerModal } from '../../../project/AddOrthophotoLayerModal';
import { OrthophotoLayersContext } from '../../../../contexts/OrthophotoLayersContext';
import { OrthophotoLayers } from './OrthophotoLayers';
import { AnnotationsOrthoviewIcon } from '../Annotations/OrthoviewToggle';
import { PointCloudLayers } from './PointCloudLayers';

const useTranslations = () => {
  const shouldUpgradeModalDescriptionProject = useT('In order to add more projects, you need to upgrade your plan.');
  const shouldUpgradeModalDescriptionUpload = useT(
    'In order to add more simultaneous uploads, you need to upgrade your plan.',
  );
  return { shouldUpgradeModalDescriptionProject, shouldUpgradeModalDescriptionUpload };
};

interface LayersProps {
  project?: Pick<Project, 'settings' | 'id' | 'mapVisible'> & {
    pointClouds: Pick<
      PointCloud,
      'cloudName' | 'id' | 'visible' | 'displayName' | 'uploadStatus' | 'availableClasses'
    >[];
  };
}
const Layers_: React.FC2<LayersProps> = ({ project }) => {
  const pointClouds = project?.pointClouds || [];
  const dispatch = useDispatch();
  const isReadOnly = useContext(RendererReadOnlyContext);
  const { organisationId = '' } = useParams();
  const translations = useTranslations();
  const {
    onClose: closeMapModal,
    open: mapModalOpen,
    onOpenWithValue,
    value: mapModalValue,
  } = useOpen<{ origin: 'MAP' | 'WMS' | 'ORTHOPHOTO' }>();
  const { onClose: closeCADLayerModal, onOpen: openCADLayerModal, open: CADLayerModalOpen } = useOpen();
  const {
    onClose: closeWmsLayerModal,
    onOpen: openWmsLayerModal,
    open: wmsLayerModalOpen,
    onOpenWithValue: openWmsLayerModalWithValue,
    value: wmsLayerModalValue,
  } = useOpen<WMSLayerModalProps['initialValues']>();
  const {
    onClose: closeOrthophotoLayerModal,
    onOpen: openOrthophotoLayerModal,
    open: orthophotoLayerModalOpen,
  } = useOpen();
  const {
    onClose: closePointCloudUploadModal,
    onOpen: openPointCloudUploadModal,
    open: pointCloudUploadModalOpen,
  } = useOpen();
  const {
    open: shouldUpgradeModalOpen,
    onClose: closeShouldUpgradeModal,
    onOpenWithValue: openShouldUpgradeModal,
    value: shouldUpgradeModalDescription,
  } = useOpen<string>();
  const { mapSelected } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedPointCloudName', 'mapSelected'],
  );
  const currentUser = useContext(UserContext);
  const [mapVisible, onToggleHideMap] = useToggleHideMap({ project });
  const [{ cadLayers }] = useCADObjects();
  const { wmsLayers } = useContext(WMSLayersContext);
  const { orthophotoLayers } = useContext(OrthophotoLayersContext);

  const onClickHideSelect = useCallback(() => {
    if (project?.settings?.projectionSystem) {
      onToggleHideMap();
    } else {
      onOpenWithValue({ origin: 'MAP' });
    }
  }, [project?.settings?.projectionSystem, onToggleHideMap, onOpenWithValue]);

  const onClickMap = useCallback(() => {
    return dispatch(selectMap({}));
  }, [dispatch]);

  const onMapModalSuccess: Required<ProjectProjectionSystemModalProps>['onSuccess'] = useCallback(() => {
    if (mapModalValue?.origin === 'MAP') onToggleHideMap();
    else if (mapModalValue?.origin === 'WMS') openWmsLayerModal();
    else if (mapModalValue?.origin === 'ORTHOPHOTO') openOrthophotoLayerModal();
    else if (mapModalValue?.origin === 'CAD') openCADLayerModal();
    closeMapModal();
  }, [
    closeMapModal,
    mapModalValue?.origin,
    openWmsLayerModal,
    openOrthophotoLayerModal,
    openCADLayerModal,
    onToggleHideMap,
  ]);

  const onAddPointCloud = useCallback(() => {
    const organisation = currentUser.organisations.find((organisation) => organisation.id === organisationId);
    if (!organisation) return;
    if (organisation.subscription.canAddPointClouds && organisation.subscription.canAddSimultaneousUploads)
      return openPointCloudUploadModal();
    return openShouldUpgradeModal(
      !organisation.subscription.canAddPointClouds
        ? translations.shouldUpgradeModalDescriptionProject
        : translations.shouldUpgradeModalDescriptionUpload,
    );
  }, [
    currentUser.organisations,
    openPointCloudUploadModal,
    openShouldUpgradeModal,
    organisationId,
    translations.shouldUpgradeModalDescriptionProject,
    translations.shouldUpgradeModalDescriptionUpload,
  ]);

  const projectionSystem = project?.settings?.projectionSystem;

  const onOpenWmsLayerModal = useCallback(() => {
    if (projectionSystem) return openWmsLayerModal();
    return onOpenWithValue({ origin: 'WMS' });
  }, [onOpenWithValue, openWmsLayerModal, projectionSystem]);

  const onOpenOrthophotoLayerModal = useCallback(() => {
    if (projectionSystem) return openOrthophotoLayerModal();
    return onOpenWithValue({ origin: 'ORTHOPHOTO' });
  }, [onOpenWithValue, openOrthophotoLayerModal, projectionSystem]);

  const onOpenCadLayerModal = useCallback(() => {
    if (projectionSystem) return openCADLayerModal();
    return onOpenWithValue({ origin: 'CAD' });
  }, [onOpenWithValue, openCADLayerModal, projectionSystem]);

  return (
    <>
      <div className="flex flex-col flex-grow mt-8 overflow-auto">
        <div className="flex flex-col overflow-auto divide-y">
          {pointClouds.length ? <PointCloudLayers pointClouds={pointClouds}></PointCloudLayers> : null}
          <div className="flex flex-col py-2">
            <SelectItemContent
              icon={MapIcon}
              idle={!mapVisible}
              selectStyle="primary"
              onClick={onClickMap}
              active={mapSelected}
              className="pl-8"
            >
              <T _str="map" swc />
              <div className="flex justify-end flex-grow">
                <AnnotationsOrthoviewIcon />
                <HideSelect hidden={!mapVisible} onClick={onClickHideSelect} />
              </div>
            </SelectItemContent>
          </div>
          {cadLayers.length ? <CadLayers className="flex flex-col" cadLayers={cadLayers} /> : null}
          {wmsLayers.length ? (
            <WmsLayers className="flex flex-col" wmsLayers={wmsLayers} onUpdateLayer={openWmsLayerModalWithValue} />
          ) : null}
          {orthophotoLayers.length ? (
            <OrthophotoLayers className="flex flex-col" orthophotoLayers={orthophotoLayers} />
          ) : null}
        </div>

        <div className="flex items-end flex-grow w-full">
          {!isReadOnly && (
            <div className="w-full">
              <Button variant="secondary" onClick={onAddPointCloud} className="w-full mt-2" size="sm">
                <T _str="add pointcloud" swc />
              </Button>
              <Button variant="secondary" onClick={onOpenCadLayerModal} className="w-full" size="sm">
                <T _str="add CAD file" swc />
              </Button>
              <Button variant="secondary" onClick={onOpenWmsLayerModal} className="w-full" size="sm">
                <T _str="add WM(T)S" swc />
              </Button>
              <Button variant="secondary" onClick={onOpenOrthophotoLayerModal} className="w-full" size="sm">
                <T _str="add orthophoto" swc />
              </Button>
            </div>
          )}
        </div>
      </div>
      {project && (
        <ProjectProjectionSystemModal
          open={mapModalOpen}
          onClose={closeMapModal}
          project={project}
          onSuccess={onMapModalSuccess}
          title={
            mapModalValue?.origin === 'WMS' ? (
              <T _str="first select a projection system before adding a wms layer" swc />
            ) : mapModalValue?.origin === 'ORTHOPHOTO' ? (
              <T _str="first select a projection system before adding an orthophoto" swc />
            ) : null
          }
        />
      )}
      <UploadPointCloudModal onClose={closePointCloudUploadModal} open={pointCloudUploadModalOpen} />
      <ShouldUpgradeModal
        open={shouldUpgradeModalOpen}
        onClose={closeShouldUpgradeModal}
        description={shouldUpgradeModalDescription || ''}
      />

      <AddCADLayerModal open={CADLayerModalOpen} onClose={closeCADLayerModal} />
      <WMSLayerModal open={wmsLayerModalOpen} onClose={closeWmsLayerModal} initialValues={wmsLayerModalValue} />
      <OrthophotoLayerModal open={orthophotoLayerModalOpen} onClose={closeOrthophotoLayerModal} />
    </>
  );
};

export const Layers = memo(Layers_);
