import classNames from 'classnames';
import { useField } from 'formik';
import React, { memo } from 'react';
import { CheckBox } from '../../inputs/CheckBox';
import { InputProps } from '../../inputs/Input';

interface FormCheckBoxProps extends InputProps {
  label?: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  error?: React.ReactNode;
  hideErrorLabel?: boolean;
}
const FormCheckBox_: React.FC2<FormCheckBoxProps> = ({
  error,
  label,
  hideErrorLabel,
  inputClassName,
  className: propsClassName,
  labelClassName,
  name,
  ...props
}) => {
  return (
    <div className={classNames('flex flex-col', propsClassName)}>
      <div className={classNames('flex items-center')}>
        <CheckBox className={inputClassName} id={name} name={name} {...props} />

        <label htmlFor={name} className={classNames('block ml-2 text-sm text-gray-900 dark:text-white')}>
          {label}
        </label>
      </div>
      <div className="flex items-center h-4 ml-6 text-xs text-red-500">{!hideErrorLabel && error}</div>
    </div>
  );
};

export const FormCheckBox = memo(FormCheckBox_) as typeof FormCheckBox_;

interface FormikCheckBoxProps extends FormCheckBoxProps {
  name: string;
}

const FormikCheckBox_: React.FC2<FormikCheckBoxProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return <FormCheckBox error={meta.touched && meta.error ? meta.error : undefined} {...props} {...field} />;
};

export const FormikCheckBox = memo(FormikCheckBox_) as typeof FormikCheckBox_;
