import { useToggleHideLabel } from '../../../hooks/modules/project/useToggleHide';
import { memo, useCallback } from 'react';
import { SwitchToggle } from '../../../components/SwitchToggle';
import { Annotation, AnnotationLabels } from '../../../types/graphqlTypes';
import { Property } from './PropertiesSection';
import { useT } from '../../../translation/src';

const useTranslations = () => {
  const coordinateLabel = useT('coordinate labels', { swc: true });
  const lengthLabel = useT('distance labels', { swc: true });
  const areaLabel = useT('area labels', { swc: true });
  const volumeLabel = useT('volume labels', { swc: true });
  const labels = useT('labels', { swc: true });
  return { coordinateLabel, lengthLabel, areaLabel, volumeLabel, labels };
};

interface HideLabelsProps {
  annotations: Annotation[];
  label: AnnotationLabels;
}

const HideLabelProperty_: React.FC2<HideLabelsProps> = ({ annotations, label }) => {
  const { toggleHideLabel } = useToggleHideLabel();
  const translations = useTranslations();
  const labelTranslations = {
    [AnnotationLabels.Coordinate]: translations.coordinateLabel,
    [AnnotationLabels.Length]: translations.lengthLabel,
    [AnnotationLabels.Area]: translations.areaLabel,
    [AnnotationLabels.Volume]: translations.volumeLabel,
  };
  const propertyLabel = labelTranslations[label];

  const visible = annotations.some((annotation) => !annotation.hiddenLabels.includes(label));

  const onToggleHideLabel = useCallback(() => {
    toggleHideLabel({ identifiers: annotations.map((annotation) => annotation.identifier), visible: !visible, label });
  }, [annotations, toggleHideLabel, visible, label]);

  return (
    <Property title={propertyLabel}>
      <SwitchToggle onChange={onToggleHideLabel} switchedOn={visible} />
    </Property>
  );
};

export const HideLabelProperty = memo(HideLabelProperty_);
