import classNames from 'classnames';
import { memo } from 'react';
import { useT } from '../../translation/src';

export const useStatusTranslations = (): Record<string, string> => {
  const success = useT('success', { swc: true });
  const warning = useT('warning', { swc: true });
  const error = useT('error', { swc: true });
  const process = useT('process', { swc: true });
  const progress = useT('process', { swc: true });
  const suspended = useT('suspended', { swc: true });
  return { success, warning, error, process, progress, suspended };
};

const CLASS_NAMES_BY_STATUS: Record<string, string> = {
  success: 'bg-eerie-black text-neon-green-300 dark:bg-black dark:text-white',
  error: 'bg-gradient-to-r from-error-red-700 to-error-red-500 text-black',
  warning: 'bg-gradient-to-r from-warning-yellow-700 to-warning-yellow-500 text-black',
  pending: 'bg-gradient-to-r from-neon-green-600 to-neon-green-300 text-black',
  process: 'bg-gradient-to-r from-gray-600 to-gray-300 text-black',
  progress: 'bg-gradient-to-r from-neon-green-600 to-neon-green-300 text-black',
  suspended: 'bg-gradient-to-r from-gray-600 to-gray-300 text-black',
};
interface StatusPillProps {
  status?: keyof typeof CLASS_NAMES_BY_STATUS | string;
}
const StatusPill_: React.FC2<StatusPillProps> = ({ children, className, status = 'process' }) => {
  const translations = useStatusTranslations();
  return (
    <Pill className={classNames(CLASS_NAMES_BY_STATUS[status], !className?.includes('text-') && 'text-xs', className)}>
      {children || `${translations[status]}`}
    </Pill>
  );
};

export const StatusPill = memo(StatusPill_);

const Pill_: React.FC2 = ({ className, children }) => {
  return (
    <div className={classNames('flex py-1 px-2 rounded-md justify-center items-center', className)}>{children}</div>
  );
};

export const Pill = memo(Pill_);
