import classNames from 'classnames';
import React, { memo } from 'react';

export type FileInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FileInput_: React.FC<FileInputProps> = ({ className, ...props }) => {
  return (
    <div
      className={classNames(
        'w-full h-10 flex rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 items-center px-2 transition',
        className,
      )}
    >
      <input type="file" {...props} />
    </div>
  );
};

export const FileInput = memo(FileInput_) as typeof FileInput_;
