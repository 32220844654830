import { T, useT } from '../../../translation/src';
import { Option, Select } from '../../../components/inputs/Select';
import { ClipMethods } from '../../../types/graphqlTypes';
import { memo } from 'react';
import { Property } from './PropertiesSection';

type clipMethodPropertyProps = {
  clipMethod: ClipMethods | undefined;
  onChange: (event: { target: { value: ClipMethods; name: string } }) => void;
};

const ClipMethodProperty_: React.FC<clipMethodPropertyProps> = ({ clipMethod, onChange }) => {
  const useClipMethodsTranslations = (): { [key in ClipMethods]: string } => {
    const none = useT('none', { swc: true });
    const isolate = useT('isolate', { swc: true });
    const hide = useT('hide', { swc: true });
    const highlight = useT('highlight', { swc: true });
    return {
      [ClipMethods.None]: none,
      [ClipMethods.Isolate]: isolate,
      [ClipMethods.Hide]: hide,
      [ClipMethods.Highlight]: highlight,
    };
  };

  const FilterTypeTranslations = useClipMethodsTranslations();

  return (
    <Property title={<T _str="Clip method" swc />}>
      <Select<ClipMethods> className="w-full" onChange={onChange} value={clipMethod} name="filter">
        {Object.values(ClipMethods)
          .map((filterType) => (
            <Option key={filterType} value={filterType}>
              {FilterTypeTranslations[filterType]}
            </Option>
          ))
          .reverse()}
      </Select>
    </Property>
  );
};
export const ClipMethodProperty = memo(ClipMethodProperty_);
